import type { FormInstance } from "antd";
import { Form, Input, Radio, Select, Space, Typography } from "antd";
import humanizeString from "humanize-string";
import _ from "lodash";
import { useState } from "react";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IObject } from "../../../../../../interfaces/object";
import type {
  SchemaResult,
  TableResult,
} from "../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type {
  IObjectPropertyFormInput,
  MeasureValidationFunction,
} from "../../domain";
import { ErrorRenderer } from "../common/ErrorRenderer";
import { SchemaSelect } from "../common/SchemaSelect";
import "./PropertiesEditor.scss";
import { ActivitiesFormatter } from "./custom-formatters/ActivitiesFormatter";
import { PillFormatter } from "./custom-formatters/PillsFormatter";
import { ScoreFormatter } from "./custom-formatters/ScoreFormatter";
import { defaultColor, getColorAtIndex } from "./custom-formatters/domain";

interface IPropertiesEditorProps {
  initialData: IObjectPropertyFormInput & {
    id: string;
  };
  schemaResults: AsyncData<SchemaResult>;
  allObjects: IObject[];
  onSave: (data: IObjectPropertyFormInput) => Promise<void>;
  form: FormInstance<any>;
  error: string[];
  datasetId: string;
  currentWarehouse: IDestination;
}

export const validateObjectPropertyFormInput: MeasureValidationFunction<
  IObjectPropertyFormInput
> = (data, schema, allDatasets, allDatasetRelationships) => {
  const errors: string[] = [];
  if (schema.status === "initial" || schema.status === "loading") {
    return errors;
  }
  if (schema.status === "error") {
    return errors;
  }

  if (!data.columnName) {
    errors.push("Please select a column");
    return errors;
  }

  if (!data.columnDomain) {
    errors.push("Can't find column domain");
    return errors;
  }

  if (!schema.data[data.columnName]) {
    errors.push("Can't find column in schema");
    return errors;
  }

  return errors;
};

type Props = IPropertiesEditorProps & InjectedOrgProps;

function PropertiesEditor(props: Props) {
  const {
    initialData,
    schemaResults,
    onSave,
    form,
    error,
    allObjects,
    datasetId,
    currentWarehouse,
    userAttributes,
    user,
  } = props;

  const debouncedOnSave = _.debounce(onSave, 200);

  const [loadingValues, setLoadingValues] = useState<boolean>(false);

  const { __typename, ...formatedInitialData } = initialData
    ? initialData
    : ({} as any);

  if (
    schemaResults.status === "initial" ||
    schemaResults.status === "loading"
  ) {
    return (
      <Feednack>
        <div>
          <Loading />
          <div>Loading Schema...</div>
        </div>
      </Feednack>
    );
  }

  if (schemaResults.status === "error") {
    return <Feednack>An unexpected error happened</Feednack>;
  }

  const onValuesChange = (changedValues: any, values: any) => {
    // we update the property name on column change
    if (changedValues?.columnName) {
      const label = humanizeString(changedValues.columnName);
      values.label = label;
      form.setFieldValue("label", label);
    }

    // we reset formatterConfig when formatter changes
    if (changedValues?.formatter) {
      values.formatterConfig = null;
      form.setFieldValue("formatterConfig", null);
      if (changedValues.formatter === "pills") {
        getDistinctValues();
      }
    }
    if (values.type !== "userProperty") {
      form.setFieldValue("userPropertyMapping", null);
      values.userPropertyMapping = undefined;
    }
    // we delete foreign keys if not applicable
    if (values.type !== "foreignKey") {
      form.setFieldValue("foreignKey", null);
      values.foreignKey = undefined;
    }
    // we delete formatters if not applicable
    if (values.type !== "standard") {
      form.setFieldValue("formatter", null);
      form.setFieldValue("formatterConfig", null);
      values.formatter = null;
      values.formatterConfig = null;
    }
    // we delete formatterConfig if we delete formatter
    if (!values.formatter) {
      values.formatterConfig = null;
    }
    // PILLS
    if (values.formatter === "pills") {
      // we create the inital values when selecting the pills formatter
      if (!values.formatterConfig) {
        values.formatterConfig = { default: defaultColor, values: [] };
      }
    }
    if (values.columnName) {
      values.columnDomain = schemaResults.data[values.columnName]?.domain;
    }
    debouncedOnSave(values);
  };

  const getDistinctValues = async () => {
    const tableId = generateUniqueId();
    const groupId = generateUniqueId();
    const sortId = generateUniqueId();
    const limitId = generateUniqueId();
    const columnName = form.getFieldValue("columnName");

    setLoadingValues(true);

    try {
      const results = await computeTransformations(currentWarehouse.id, {
        base: [
          {
            operation: {
              type: "Table.FromWhalyDataset",
              args: { datasetId: datasetId },
            },
            var: tableId,
            domain: "datasetResolver",
          },
          {
            var: groupId,
            domain: "datasetResolver",
            operation: {
              type: "Table.Group",
              args: {
                keys: [columnName],
                table: tableId,
                aggregatedColumns: [
                  {
                    aggregatedColumn: columnName,
                    aggregationType: "COUNT",
                    destinationColumnName: "_wly_counter",
                  },
                ],
              },
            },
          },
          {
            var: sortId,
            operation: {
              type: "Table.Sort",
              args: {
                table: groupId,
                condition: [
                  {
                    column: "_wly_counter",
                    sort: "DESC",
                  },
                ],
              },
            },
            domain: "datasetResolver",
          },
          {
            var: limitId,
            operation: {
              type: "Table.FirstN",
              args: {
                table: sortId,
                countOrCondition: 50,
              },
            },
            domain: "datasetResolver",
          },
        ],
      });
      if (form.getFieldValue("formatter") === "pills") {
        form.setFieldValue("formatterConfig", {
          default: defaultColor,
          values: (results.data.base as TableResult).map((r, i) => {
            return {
              value: String(r[columnName]),
              color: getColorAtIndex(i),
            };
          }),
        });
        setTimeout(() => {
          onValuesChange(null, form.getFieldsValue());
        }, 50);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingValues(false);
    }
  };

  return (
    <Form
      initialValues={formatedInitialData}
      layout="vertical"
      form={form}
      style={formatedInitialData ? { padding: "24px 0" } : {}}
      onFinish={async (v) => {
        try {
          await form.validateFields();
          const values = form.getFieldsValue();

          if (values.columnName) {
            values.columnDomain = schemaResults.data[values.columnName]?.domain;
          }

          await onSave({
            ...formatedInitialData,
            ...values,
          });
        } catch (error) {
          console.error("Validate Failed:", error);
        }
      }}
      onValuesChange={onValuesChange}
    >
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        {error && error.length > 0 ? <ErrorRenderer error={error} /> : null}
        <WlyCard title="Property Information">
          <Form.Item required name="label" label="Label">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item required name="columnName" label="Column">
            <SchemaSelect schema={schemaResults.data} alreadyUsed={[]} />
          </Form.Item>
          <Form.Item name="hierarchyPath" label="Hierarchy path">
            <Input />
          </Form.Item>
        </WlyCard>
        <WlyCard title="Property type">
          <Form.Item required name="type" label="Type">
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={"standard"}>
                  <div>
                    <div>
                      <Typography.Text strong={true}>Standard</Typography.Text>
                    </div>
                    <div>
                      <Typography.Text type="secondary" italic={true}>
                        The property will be displayed as text
                      </Typography.Text>
                    </div>
                  </div>
                </Radio>
                <Radio value={"foreignKey"}>
                  <div>
                    <div>
                      <Typography.Text strong={true}>
                        Foreign key
                      </Typography.Text>
                    </div>
                    <div>
                      <Typography.Text type="secondary" italic={true}>
                        The property will be displayed as a link to the selected
                        object
                      </Typography.Text>
                    </div>
                  </div>
                </Radio>
                <Radio value={"userProperty"}>
                  <div>
                    <div>
                      <Typography.Text strong={true}>User</Typography.Text>
                    </div>
                    <div>
                      <Typography.Text type="secondary" italic={true}>
                        The property will be displayed as a Whaly user
                      </Typography.Text>
                    </div>
                  </div>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const values = form.getFieldsValue();
              if (values.type === "foreignKey") {
                return (
                  <Form.Item required name="foreignKey" label="Object">
                    <Select>
                      {allObjects.map((o) => (
                        <Select.Option key={o.id} value={o.id}>
                          {o.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              } else if (values.type === "userProperty") {
                return (
                  <Form.Item
                    required
                    name="userPropertyMapping"
                    label="User Property"
                  >
                    <Select>
                      <Select.Option value="id">
                        Id -{" "}
                        <Typography.Text type="secondary">
                          Standard attribute
                        </Typography.Text>
                      </Select.Option>
                      <Select.Option value="email">
                        Email -{" "}
                        <Typography.Text type="secondary">
                          Standard attribute
                        </Typography.Text>
                      </Select.Option>
                      {Object.entries(userAttributes)
                        .filter((ua) => !ua[1].meta.allowMultipleValues)
                        .map((ua) => {
                          return (
                            <Select.Option
                              key={ua[1].meta.id}
                              value={`custom_${ua[1].meta.technicalName}`}
                            >
                              {ua[1].meta.label}-{" "}
                              <Typography.Text type="secondary">
                                Custom attribute
                              </Typography.Text>
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                );
              }
              return null;
            }}
          </Form.Item>
        </WlyCard>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const values = form.getFieldsValue();
            if (
              values.type !== "foreignKey" &&
              values.type !== "userProperty"
            ) {
              return (
                <WlyCard title="Property format">
                  <Form.Item
                    help="select what property on the user your field matches."
                    required
                    name="formatter"
                    label="Format"
                  >
                    <Select
                      options={[
                        { label: "Don't format", value: null },
                        { label: "Pills", value: "pills" },
                        ...(user.isAdmin
                          ? [
                              { label: "Activities", value: "activities" },
                              { label: "Score", value: "score" },
                            ]
                          : []),
                      ]}
                    />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldsValue }) => {
                      const values = getFieldsValue();
                      if (values.formatter === "pills") {
                        if (loadingValues) {
                          return <Loading />;
                        } else {
                          return (
                            <Form.Item name={"formatterConfig"} noStyle>
                              <PillFormatter form={form} />
                            </Form.Item>
                          );
                        }
                      } else if (values.formatter === "activities") {
                        return (
                          <Form.Item name={"formatterConfig"} noStyle>
                            <ActivitiesFormatter
                              schema={schemaResults.data}
                              form={form}
                              allObjects={allObjects}
                            />
                          </Form.Item>
                        );
                      } else if (values.formatter === "score") {
                        return (
                          <Form.Item name={"formatterConfig"} noStyle>
                            <ScoreFormatter
                              schema={schemaResults.data}
                              form={form}
                            />
                          </Form.Item>
                        );
                      } else {
                        return null;
                      }
                    }}
                  </Form.Item>
                </WlyCard>
              );
            } else {
              return null;
            }
          }}
        </Form.Item>
      </Space>
    </Form>
  );
}

export default compose<Props, IPropertiesEditorProps>(WithOrg)(
  PropertiesEditor
);
