import _ from "lodash";
import React, { createRef, useMemo, useReducer } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IObject } from "../../../interfaces/object";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { WorkspaceUIStoreProps } from "../../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { getObjectColumns } from "../../v2-demo/container/object/domain";
import type {
  ILayout,
  ILayoutAction,
  IRecordAction,
  IRecordWidgetData,
} from "../../v2-demo/container/record/component/domain";
import { fetchRecord } from "../../v2-demo/container/record/component/domain";
import RecordLayout from "../../v2-demo/container/record/component/layout/RecordLayout";
import type { IRecord } from "../../v2-demo/container/record/domain";
import { generateFakeId } from "../../workbench/workbench/exploration/domain";
import { convertLayoutToPayload } from "../domain";
import type { IScreenSize } from "./header/ObjectLayoutEditorHeader";
import ObjectLayoutEditorHeader from "./header/ObjectLayoutEditorHeader";
import { ObjectLayoutEditorSider } from "./sider/ObjectLayoutEditorSider";

import usePrevious from "../../../components/hooks/usePrevious";
import { type IDataset } from "../../../interfaces/sources";
import RecordEmailLayout from "../../v2-demo/container/record/component/layout/RecordEmailLayout";
import { renderRecordEmailMJML } from "../../v2-demo/container/record/component/layout/RecordEmailMjml";
import "./CustomLayoutEditorInner.scss";

interface ICustomLayoutEditorInnerProps {
  layout: ILayout;
  object: IObject;
  onSave: (
    orgId: string,
    objectId: string,
    layout: ILayout,
    selectedRecordId?: string
  ) => Promise<void>;
  onChange?: (layout: ILayout, recordId: string) => void;
  boxed: boolean;
  onRename?: () => void;
  onMarkAsDefault?: () => void;
  recordId?: string;
  allDatasets: IDataset[];
}

type Props = ICustomLayoutEditorInnerProps &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  RouteComponentProps &
  WorkspaceUIStoreProps;

const executeAction = (state: ILayout, action: IRecordAction): ILayout => {
  if (action.type === "header::modify") {
    return { ...state, header: action.data };
  }
  if (action.type === "body::modify") {
    return { ...state, config: action.data };
  }
  if (action.type === "layout::add") {
    return {
      ...state,
      rows: [...state.rows, action.data],
    };
  }
  if (action.type === "layout::move") {
    const currentParentId = action.data.parentColumn?.id;
    const currentId = action.data.id;
    const currentRow = action.data;
    const currentPosition = action.data.position;
    let currentRows = state.rows.filter(
      (r) => r.parentColumn?.id === currentParentId && currentId !== r.id
    );

    currentRows = _.sortBy(currentRows, ["position"]);

    if (currentPosition < 0) {
      currentRows = [currentRow, ...currentRows];
    } else if (currentPosition >= currentRows.length) {
      currentRows = [...currentRows, currentRow];
    } else {
      currentRows = currentRows.flatMap((r, i) => {
        if (i === currentPosition) {
          if (action.position === "top") {
            return [currentRow, r];
          } else {
            return [r, currentRow];
          }
        }
        return r;
      });
    }

    const positionMapping = currentRows
      .map((cr, i) => ({ ...cr, position: i }))
      .reduce((acc, v) => {
        return {
          ...acc,
          [v.id]: v.position,
        };
      }, {});

    return {
      ...state,
      rows: state.rows.map((r) => {
        if (typeof positionMapping[r.id] === "number") {
          return {
            ...r,
            position: positionMapping[r.id],
          };
        }
        return r;
      }),
    };
  }
  if (action.type === "layout::remove") {
    const currentParentId = action.data.parentColumn?.id;
    const currentId = action.data.id;
    const currentRows = state.rows.filter(
      (r) => r.parentColumn?.id === currentParentId && currentId !== r.id
    );
    const sortedRows = _.sortBy(currentRows, ["position"])
      .map((r, i) => ({
        ...r,
        position: i,
      }))
      .reduce((acc, v) => {
        return {
          ...acc,
          [v.id]: v.position,
        };
      }, {});

    const removedColIds = action.data.columns.map((c) => c.id);

    return {
      ...state,
      rows: state.rows.flatMap((r) => {
        if (r.id === action.data.id) {
          return [];
        }
        if (typeof sortedRows[r.id] === "number") {
          return [
            {
              ...r,
              position: sortedRows[r.id],
            },
          ];
        }
        return [r];
      }),
      widgets: state.widgets.flatMap((w) => {
        if (w.parentColumn?.id && removedColIds.includes(w.parentColumn?.id)) {
          return [];
        }
        return [w];
      }),
    };
  }
  if (action.type === "layout::modify") {
    return {
      ...state,
      rows: state.rows.map((r) => {
        if (r.id === action.data.id) {
          return action.data;
        } else {
          return r;
        }
      }),
    };
  }
  if (action.type === "widget::add") {
    return {
      ...state,
      widgets: [...state.widgets, action.data],
    };
  }
  if (action.type === "widget::modify") {
    return {
      ...state,
      widgets: state.widgets.map((w) => {
        if (w.id === action.data.id) {
          return action.data;
        }
        return w;
      }),
    };
  }
  if (action.type === "widget::remove") {
    const currentParentId = action.data.parentColumn?.id;
    const currentId = action.data.id;
    let currentWidgets = state.widgets.filter(
      (w) => w.id !== currentId && currentParentId === w.parentColumn?.id
    );

    currentWidgets = _.sortBy(currentWidgets, ["position"]);

    const positionMapping = currentWidgets
      .map((cr, i) => ({ ...cr, position: i }))
      .reduce((acc, v) => {
        return {
          ...acc,
          [v.id]: v.position,
        };
      }, {});

    return {
      ...state,
      widgets: state.widgets.flatMap((r) => {
        if (r.id === action.data.id) {
          return [];
        }
        if (typeof positionMapping[r.id] === "number") {
          return [
            {
              ...r,
              position: positionMapping[r.id],
            },
          ];
        }
        return [r];
      }),
    };
  }
  if (action.type === "widget::move") {
    const currentParentId = action.data.parentColumn?.id;
    const currentId = action.data.id;
    const currentWidget = action.data;
    const currentPosition = action.data.position;

    let currentWidgets = state.widgets.filter(
      (w) => w.id !== currentId && currentParentId === w.parentColumn?.id
    );

    currentWidgets = _.sortBy(currentWidgets, ["position"]);

    if (currentWidgets.length === 0) {
      currentWidgets = [currentWidget, ...currentWidgets];
    } else if (currentPosition < 0) {
      currentWidgets = [currentWidget, ...currentWidgets];
    } else if (currentPosition > currentWidgets.length) {
      currentWidgets = [...currentWidgets, currentWidget];
    } else {
      currentWidgets = currentWidgets.flatMap((r, i) => {
        if (i === currentPosition && action.position === "top") {
          return [currentWidget, r];
        }
        if (i === currentPosition && action.position === "bottom") {
          return [r, currentWidget];
        }
        return r;
      });
    }

    const positionMapping = currentWidgets
      .map((cr, i) => ({ ...cr, position: i }))
      .reduce((acc, v) => {
        return {
          ...acc,
          [v.id]: v.position,
        };
      }, {});

    if (!action.colIdToReorder) {
      // we have moved inside the same column

      return {
        ...state,
        widgets: state.widgets.map((r) => {
          if (typeof positionMapping[r.id] === "number") {
            return {
              ...r,
              parentColumn:
                r.id === currentId ? action.data.parentColumn : r.parentColumn,
              position: positionMapping[r.id],
            };
          }
          return r;
        }),
      };
    } else {
      // we have moved from one column to another
      let previousWidgets = state.widgets.filter(
        (w) =>
          currentId !== w.id && action.colIdToReorder === w.parentColumn?.id
      );

      previousWidgets = _.sortBy(previousWidgets, ["position"]).map((w, i) => ({
        ...w,
        position: i,
      }));
      const enhancedPositionMapping = previousWidgets.reduce((acc, v) => {
        return {
          ...acc,
          [v.id]: v.position,
        };
      }, positionMapping);

      return {
        ...state,
        widgets: state.widgets.map((r) => {
          if (typeof enhancedPositionMapping[r.id] === "number") {
            return {
              ...r,
              parentColumn:
                r.id === currentId ? action.data.parentColumn : r.parentColumn,
              position: enhancedPositionMapping[r.id],
            };
          }
          return r;
        }),
      };
    }
  }
  return state;
};

function CustomLayoutEditorInner(props: Props) {
  const {
    layout: initialLayout,
    antUtils: { message },
    org,
    location: { search },
    object,
    boxed,
    user,
    onChange,
    onSave: onSaveProps,
    recordId: recordIdFromProps,
    allDatasets,
    onRename,
  } = props;

  const [saving, setSaving] = React.useState<boolean>(false);
  const [renderMjml, setRenderMjml] = React.useState<boolean>(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [size, setSize] = React.useState<IScreenSize>("desktop");
  const ref = createRef();

  const [recordId, setRecordId] = React.useState<string | undefined>(
    recordIdFromProps
  );

  const prevRecordId = usePrevious(recordId);

  const [layout, onLayoutChange] = useReducer(
    (state: ILayout, actions: ILayoutAction[]) => {
      return actions.reduce((acc, a) => {
        return executeAction(acc, a);
      }, state);
    },
    initialLayout
  );

  const [selectedComponent, setSelectedComponent] = React.useState<
    string | null
  >();

  const [hoveredComponent, setHoveredComponent] = React.useState<
    string | null
  >();

  const availableColumns = useMemo(() => getObjectColumns(object), [object]);

  const [fakeId] = React.useState(generateFakeId());

  const buildData = (): IRecordWidgetData => ({
    status: "success",
    data: {
      ...availableColumns.reduce<IRecord>((acc, v) => {
        const buildValue = () => {
          if (v.type === "property") {
            if (v.type === "property" && v.data.key.endsWith(".label")) {
              return `${fakeId}||name||https://picsum.photos/200`;
            } else if (v.type === "property" && v.data.key.endsWith(".id")) {
              return fakeId;
            } else if (v.type === "property" && v.data.key.endsWith(".name")) {
              return "name";
            } else if (v.type === "property" && v.data.domain === "BOOLEAN") {
              return "true";
            } else if (v.type === "property" && v.data.domain === "NUMERIC") {
              return "12";
            } else if (v.type === "property" && v.data.domain === "TIME") {
              return "2023-10-17 10:36";
            } else {
              return v.data.label;
            }
          } else if (v.type === "metric") {
            return 1;
          }
        };
        return {
          ...acc,
          [v.data.key]: buildValue(),
        } as IRecord;
      }, {} as IRecord),
    },
  });

  const data = useMemo(() => buildData(), [availableColumns]);

  const [record, setRecord] = React.useState<AsyncData<IRecord>>({
    status: "loading",
  });

  React.useEffect(() => {
    if (recordId) {
      fetchRecord(org.id, recordId, object, setRecord);
    } else {
      setRecord(data);
    }
  }, [recordId, org.id, object]);

  React.useEffect(() => {
    if (
      onChange &&
      (!_.isEqual(initialLayout, layout) || !_.isEqual(recordId, prevRecordId))
    ) {
      onChange(layout, recordId!);
    }
  }, [layout, recordId]);

  const onSave = async () => {
    setSaving(true);
    const loading = message.loading({ content: "Saving..." });
    try {
      const data = await GraphQLService<{
        mutateObjectLayoutFromDeclaration: boolean;
      }>(
        `
        mutation mutateObjectLayoutFromDeclaration($orgId: ID!, $objectId: ID!, $payload: JSON) {
          mutateObjectLayoutFromDeclaration(orgId: $orgId, objectId: $objectId, payload: $payload)
        }
      `,
        {
          orgId: org.id,
          objectId: object.id,
          payload: convertLayoutToPayload(layout),
        }
      );
      if (data.mutateObjectLayoutFromDeclaration === false) {
        message.error("There was an error saving your layout");
      } else {
        message.success("Successfully saved layout");
        onSaveProps(org.id, object.id, layout);
      }
    } catch (err) {
      console.error(err);
      message.error("Error while saving");
    } finally {
      setSaving(false);
      loading();
    }
  };

  if (record.status === "error") {
    return (
      <Feednack>
        <div>There was an error fetching your record...</div>
      </Feednack>
    );
  }

  const fetchedRecordData: IRecordWidgetData =
    record.status === "success"
      ? {
          status: "success",
          data: record.data,
        }
      : {
          status: "loading",
        };

  const renderLayout = () => {
    if (layout.type === "RECORD") {
      return (
        <RecordLayout
          object={object}
          layout={Object.assign({}, layout)}
          data={recordId ? fetchedRecordData : data}
          edit={!preview as any}
          selected={selectedComponent!}
          hovered={hoveredComponent!}
          preview={preview}
          setAction={(action, key) => {
            if (action === "click") {
              setSelectedComponent(key);
            } else {
              setHoveredComponent(key);
            }
          }}
          onLayoutChange={onLayoutChange}
        />
      );
    } else if (layout.type === "EMAIL") {
      // console.log("layout", layout.id, object.id);
      return (
        <RecordEmailLayout
          object={object}
          layout={Object.assign({}, layout)}
          data={recordId ? fetchedRecordData : data}
          edit={!preview as any}
          selected={selectedComponent!}
          hovered={hoveredComponent!}
          preview={preview}
          emailTemplateMjml={object.org.emailTemplateMjml}
          setAction={(action, key) => {
            if (action === "click") {
              setSelectedComponent(key);
            } else {
              setHoveredComponent(key);
            }
          }}
          onLayoutChange={onLayoutChange}
        />
      );
    }
  };

  return (
    <div className="object-layout-editor">
      <div className="object-layout-editor-header">
        <ObjectLayoutEditorHeader
          preview={preview}
          setPreview={setPreview}
          object={object}
          setSize={setSize}
          size={size}
          onSave={onSave}
          saving={saving}
          recordId={recordId}
          layout={layout}
          sendEmail={async () => {
            const l = message.loading("Sending...", 0);
            try {
              const rec = recordId ? fetchedRecordData : data;
              if (rec.status !== "success") {
                l();
                message.error("Please wait til data is loaded");
                return;
              }

              const s = await renderRecordEmailMJML(
                layout,
                object,
                rec.data,
                org,
                user
              );
              await GraphQLService(
                `
              mutation sendEmail($orgId: ID!, $sections: [String]!) {
                sendEmail(orgId: $orgId, sections: $sections)
              }
              `,
                {
                  orgId: org.id,
                  sections: s,
                }
              );
              l();
              message.success("Sucessfully send test email");
            } catch (err) {
              console.error(err);
              l();
              message.error("Error sending your email");
            }
          }}
          displayName={{
            status: "success",
            data: { id: object.id, label: object.name },
          }}
          canBeListed={object.canBeListed}
          setRecordId={setRecordId}
          onRename={onRename}
        />
      </div>
      <div className="object-layout-editor-content">
        <div
          onClick={() => {
            setSelectedComponent(null);
            setHoveredComponent(null);
          }}
          className="object-layout-editor-content-inner"
        >
          <div
            className={`object-layout-editor-content-iframe ${
              layout.type === "EMAIL" && preview ? "email-preview" : ""
            } ${size}`}
          >
            {renderLayout()}
          </div>
        </div>
        <div
          className={`object-layout-editor-content-sider ${
            preview ? "preview" : ""
          }`}
        >
          <ObjectLayoutEditorSider
            object={object}
            layout={layout}
            behavior={{
              hover: {
                component: hoveredComponent!,
                setComponent: setHoveredComponent,
              },
              selected: {
                component: selectedComponent!,
                setComponent: setSelectedComponent,
              },
            }}
            onLayoutChange={onLayoutChange}
            record={(data as any).data}
            allDatasets={allDatasets}
          />
        </div>
      </div>
    </div>
  );
}

export default compose<Props, ICustomLayoutEditorInnerProps>(
  withAntUtils,
  WithOrg,
  withRouter
)(CustomLayoutEditorInner);
