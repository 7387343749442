import { Typography } from "antd";
import * as React from "react";
import { parseLabel } from "../../../../../../domain";

export interface IHeadingProps {
  level: number;
  id: string;
}

export function Heading(props: React.PropsWithChildren<IHeadingProps>) {
  const { level, children, id } = props;

  const renderChildren = () => {
    if (typeof children === "string" && children.includes("||")) {
      // we have a label
      const { id, name, image } = parseLabel(children);
      if (name) {
        return name;
      }
    }
    return children;
  };

  return (
    <Typography.Title id={id} level={level as any}>
      {renderChildren()}
    </Typography.Title>
  );
}
