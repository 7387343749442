import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import { type IGeneratedTextConfig } from "./domain";
import GeneratedTextEditor from "./editor/GeneratedTextEditor";
import GeneratedTextRenderer from "./renderer/GeneratedTextRenderer";

export const generatedTextDefinition: IRendererConfig<IGeneratedTextConfig> = {
  allowCardRemoval: true,
  parseConfig: (widget) => {
    const defaultValue: IGeneratedTextConfig = {
      model: "gpt-3.5-turbo",
      prompt: "",
      dataSheets: [],
    };

    if (!widget.config) {
      return defaultValue;
    }
    try {
      const config: IGeneratedTextConfig = JSON.parse(widget.config);

      return config;
    } catch (err) {
      console.error(err);
      return defaultValue;
    }
  },
  renderEditor: (widget, object, record, conf, form, org, datasets) => {
    return (
      <GeneratedTextEditor
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        form={form}
        datasets={datasets}
      />
    );
  },
  renderComponent: (widget, object, record, conf, edit) => {
    return (
      <GeneratedTextRenderer
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        edit={edit}
      />
    );
  },
  renderLoadingComponent: (widget, conf) => (
    <Skeleton.Button
      active
      block
      shape="default"
      size="small"
      style={{ height: 250 }}
    />
  ),
};
