import Markdoc from "@markdoc/markdoc";
import { getObjectColumns } from "../../../../../object/domain";
import { fetchDatasheetConcurrentlyReturnJSON } from "../../../common/markdoc/datasheet/api";
import {
  convertDataSheetNameToMarkdocVariable,
  convertKeyToMarkdocVariable,
  formatRenderableTreeForGroupsInEmail,
  getFormattedRecord,
  getParsedDoc,
} from "../../../common/markdoc/domain";
import { nodesConfig } from "../../../widgets/markdown/widget/markdoc-react-component/config";
import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetTextConfig } from "../domain";
import { getDefaultConfig } from "../domain";

export const EmailWidgetTextMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetTextConfig
> = async (widget, object, record, conf, org, user) => {
  const defaults = getDefaultConfig(conf, record);
  const source = defaults.content as string;
  const dataSheets = defaults.dataSheets;

  const padding =
    defaults.spacing === "normal"
      ? `padding-top="10" padding-bottom="10"`
      : `padding-top="0" padding-bottom="0"`;

  if (!source) {
    return `<mj-text ${padding} align="center"><i> Please configure this widget</i></mj-text>`;
  }

  try {
    const dataSheetsResults = await fetchDatasheetConcurrentlyReturnJSON(
      dataSheets,
      org,
      record,
      object
    );

    const formattedDatasheetResults = Object.keys(dataSheetsResults).reduce(
      (acc, v) => {
        const dataSheetVar = convertDataSheetNameToMarkdocVariable(v);
        return {
          ...acc,
          [dataSheetVar]: dataSheetsResults[v].map((d) => {
            return Object.keys(d).reduce((a, b) => {
              return {
                ...a,
                [`${dataSheetVar}_${convertKeyToMarkdocVariable(b)}`]: d[b],
              };
            }, {});
          }),
        };
      },
      {}
    );

    const columns = getObjectColumns(object);
    const formattedRecord = getFormattedRecord(
      columns,
      record,
      formattedDatasheetResults
    );

    const content = getParsedDoc(
      source,
      formattedRecord,
      user,
      columns,
      nodesConfig
    );

    const newContent = formatRenderableTreeForGroupsInEmail(content);

    const html = Markdoc.renderers.html(newContent);
    return `<mj-text ${padding} align="${
      defaults.align ? defaults.align : "left"
    }">${html
      .replaceAll("<article>", "")
      .replaceAll("</article>", "")}</mj-text>`;
  } catch (err) {
    console.error(err);
    return `<mj-text ${padding} align="${
      defaults.align ? defaults.align : "left"
    }">There was an error generating this widget</mj-text>`;
  }
};
