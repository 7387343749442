import Editor from "@monaco-editor/react";
import { Empty } from "antd";
import { compose } from "../../../../components/compose/WlyCompose";
import Loading from "../../../../components/layout/feedback/loading";
import type { InjectedOrgProps } from "../../../../containers/orgs/WithOrg";
import WithOrg from "../../../../containers/orgs/WithOrg";
import type { ISource } from "../../../../interfaces/sources";
import { useGetSourceState } from "../hooks/useGetSourceState";
import "./SourceSchema.scss";

type ISourceStateProps = {
  source: ISource;
};

type Props = ISourceStateProps & InjectedOrgProps;

function SourceState(props: Props) {
  const { source } = props;
  const { sourceState, loading, pending, error } = useGetSourceState(source);

  if (loading || pending) {
    return <Loading />;
  }
  if (!sourceState || error) {
    return <Empty description={error ? error : "No data"} />;
  }

  return (
    <Editor
      defaultLanguage="json"
      theme="vs-dark"
      defaultValue={JSON.stringify(sourceState, null, 2)}
      options={{
        readOnly: true,
        minimap: {
          enabled: false,
        },
        lineNumbers: "on",
      }}
    />
  );
}

export default compose<Props, ISourceStateProps>(WithOrg)(SourceState);
