import { Affix, Space, Typography } from "antd";
import * as React from "react";

export interface IFloatingMenuProps {}

export function FloatingMenu(
  props: React.PropsWithChildren<IFloatingMenuProps>
) {
  const { children } = props;

  const items = [
    {
      label: "ITM SM VULBENS",
      subLabel: "UF",
      logo: "https://cdn.whaly.io/retail-demo/intermarche.webp",
      rating: 8.4,
      isSelected: false,
      route: "/danone/object-centered/stores/20/73",
    },
    {
      label: "CRF MARKET AMANCY",
      subLabel: "BSA",
      logo: "https://cdn.whaly.io/retail-demo/carrefour.png",
      rating: 7.3,
      isSelected: false,
      route: "/danone/object-centered/stores/6/74",
    },
    {
      label: "MIGROS ETREMBIERES",
      subLabel: "BSA",
      logo: "https://cdn.whaly.io/retail-demo/cora.png",
      rating: 6.3,
      isSelected: false,
      route: "/danone/object-centered/stores/22/74",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      {children}
      <div>
        <Affix
          style={{
            position: "absolute",
            top: 0,
            right: -258,
            width: 192,
          }}
          offsetTop={12}
          target={() => {
            const el = document.getElementsByClassName("record-body");
            if (el && el[0]) {
              return el[0] as HTMLDivElement;
            }
            return window;
          }}
        >
          {/* <div>
            <Typography.Text strong>Synthèses de visites</Typography.Text>
          </div>
          <div style={{ marginTop: 8 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>
                <Typography.Text style={{ cursor: "pointer" }} type="secondary">
                  Semaine 44
                </Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary" underline>
                  Semaine 43
                </Typography.Text>
              </div>
            </Space>
          </div> */}
          <div>
            <div style={{ marginTop: 24 }}>
              <Typography.Text strong>
                Magasin à visiter le même jour
              </Typography.Text>
            </div>
            <div style={{ marginTop: 8 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {items.map((i) => {
                  return (
                    <div
                      key={i.label}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 12,
                      }}
                    >
                      <div style={{ flex: 1, width: 0 }}>
                        <Typography.Text
                          style={{ cursor: "pointer" }}
                          type="secondary"
                          ellipsis
                        >
                          {i.label}
                        </Typography.Text>
                      </div>
                      <div style={{ flex: `0 40px` }}>
                        <div
                          style={{
                            height: 18,
                            backgroundColor:
                              i.rating > 8 ? "#FFE8EE" : "#FEFAEC",
                            display: "flex",
                            gap: 8,
                            padding: `0 6px`,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 16,
                          }}
                        >
                          <div>
                            <Typography.Text>{i.rating}</Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Space>
            </div>
          </div>
          <div>
            <div style={{ marginTop: 24 }}>
              <Typography.Text strong>
                A visiter autours semaine 44
              </Typography.Text>
            </div>
            <div style={{ marginTop: 8 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {items.map((i) => {
                  return (
                    <div
                      key={i.label}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 12,
                      }}
                    >
                      <div style={{ flex: 1, width: 0 }}>
                        <Typography.Text
                          style={{ cursor: "pointer" }}
                          type="secondary"
                          ellipsis
                        >
                          {i.label}
                        </Typography.Text>
                      </div>
                      <div style={{ flex: `0 40px` }}>
                        <div
                          style={{
                            height: 18,
                            backgroundColor:
                              i.rating > 8 ? "#FFE8EE" : "#FEFAEC",
                            display: "flex",
                            gap: 8,
                            padding: `0 6px`,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 16,
                          }}
                        >
                          <div>
                            <Typography.Text>{i.rating}</Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Space>
            </div>
          </div>
        </Affix>
      </div>
    </div>
  );
}
