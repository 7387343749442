import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext } from "react";
import type { publicApiType } from "react-horizontal-scrolling-menu";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IUser } from "../../../../../../../../interfaces/user";
import { type IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { type IWidgetParsedConfig } from "../domain";
import SuggestedCategoryRenderer from "./SuggestedCategoryRenderer";
import "./SuggestedProductsRenderer.scss";
import "./hideScrollbar.scss";

type Props = {
  data: { [key: string]: string | number | boolean }[];
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetParsedConfig;
  edit?: boolean;
  user: IUser;
  parentContext: string;
  reload?: () => void;
};

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    useContext<publicApiType>(VisibilityContext);

  return (
    <Button
      shape="circle"
      size="large"
      hidden={isFirstItemVisible}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      icon={<LeftOutlined style={{ fontSize: 16 }} />}
      style={{
        transform: "scale(0.7)",
        translate: -3,
        marginTop: -2,
      }}
    />
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext } =
    useContext<publicApiType>(VisibilityContext);

  return (
    <Button
      shape="circle"
      size="large"
      hidden={isLastItemVisible}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      icon={<RightOutlined style={{ fontSize: 16 }} />}
      style={{
        transform: "scale(0.7)",
        translate: 3,
        marginTop: -2,
      }}
    />
  );
}

export default function SuggestedProductsRenderer(props: Props) {
  const { data, conf, record, widget, object, user, parentContext, reload } =
    props;

  const { categoryKey } = conf;

  const formattedData = categoryKey
    ? data.reduce<{ [key: string]: Array<any> }>((acc, curr) => {
        const toreturn = acc;
        const getter = categoryKey;
        if (!toreturn[curr[getter].toString()]) {
          toreturn[curr[getter].toString()] = [];
          toreturn[curr[getter].toString()].push(curr);
        } else {
          toreturn[curr[getter].toString()].push(curr);
        }
        return toreturn;
      }, {})
    : { default: data };

  const categories = Object.keys(formattedData);

  const action = object.registeredActions?.find(
    (ra) => ra.id === conf.displayedAction
  );

  return (
    <div className="wly-suggested-products">
      {categories.map((c) => {
        return (
          <SuggestedCategoryRenderer
            key={c}
            action={action}
            data={formattedData}
            category={c}
            object={object}
            conf={conf}
            user={user}
            parentContext={parentContext}
            showTitle={!!categoryKey}
            reload={reload}
          />
        );
      })}
    </div>
  );
}
