import * as React from "react";
import { COLOR_GOLD } from "../../../../../../../layout/domain";

export interface ICalloutProps {}

export function Callout(props: React.PropsWithChildren<ICalloutProps>) {
  const { children } = props;
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          width: 4,
          backgroundColor: COLOR_GOLD,
          borderRadius: 12,
          height: "100%",
          top: 0,
          bottom: 0,
          left: -18,
        }}
      />
      <div>{children}</div>
    </div>
  );
}
