import type { FormInstance } from "antd";
import { Form, Input, Select, Space } from "antd";
import _ from "lodash";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type {
  AvailableDimension,
  IAvailableDimensionGroup,
} from "../../../../../../components/measures/filter-item/FilterItem";
import type { IObject } from "../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../object/domain";
import type { AvailableProperty } from "../../../object/viewer/domain";
import type { IRecord } from "../../domain";
import { WidgetVisibilityFilters } from "../common/visibility-filters/WidgetVisibilityFilters";
import type { IRow } from "../domain";
import { type WidgetStateConfig } from "../widgets/domain";
import { buildQueryFromDimensionAndMetrics } from "../widgets/related-lists/domain";

interface IRowEditionRendererProps {
  object: IObject;
  row: IRow;
  record: IRecord;
  form: FormInstance;
  onChange: (row: IRow) => void;
  widgetStates: WidgetStateConfig[];
}

type Props = IRowEditionRendererProps & InjectedOrgProps;

function RowEditionRenderer(props: Props) {
  const { row, object, form, org, onChange, widgetStates } = props;

  const objectColumns = getObjectColumns(object);
  const debouncedChange = _.debounce(onChange, 200);

  return (
    <Form
      form={form}
      initialValues={{
        ...row,
      }}
      onValuesChange={(e, v) => {
        const { ...rest } = v;
        const { ...initialRow } = row;
        debouncedChange({
          ...initialRow,
          ...rest,
        });
      }}
      layout="vertical"
    >
      <div style={{ margin: `0 -8px` }}>
        {/* common */}
        <ChartOptionCollapse dark title="Row Configuration">
          <Space direction="vertical" style={{ width: "100%" }}>
            <ChartOptionLine
              items={[
                {
                  content: "Title",
                  flex: 1,
                },
                {
                  content: (
                    <Form.Item noStyle name="name">
                      <Input />
                    </Form.Item>
                  ),
                  flex: 1,
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  content: "Description",
                  flex: 1,
                },
                {
                  content: (
                    <Form.Item noStyle name="description">
                      <Input />
                    </Form.Item>
                  ),
                  flex: 1,
                },
              ]}
            />
          </Space>
        </ChartOptionCollapse>
        <ChartOptionCollapse dark title="Spacing">
          <Space direction="vertical" style={{ width: "100%" }}>
            <ChartOptionLine
              items={[
                {
                  content: "Top",
                  flex: 1,
                },
                {
                  content: (
                    <Form.Item noStyle name={["config", "topSpacing"]}>
                      <Select defaultValue={"small"} style={{ width: "100%" }}>
                        <Select.Option value="none">None</Select.Option>
                        <Select.Option value="small">Small</Select.Option>
                        <Select.Option value="medium">Medium</Select.Option>
                        <Select.Option value="large">Large</Select.Option>
                      </Select>
                    </Form.Item>
                  ),
                  flex: 1,
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  content: "Bottom",
                  flex: 1,
                },
                {
                  content: (
                    <Form.Item noStyle name={["config", "bottomSpacing"]}>
                      <Select defaultValue={"small"} style={{ width: "100%" }}>
                        <Select.Option value="none">None</Select.Option>
                        <Select.Option value="small">Small</Select.Option>
                        <Select.Option value="medium">Medium</Select.Option>
                        <Select.Option value="large">Large</Select.Option>
                      </Select>
                    </Form.Item>
                  ),
                  flex: 1,
                },
              ]}
            />
          </Space>
        </ChartOptionCollapse>
        <ChartOptionCollapse dark title="Conditional Display">
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item noStyle name="displayFilters">
                    <WidgetVisibilityFilters
                      autocomplete={async (dimension) => {
                        try {
                          if (
                            widgetStates
                              .map((w) => `widgetState::${w.key}`)
                              .includes(dimension)
                          ) {
                            const values =
                              widgetStates.find(
                                (ws) => `widgetState::${ws.key}` === dimension
                              )?.values || [];
                            return values;
                          }

                          const dim = objectColumns.find(
                            (ac) =>
                              ac.type === "property" &&
                              dimension === ac.data.key
                          );

                          if (!dim) {
                            return [];
                          }

                          const dimKey = (dim.data as AvailableProperty)
                            .sortAndFilterKey;
                          if (!dimKey) {
                            throw new Error("Dim not found");
                          }

                          const query = buildQueryFromDimensionAndMetrics(
                            [dimKey],
                            []
                          );

                          const data = await lagoonServiceLoad(
                            org.id,
                            query,
                            "OBJECT",
                            object.id,
                            undefined,
                            LagoonCallOrigin.WHALY_APP
                          );

                          const results = data.tablePivot();

                          return results.map((r) => r[dimKey].toString());
                        } catch (err) {
                          console.error(err);
                          throw new Error("Can't fetch recommendations");
                        }
                      }}
                      availableDimensions={[
                        ...objectColumns.map<AvailableDimension>((ac) => {
                          const prop = ac.data;
                          const domain =
                            ac.type === "property" ? ac.data.domain : "NUMERIC";
                          return {
                            key: prop.key,
                            label: prop.label,
                            description: prop.description,
                            type: "standard",
                            domain: domain,
                          };
                        }),
                        ...(widgetStates.length > 0
                          ? [
                              {
                                type: "group",
                                key: "states",
                                label: "Widget States",
                                availableDimensions:
                                  widgetStates.map<AvailableDimension>((ws) => {
                                    return {
                                      type: "standard",
                                      key: `widgetState::${ws.key}`,
                                      label: ws.label,
                                      domain: "STRING",
                                    } as AvailableDimension;
                                  }),
                              } as IAvailableDimensionGroup,
                            ]
                          : []),
                      ]}
                    />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
        </ChartOptionCollapse>
      </div>
    </Form>
  );
}

export default compose<Props, IRowEditionRendererProps>(WithOrg)(
  RowEditionRenderer
);
