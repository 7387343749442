import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { Button, Collapse, ConfigProvider, Typography } from "antd";
import React from "react";
import { useHover } from "../../../../../../../../../../components/hooks/useHover";
import "./Toggle.scss";

export interface IToggleProps {
  title: string;
  level: 1 | 2 | 3 | 4 | 5;
  defaultState: "open" | "closed";
  id: string;
}

export function Toggle(props: React.PropsWithChildren<IToggleProps>) {
  const { children, title, defaultState, level, id } = props;
  const [ref, isOver] = useHover<any>();
  const [isOpen, setIsOpen] = React.useState<boolean>(
    defaultState === "open" ? true : false
  );
  return (
    <>
      {/* <div style={{ height: 1, marginBottom: "1.2em", width: "100%" }} /> */}
      <Typography.Paragraph>
        <ConfigProvider
          theme={{
            components: {
              Collapse: {
                headerPadding: 0,
                contentPadding: `16px 0`,
              },
            },
          }}
        >
          <Collapse
            ref={ref}
            ghost
            // expandIcon={({ isActive }) => (
            //   <CaretRightOutlined rotate={isActive ? 90 : 0} />
            // )}

            className="wly_toggle"
            defaultActiveKey={defaultState === "open" ? "1" : undefined}
            onChange={() => {
              setIsOpen((r) => !r);
            }}
            items={[
              {
                key: "1",
                label: (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <Typography.Title
                      style={{ flex: `0 auto`, marginBottom: 0 }}
                      level={level}
                      id={id}
                    >
                      {title}{" "}
                    </Typography.Title>{" "}
                    <Button
                      shape="circle"
                      className="wly_toggle_action"
                      style={{ opacity: isOver ? 1 : 0 }}
                      icon={
                        isOpen ? <CaretDownOutlined /> : <CaretRightOutlined />
                      }
                      type="text"
                    />
                  </div>
                ),
                showArrow: false,
                children: (
                  <>
                    <div
                      style={{
                        marginBottom: "0.5em",
                        width: "100%",
                        height: 1,
                      }}
                    />
                    {children}
                  </>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </Typography.Paragraph>
      {!isOpen && (
        <div style={{ marginBottom: "0.5em", width: "100%", height: 1 }} />
      )}
    </>
  );
}
