import { MinusCircleOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Button, Card, Form, Input, Select, Space } from "antd";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetInsightConfig } from "../domain";

interface InsightWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetInsightConfig;
  form: FormInstance;
}

export function InsightWidgetEditor(props: InsightWidgetEditorProps) {
  const { widget, object, record, conf, form } = props;

  const objectColumns = getObjectColumns(object).filter(
    (o) => !o.data.key.endsWith(".label")
  );

  return (
    <>
      <ChartOptionCollapse dark title="Insights Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.List name={["config", "insights"]}>
                    {(fields, { add, remove }) => {
                      return (
                        <Space style={{ width: "100%" }} direction="vertical">
                          {fields.map((f, i) => {
                            return (
                              <Card size="small" key={f.key}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 8,
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <Form.Item
                                      label="Icon"
                                      name={[f.name, "icon"]}
                                    >
                                      <Select style={{ width: "100%" }}>
                                        <Select.Option value="trophy">
                                          Trophy
                                        </Select.Option>
                                        <Select.Option value="rising">
                                          Rising
                                        </Select.Option>
                                        <Select.Option value="falling">
                                          Falling
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      label="description"
                                      name={[f.name, "description"]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </div>
                                  <div style={{ flex: `0 auto` }}>
                                    <Button
                                      size="small"
                                      icon={<MinusCircleOutlined />}
                                      type="text"
                                      shape="circle"
                                      onClick={() => remove(i)}
                                    />
                                  </div>
                                </div>
                              </Card>
                            );
                          })}
                          <div>
                            <Button
                              size="small"
                              block
                              onClick={() =>
                                add({ icon: "trophy", description: "" })
                              }
                            >
                              Add
                            </Button>
                          </div>
                        </Space>
                      );
                    }}
                  </Form.List>
                ),
                flex: `1`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
