import { compose } from "../../../../../../../../../../components/compose/WlyCompose";
import { useLagoonQuery } from "../../../../../../../../../../components/hooks/query/useLagoonQuery";
import Feednack from "../../../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../../../components/layout/feedback/loading";
import type { IObject } from "../../../../../../../../../../interfaces/object";
import type { UserLocale } from "../../../../../../../../../../interfaces/user";
import type { InjectedOrgProps } from "../../../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../../../orgs/WithOrg";
import type { IRecord } from "../../../../../../domain";
import type { IWidgetKPIConfig, kpiTheme } from "../../../domain";
import { getSparklineQuery } from "../../../domain";
import { BarSparklineRenderer } from "./BarSparklineRenderer";
import { useBarSparklineConfig } from "./useBarSparklineConfig";

interface IBarSparklineProps {
  theme: kpiTheme;
  object: IObject;
  record: IRecord;
  locale: UserLocale;
  conf: IWidgetKPIConfig;
}

type Props = IBarSparklineProps & InjectedOrgProps;

function BarSparkline(props: Props) {
  const { theme, object, record, conf, locale, org, user } = props;

  const query = getSparklineQuery({
    conf: conf,
    object: object,
    record: record,
  });

  const [config, availableColumns] = useBarSparklineConfig({
    query,
    object,
    conf,
  });

  const { data, loading, error } = useLagoonQuery(query, {
    objectType: "OBJECT",
    objectId: object.id,
    org: org,
    enabled: !!query,
  });

  if (!config) {
    return <></>;
  }

  if (loading) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) {
    return (
      <Feednack>
        <>{error.message}</>
      </Feednack>
    );
  }

  return (
    <BarSparklineRenderer
      config={config}
      availableColumns={availableColumns ? availableColumns : []}
      theme={theme}
      locale={locale}
      data={data ?? []}
      user={user}
    />
  );
}

export default compose<Props, IBarSparklineProps>(WithOrg)(BarSparkline);
