import { InfoCircleFilled, WarningOutlined } from "@ant-design/icons";
import { Checkbox, Flex, Space, Tooltip, Typography } from "antd";
import React from "react";
import WlyModal from "../../../../components/modal/WlyModal";
import type { Bookmark } from "./SourceSchema";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  disabled: boolean;
  bookmarks: Bookmark[];
  bookmarksToResync: Bookmark[];
  setBookmarksToResync: (value: React.SetStateAction<Bookmark[]>) => void;
  onSubmit: () => void;
};

export const SourceStateModal = ({
  open,
  setOpen,
  disabled,
  bookmarks,
  bookmarksToResync,
  setBookmarksToResync,
  onSubmit,
}: Props) => {
  return (
    <WlyModal
      title={<ModalTitle />}
      okContent={<ModalSubmitWarning />}
      open={open}
      onClose={() => setOpen(false)}
      onOk={onSubmit}
      okButtonDisabled={disabled}
      okText="Trigger a resync"
      closable
    >
      <Space direction="vertical" size="small">
        {bookmarks.map((bookmark) => (
          <Space key={bookmark.key} direction="vertical" size="small">
            <Checkbox
              checked={bookmarksToResync.some((b) => b.key === bookmark.key)}
              onChange={(e) => {
                if (e.target.checked) {
                  setBookmarksToResync((bookmarksToResync) => [
                    ...bookmarksToResync,
                    bookmark,
                  ]);
                } else {
                  setBookmarksToResync((bookmarksToResync) =>
                    bookmarksToResync.filter((b) => b.key !== bookmark.key)
                  );
                }
              }}
            >
              <Space>
                <CheckboxLabel bookmark={bookmark} />
                {bookmark.childrens.length > 2 && (
                  <Tooltip
                    title={<BookmarkTooltipContent bookmark={bookmark} />}
                    mouseLeaveDelay={0}
                  >
                    <InfoCircleFilled style={{ color: "lightgray" }} />
                  </Tooltip>
                )}
              </Space>
            </Checkbox>
          </Space>
        ))}
      </Space>
    </WlyModal>
  );
};

const ModalTitle = () => {
  return (
    <Flex gap="small">
      <Tooltip
        title="Some tables are grouped together due to technical constraints."
        mouseLeaveDelay={0}
      >
        <InfoCircleFilled style={{ color: "lightgray" }} />
      </Tooltip>
      Pick one or more tables to resync :
    </Flex>
  );
};

const ModalSubmitWarning = () => {
  return (
    <Tooltip title="Warning: Resyncing data may take a lot of time.">
      <WarningOutlined style={{ fontSize: "18px" }} />
    </Tooltip>
  );
};

const CheckboxLabel = ({ bookmark }: { bookmark: Bookmark }) => {
  const childrensCount = bookmark.childrens.length;
  const childrensLabels = bookmark.childrens.map(({ label }) => label);

  if (childrensCount === 0) {
    return <Typography>{bookmark.label}</Typography>;
  }
  if (childrensCount > 2) {
    return (
      <Typography>
        {`${childrensLabels.slice(0, 2).join(", ")}... `}
        <b>{`and ${childrensCount - 2} more`}</b>
      </Typography>
    );
  }

  return (
    <Typography>
      {bookmark.childrens.map(({ label }) => label).join(", ")}
    </Typography>
  );
};

const BookmarkTooltipContent = ({ bookmark }: { bookmark: Bookmark }) => {
  return (
    <Flex vertical>
      <h4>{`Tables from the '${bookmark.label}' group that will be resynced:`}</h4>
      <ul>
        {bookmark.childrens.map((child) => (
          <li key={child.key}>
            <Typography.Text ellipsis style={{ color: "white" }}>
              {child.label}
            </Typography.Text>
          </li>
        ))}
      </ul>
    </Flex>
  );
};
