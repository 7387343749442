import { Skeleton } from "antd";
import cuid from "cuid";
import type { IRendererConfig } from "../domain";
import type { IWidgetTabConfig } from "./domain";
import { TitleWidgetEditor } from "./editor/TabsWidgetEditor";
import { TabsWidget } from "./widget/TabsWidgetRenderer";

export const widgetTabsDefinition: IRendererConfig<IWidgetTabConfig> = {
  removeCard: true,
  parseConfig: (widget) => {
    const defaultValue: IWidgetTabConfig = {
      id: cuid(),
      apiName: "Unnamed",
      keys: [],
    };

    if (!widget.config) {
      return defaultValue;
    }

    try {
      const config: IWidgetTabConfig = JSON.parse(widget.config);
      return config;
    } catch (err) {
      console.error(err);
      return defaultValue;
    }
  },
  renderEditor: (widget, object, record, conf, form, org) => {
    return (
      <TitleWidgetEditor
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        form={form}
      />
    );
  },
  renderComponent: (widget, object, record, conf, edit, state, setState) => {
    return (
      <TabsWidget
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        edit={edit}
        state={state}
        setState={setState}
      />
    );
  },
  renderLoadingComponent: (widget, conf) => (
    <Skeleton.Button
      active
      block
      size="small"
      shape="default"
      style={{ height: 75 }}
    />
  ),
  extractStateConfig: (widget, object, conf, edit) => {
    return {
      key: widget.id,
      label: conf.apiName ? conf.apiName : "Unnamed",
      values: (conf.keys || []).map((k, i) => {
        if (k.apiName) {
          return k.apiName;
        } else if (k.label) {
          return k.label;
        }
        return i.toString();
      }),
    };
  },
};
