import { Affix, Space, Typography } from "antd";
import _ from "lodash";
import * as React from "react";

export interface IFloatingTocProps {}

interface SummaryItem {
  key: string;
  label: string;
  children?: Array<SummaryItem>;
}

export function FloatingToc(props: React.PropsWithChildren<IFloatingTocProps>) {
  const { children } = props;
  const [selected, setSelected] = React.useState<string | undefined>("");
  const toc: Array<SummaryItem> = [
    {
      key: "actions-prioritaires",
      label: "Actions Prioritaires",
      children: [
        {
          key: "ap-1",
          label: "Ultra Frais - Assortiment INNOs & RENOs",
        },
        {
          key: "ap-2",
          label: "Ultra Frais - Transfo OP Ciblée",
        },
        {
          key: "ap-3",
          label: "Boissons sans alcool - Transfo OP Ciblée",
        },
      ],
    },

    {
      key: "uf",
      label: "Ultra Frais",
      children: [
        {
          key: "uf-1",
          label: "Assortiment INNOs & RENOs",
        },
        {
          key: "uf-2",
          label: "Transfo OP Ciblée",
        },
        {
          key: "uf-3",
          label: "Revue de l’assortiment sur la gamme Skyr",
        },
        {
          key: "uf-4",
          label: "Sur-ruptures de produits",
        },
        {
          key: "uf-5",
          label: "Visibilité sur le rayon",
        },
        {
          key: "uf-6",
          label: "Revue de l’assortiment global",
        },
      ],
    },
    {
      key: "bsa",
      label: "Boisson Sans Alcool",
      children: [
        {
          key: "bsa-1",
          label: "Transfo OP Ciblée",
        },
        {
          key: "bsa-2",
          label: "Visibilité sur le rayon",
        },
        {
          key: "bsa-3",
          label: "Revue de l’assortiment global",
        },
      ],
    },
    {
      key: "bv",
      label: "Boisson Végétale",
      children: [
        {
          key: "bsa-1",
          label: "Revue de l’assortiment global",
        },
      ],
    },
    {
      key: "ai",
      label: "Alimentation Infantile",
      children: [
        {
          key: "bsa-1",
          label: "Revue de l’assortiment global",
        },
      ],
    },
    {
      key: "prod",
      label: "Produits",
      children: [
        {
          key: "prod-1",
          label: "Skyr - 825 g (PRIO)",
        },
        {
          key: "prod-2",
          label: "Activia Bio Nature",
        },
      ],
    },
  ];

  const checkIfIdIsVisible = (id: string): boolean => {
    const el = document.getElementById(id);
    const body = document.getElementsByClassName("record-body");
    if (el && body && body[0]) {
      const elBBox = el.getBoundingClientRect();
      const bodyBbox = body[0].getBoundingClientRect();
      if (elBBox.y - bodyBbox.y > 24) {
        return true;
      } else {
        if (elBBox.y - bodyBbox.y <= 24) {
          return false;
        }
      }
    }
    return true;
  };

  const findLastLevelVisible = (): string | undefined => {
    const main = _.reverse(toc).find(
      (v, i, s) => checkIfIdIsVisible(v.key) === false
    );
    if (main) {
      if (main.children) {
        const sub = _.reverse(main.children).find(
          (v) => checkIfIdIsVisible(v.key) === false
        );
        if (sub) {
          return sub.key;
        }
      }
      return main.key;
    }
    return undefined;
  };

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  React.useEffect(() => {
    const onScroll = () => {
      const sele = findLastLevelVisible();
      if (sele !== selected) {
        setSelected(sele);
      }
    };

    const el = document.getElementsByClassName("record-body");
    if (el && el[0]) {
      return el[0].addEventListener("scroll", onScroll);
    }

    return () => {
      const el = document.getElementsByClassName("record-body");
      if (el && el[0]) {
        return el[0].removeEventListener("scroll", onScroll);
      }
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {children}
      <div>
        <Affix
          style={{
            position: "absolute",
            top: 0,
            left: -258,
            width: 192,
          }}
          offsetTop={12}
          target={() => {
            const el = document.getElementsByClassName("record-body");
            if (el && el[0]) {
              return el[0] as HTMLDivElement;
            }
            return window;
          }}
        >
          <div>
            <Typography.Text strong>Sommaire</Typography.Text>
          </div>
          <div style={{ marginTop: 8 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {toc.map((t, i) => {
                return (
                  <div style={{ paddingTop: 8 }} key={t.key}>
                    <div>
                      <Typography.Text
                        ellipsis
                        strong
                        underline={t.key === selected}
                        onClick={() => scrollTo(t.key)}
                        style={{ cursor: "pointer" }}
                      >
                        {t.key === selected ? <u>{t.label}</u> : t.label}
                      </Typography.Text>
                    </div>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: 8 }}
                    >
                      {t.children?.map((a) => {
                        return (
                          <div key={a.key} onClick={() => scrollTo(a.key)}>
                            <Typography.Text
                              style={{ cursor: "pointer" }}
                              type="secondary"
                              ellipsis
                            >
                              {a.key === selected ? <u>{a.label}</u> : a.label}
                            </Typography.Text>
                          </div>
                        );
                      })}
                    </Space>
                  </div>
                );
              })}
            </Space>
          </div>
        </Affix>
      </div>
    </div>
  );
}
