import type { Query } from "@cubejs-client/core";
import {
  Checkbox,
  Col,
  ConfigProvider,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  theme,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { withRouter, type RouteComponentProps } from "react-router";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import MeasureSort from "../../../../../../../../components/measures/measure-sort/MeasureSort";
import { DatasetSelector } from "../../../../../../../../components/workbench/DatasetSelector";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IDataset } from "../../../../../../../../interfaces/sources";
import type {
  SchemaResult,
  Transformation,
} from "../../../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../../../services/BrizoService";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { generateUniqueId } from "../../../../../../../../utils/uniqueId";
import WithOrg, {
  getCurrentWarehouse,
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import TypeRenderer from "../../../../../../../spreadsheet/renderer/TypeRenderer";
import { getObjectColumns } from "../../../../../object/domain";
import type { AvailableProperty } from "../../../../../object/viewer/domain";
import { getAvailableDimensions } from "../../../../../object/viewer/domain";
import { substitutionColumnPrefix } from "../../../widgets/generated-text/domain";
import { FilterEditor } from "../../../widgets/related-lists/editor/FilterEditor";
import type { ITextDataSheet } from "../interfaces";

export interface ICreateEditDatasheetModalProps {
  initialValue: ITextDataSheet;
  onSave: (values: ITextDataSheet) => void;
  onCancel: () => void;
  open: boolean;
  datasets: IDataset[];
  object: IObject;
}

type Props = ICreateEditDatasheetModalProps &
  RouteComponentProps<{ warehouseSlug: string }> &
  InjectedOrgProps;

function CreateEditDatasheetModal(props: Props) {
  const {
    open,
    initialValue,
    onSave,
    onCancel,
    object,
    datasets,
    org,
    match: {
      params: { warehouseSlug },
    },
  } = props;
  const [form] = useForm<ITextDataSheet>();
  const [schema, setSchema] = React.useState<AsyncData<AvailableDimension[]>>({
    status: "initial",
  });
  const currentWarehouse = getCurrentWarehouse(org, warehouseSlug);

  const fetchSchemaFromObject = async (
    propertyId: string,
    selectAll?: boolean
  ): Promise<void> => {
    try {
      setSchema({ status: "loading" });
      const foreignKey = object.foreignKeys.find((fk) => fk.id === propertyId);
      if (!foreignKey) {
        throw new Error("Relationship not found");
      }
      const columns = getObjectColumns(foreignKey.object);
      const availableDimensions = getAvailableDimensions(columns, {
        type: "sortAndFilter",
      });
      if (selectAll) {
        form.setFieldValue(
          "select",
          availableDimensions.map((ad) => ad.key)
        );
      }
      setSchema({ status: "success", data: availableDimensions });
    } catch (err) {
      console.error(err);
      setSchema({ status: "error", error: err });
    }
  };

  const fetchSchemaFromModel = async (
    modelId: string,
    selectAll?: boolean
  ): Promise<void> => {
    try {
      setSchema({ status: "loading" });
      const dataset = datasets.find((d) => d.id === modelId);
      if (!dataset) {
        throw new Error("Dataset not found");
      }

      if (!currentWarehouse) {
        throw new Error("Warehouse not found");
      }
      const data = await computeTransformations(currentWarehouse.id, {
        schema: [
          {
            var: "1",
            operation: {
              type: "Table.FromWhalyDataset",
              args: {
                datasetId: modelId,
              },
            },
            domain: "dataset",
          },
          {
            var: "2",
            operation: {
              type: "Table.Schema",
              args: {
                table: "1",
              },
            },
            domain: "dataset",
          },
        ],
      });
      if (data.errors) {
        throw new Error("Error while fetching the schema");
      }
      const sch = data.data.schema as SchemaResult;
      const availableDimensions = Object.keys(sch).map<AvailableDimension>(
        (k) => {
          return {
            key: k,
            label: sch[k].label ? sch[k].label : k,
            type: "standard",
            domain: sch[k].domain,
          };
        }
      );

      if (selectAll) {
        form.setFieldValue(
          "select",
          availableDimensions.map((ad) => ad.key)
        );
      }

      setSchema({ status: "success", data: availableDimensions });
    } catch (err) {
      console.error(err);
      setSchema({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (initialValue.from && initialValue.type) {
      if (initialValue.type === "MODEL") {
        fetchSchemaFromModel(initialValue.from);
      } else {
        fetchSchemaFromObject(initialValue.from);
      }
    }
  }, [initialValue.from, initialValue.type]);

  React.useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    if (open) {
      form.setFieldsValue(initialValue);
    }
  }, [open]);

  const onFinish = async () => {
    try {
      const v = await form.validateFields();
      onSave({ ...v, id: initialValue.id });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = getObjectColumns(object);

  return (
    <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
      <Modal
        open={open}
        onCancel={onCancel}
        destroyOnClose
        maskClosable={false}
        className="markdown-modal"
        title={"Add data sheet"}
        width={"50%"}
        styles={{ body: { overflowY: "auto", overflowX: "hidden" } }}
        okText={"Save"}
        onOk={async () => {
          await onFinish();
        }}
      >
        <Form
          initialValues={initialValue}
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            required
            label="Reference name"
            name={["name"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            required
            label="Type"
            name={["type"]}
          >
            <Radio.Group
              onChange={(e) => {
                form.setFieldsValue({
                  from: "",
                  select: [],
                  additionalFilters: { operator: "and", filters: [] },
                  limit: {
                    type: "value",
                    value: "",
                  },
                });
                setSchema({ status: "initial" });
                return e;
              }}
            >
              <Radio value="MODEL">Model</Radio>
              <Radio value="OBJECT">Related Object</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const val = form.getFieldValue("type");
              const onChange = (e: any) => {
                setSchema({ status: "initial" });
                form.setFieldsValue({
                  select: [],
                  additionalFilters: { operator: "and", filters: [] },
                  limit: {
                    type: "value",
                    value: "10",
                  },
                });
                if (val === "MODEL") {
                  fetchSchemaFromModel(e, true);
                } else {
                  fetchSchemaFromObject(e, true);
                }
              };
              if (val === "MODEL") {
                return (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    required
                    label="From"
                    name={["from"]}
                  >
                    <DatasetSelector onChange={onChange} datasets={datasets} />
                  </Form.Item>
                );
              } else {
                return (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    required
                    label="From"
                    name={["from"]}
                  >
                    <Select onChange={onChange}>
                      {object.foreignKeys.map((fk) => {
                        return (
                          <Select.Option key={fk.id}>
                            {fk.object.name}{" "}
                            <Typography.Text type="secondary">
                              on {fk.label}
                            </Typography.Text>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const hasFrom = form.getFieldValue("from");
              const type = form.getFieldValue("type");
              const columnsWithId = getObjectColumns(object, true);
              if (hasFrom) {
                if (schema.status === "initial") {
                  return null;
                }
                if (schema.status === "loading") {
                  return <Loading />;
                }
                if (schema.status === "error") {
                  return (
                    <Typography.Text type="danger">
                      There was an error fetching schema
                    </Typography.Text>
                  );
                }
                return (
                  <>
                    <Form.Item label="Select Column" name={["select"]}>
                      <Checkbox.Group style={{ width: "100%" }}>
                        <Row>
                          {schema.data.map((ck, i) => {
                            return (
                              <Col key={ck.key} span={24}>
                                <Checkbox value={ck.key}>
                                  <TypeRenderer domain={ck.domain} /> {ck.label}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    <Form.Item label="Filters" name={["additionalFilters"]}>
                      <FilterEditor
                        availableDimensions={schema.data}
                        valueSubstitutionColumns={columnsWithId.map((c) => ({
                          key: `${substitutionColumnPrefix}${c.data.key}`,
                          domain:
                            c.type === "property" ? c.data.domain : "NUMERIC",
                          label: c.data.label,
                        }))}
                        autocomplete={async (dimension, operator, value) => {
                          if (type === "MODEL") {
                            const groupId = generateUniqueId();
                            const sortId = generateUniqueId();
                            const limitId = generateUniqueId();

                            const model = datasets.find(
                              (d) => hasFrom === d.id
                            );
                            if (!model) {
                              throw new Error("No model found");
                            }

                            if (!currentWarehouse) {
                              throw new Error("No warehouse found");
                            }

                            const newTransformations: Transformation[] = [
                              {
                                var: "current",
                                operation: {
                                  type: "Table.FromWhalyDataset",
                                  args: {
                                    datasetId: model.id,
                                  },
                                },
                                domain: "dataset",
                              },
                              {
                                var: groupId,
                                operation: {
                                  type: "Table.Group",
                                  args: {
                                    // we take the transformation before the select rows otherwise we don't see all the values
                                    table: "current",
                                    keys: [dimension],
                                    aggregatedColumns: [
                                      {
                                        aggregatedColumn: dimension,
                                        aggregationType: "COUNT",
                                        destinationColumnName: "counter",
                                      },
                                    ],
                                  },
                                },
                                domain: "datasetResolver",
                              },
                              {
                                var: sortId,
                                operation: {
                                  type: "Table.Sort",
                                  args: {
                                    table: groupId,
                                    condition: [
                                      {
                                        column: "counter",
                                        sort: "DESC",
                                      },
                                    ],
                                  },
                                },
                                domain: "datasetResolver",
                              },
                              {
                                var: limitId,
                                operation: {
                                  type: "Table.FirstN",
                                  args: {
                                    table: sortId,
                                    countOrCondition: 200,
                                  },
                                },
                                domain: "datasetResolver",
                              },
                            ];

                            return computeTransformations(currentWarehouse.id, {
                              recommendation: newTransformations,
                            }).then((r) => {
                              return (r.data.recommendation as Array<any>).map(
                                (r) => {
                                  return r[dimension] as string;
                                }
                              );
                            });
                          } else {
                            const foreignKey = object.foreignKeys.find(
                              (fk) => fk.id === hasFrom
                            );
                            if (!foreignKey) {
                              throw new Error("no foreign key found");
                            }
                            let query: Query = {
                              dimensions: [dimension],
                              limit: 50,
                              filters: [
                                {
                                  member: dimension,
                                  operator: "set",
                                },
                              ],
                            };
                            if (
                              typeof value === "string" &&
                              value !== "" &&
                              operator
                            ) {
                              query = {
                                dimensions: [dimension],
                                limit: 50,
                                filters: [
                                  {
                                    member: dimension,
                                    operator: operator,
                                    values: [value],
                                  },
                                ],
                              };
                            }
                            return lagoonServiceLoad(
                              org.id,
                              query,
                              "OBJECT",
                              foreignKey.object.id,
                              undefined,
                              LagoonCallOrigin.WHALY_APP,
                              undefined,
                              undefined,
                              false
                            )
                              .then((r) => {
                                return r.tablePivot();
                              })
                              .then((r) => {
                                return (r || []).map(
                                  (d) => d[dimension] as string
                                );
                              });
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        const type = form.getFieldValue("type");
                        if (type !== "OBJECT") {
                          return null;
                        }

                        return (
                          <div style={{ maxWidth: "100%" }}>
                            <Form.Item name={["sortBy"]} label="Sort by">
                              <MeasureSort measures={schema.data} />
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.Item>
                    <Form.Item label="Limit">
                      <Flex gap={8}>
                        <div style={{ flex: `0 100px` }}>
                          <Form.Item
                            style={{ width: "100%" }}
                            name={["limit", "type"]}
                            noStyle
                          >
                            <Select
                              onChange={(e) => {
                                if (e === "value") {
                                  form.setFieldValue(["limit", "value"], "10");
                                } else {
                                  form.setFieldValue(
                                    ["limit", "value"],
                                    undefined
                                  );
                                }

                                return e;
                              }}
                            >
                              <Select.Option value="value">Value</Select.Option>
                              <Select.Option value="column">
                                Column
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Form.Item
                            style={{ width: "100%" }}
                            noStyle
                            shouldUpdate
                          >
                            {() => {
                              const v = form.getFieldValue(["limit", "type"]);
                              if (v === "value") {
                                return (
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                    required
                                    name={["limit", "value"]}
                                  >
                                    <InputNumber style={{ width: "100%" }} />
                                  </Form.Item>
                                );
                              }
                              return (
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                  required
                                  name={["limit", "value"]}
                                >
                                  <Select>
                                    {columns
                                      .filter(
                                        (c) =>
                                          c.type === "metric" ||
                                          (c.type === "property" &&
                                            c.data.domain === "NUMERIC")
                                      )
                                      .map((c) => {
                                        return (
                                          <Select.Option
                                            key={c.data.key}
                                            value={c.data.key}
                                          >
                                            {c.data.label}
                                          </Select.Option>
                                        );
                                      })}
                                  </Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </div>
                      </Flex>
                    </Form.Item>
                  </>
                );
              }
              return null;
            }}
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
}

export default compose<Props, ICreateEditDatasheetModalProps>(
  withRouter,
  WithOrg
)(CreateEditDatasheetModal);
