export const collapse = {
  render: "Collapse",
  children: ["paragraph", "tag", "list"],
  attributes: {
    title: {
      type: String,
      default: "Details",
    },
    logo: {
      type: String,
    },
    level: {
      type: Number,
      default: 5,
    },
    defaultState: {
      type: String,
      default: "closed",
    },
    id: {
      type: String,
    },
  },
};
