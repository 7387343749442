import { Button, Space } from "antd";
import React from "react";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetTabConfig } from "../domain";

interface ITabsWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetTabConfig;
  state: string | undefined;
  setState: (s: string) => void;
  edit?: boolean;
}

type Props = ITabsWidgetProps;

export function TabsWidget(props: Props) {
  const { record, conf, object, state, setState, edit } = props;

  const getDefaultKey = (): string | undefined => {
    const defaultSelected = conf?.keys?.find((k) => k.defaultSelected);
    const defaultSelectedIndex = conf?.keys?.findIndex(
      (k) => k.defaultSelected
    );
    if (defaultSelected) {
      if (defaultSelected.apiName) {
        return defaultSelected.apiName;
      } else {
        return defaultSelectedIndex?.toString();
      }
    }
  };

  const defautlSelectedKey = getDefaultKey();
  const keys = (conf?.keys || []).map<{ label: string; key: string }>(
    (k, i) => {
      return {
        key: k.apiName ? k.apiName : i.toString(),
        label: k.label ? k.label : "No name",
      };
    }
  );

  React.useEffect(() => {
    if (!edit && !state && defautlSelectedKey) {
      setState(defautlSelectedKey);
    }

    if (edit && defautlSelectedKey && state !== defautlSelectedKey) {
      setState(defautlSelectedKey);
    }
  }, [state, defautlSelectedKey, edit]);

  return (
    <Space wrap>
      {keys.map((k, i) => {
        return (
          <Button
            key={k.key}
            type={state === k.key ? "primary" : undefined}
            shape="round"
            onClick={() => !edit && setState(k.key)}
          >
            {k.label}
          </Button>
        );
      })}
    </Space>
  );
}
