import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import type { IWidgetInsightConfig } from "./domain";
import { InsightWidgetEditor } from "./editor/InsightWidgetEditor";
import { InsightWidget } from "./widget/InsightWidgetRenderer";

export const widgetInsightDefinition: IRendererConfig<IWidgetInsightConfig> = {
  removeCard: true,
  parseConfig: (widget) => {
    const defaultValue: IWidgetInsightConfig = {
      insights: [],
    };

    if (!widget.config) {
      return defaultValue;
    }

    try {
      const config: IWidgetInsightConfig = JSON.parse(widget.config);
      return config;
    } catch (err) {
      console.error(err);
      return defaultValue;
    }
  },
  renderEditor: (widget, object, record, conf, form, org) => {
    return (
      <InsightWidgetEditor
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        form={form}
      />
    );
  },
  renderComponent: (widget, object, record, conf, edit) => {
    return (
      <InsightWidget
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        edit={edit}
      />
    );
  },
  renderLoadingComponent: (widget, conf) => (
    <Skeleton.Button
      active
      block
      size="small"
      shape="default"
      style={{ height: 75 }}
    />
  ),
};
