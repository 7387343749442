import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import {
  Collapse as AntCollapse,
  Button,
  ConfigProvider,
  Divider,
  Typography,
} from "antd";
import * as React from "react";

export interface ICollapseProps {
  title: string;
  logo?: string;
  level: 1 | 2 | 3 | 4 | 5;
  defaultState: "open" | "closed";
  id?: string;
}

export function Collapse(props: React.PropsWithChildren<ICollapseProps>) {
  const { children, defaultState, level, title, id, logo } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(
    defaultState === "open" ? true : false
  );
  return (
    <Typography.Paragraph>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              headerPadding: 0,
              contentPadding: `16px 0`,
            },
          },
        }}
      >
        <AntCollapse
          ghost
          className="wly_toggle"
          defaultActiveKey={defaultState === "open" ? "1" : undefined}
          onChange={() => {
            setIsOpen((r) => !r);
          }}
          items={[
            {
              key: "1",
              label: (
                <div
                  id={id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 24,
                  }}
                >
                  <div style={{ flex: `0 64px` }}>
                    <img
                      style={{ width: 64, height: 64, borderRadius: 16 }}
                      src={logo}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography.Title
                      style={{ flex: `0 auto`, marginBottom: 0 }}
                      level={level}
                    >
                      {title}
                    </Typography.Title>
                  </div>
                  <div style={{ flex: `0 auto` }}>
                    <Button
                      shape="circle"
                      icon={
                        isOpen ? <CaretDownOutlined /> : <CaretRightOutlined />
                      }
                      type="text"
                    />
                  </div>
                </div>
              ),
              showArrow: false,
              children: (
                <>
                  <div
                    style={{
                      marginBottom: "0.5em",
                      width: "100%",
                      height: 1,
                      marginTop: `1em`,
                    }}
                  />
                  {children}
                </>
              ),
            },
          ]}
        />
        <Divider style={{ margin: `1em 0` }} />
      </ConfigProvider>
    </Typography.Paragraph>
  );
}
