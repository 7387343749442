import { Typography } from "antd";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetTitleConfig } from "../domain";

interface ISeparatorWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetTitleConfig;
  edit?: boolean;
}

type Props = ISeparatorWidgetProps;

export function TitleWidget(props: Props) {
  const { record, conf, object } = props;

  const level = conf.level ? conf.level : 1;
  const text = conf.text;

  return (
    <Typography.Title style={{ marginBottom: 0 }} level={level}>
      {text ? text : "Title"}
    </Typography.Title>
  );
}
