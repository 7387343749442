import { useCallback, useContext, useEffect, useState } from "react";
import { AntUtilsContext } from "../../../../components/ant-utils/antUtilsContextProvider";
import type { ISource } from "../../../../interfaces/sources";
import type { ISourceState } from "../../../../interfaces/sourcesstate";
import { getJSONFile } from "../../../../services/FileService";

export const useGetSourceState = (source: ISource) => {
  const antUtils = useContext(AntUtilsContext);

  const [sourceState, setSourceState] = useState<ISourceState | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchSourceState = useCallback(async () => {
    try {
      if (!source.stateFileURI) throw new Error("stateFileURI is missing");
      setLoading(true);
      const resp = await getJSONFile<ISourceState>(source.stateFileURI);
      setSourceState(resp);
    } catch (error) {
      setError("Error while loading the file, please try again");
      setSourceState(undefined);
      antUtils.message.error("Unexpected error while loading the file");
      console.error(error);
    } finally {
      setLoading(false);
      setPending(false);
    }
  }, [source, antUtils]);

  useEffect(() => {
    fetchSourceState();
  }, [fetchSourceState]);

  return { sourceState, loading, pending, error, refetch: fetchSourceState };
};
