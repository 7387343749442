import type { Filter } from "@cubejs-client/core";
import { Button, Dropdown } from "antd";
import type {
  AvailableDimension,
  IAvailableDimensionGroup,
} from "./FilterItem";
import { hasFilterGroup } from "./domain";

interface IAddFilterButtonProps {
  availableDimensions: Array<AvailableDimension | IAvailableDimensionGroup>;
  filters: Filter[];
  newFilterOperator?: "set" | "equals";
  setFilters: (filters: Filter[]) => void;
}

export function AddFilterButton(props: IAddFilterButtonProps) {
  const { filters, availableDimensions, newFilterOperator, setFilters } = props;

  const hasGroup = hasFilterGroup(filters);

  if (hasGroup) {
    return (
      <Button
        disabled={availableDimensions.length === 0}
        size="small"
        style={{ width: "100%" }}
        onClick={() => {
          setFilters([
            ...filters,
            {
              and: [],
            },
          ] as any);
        }}
      >
        Add a Group
      </Button>
    );
  }
  return (
    <Dropdown.Button
      disabled={availableDimensions.length === 0}
      trigger={["click"]}
      menu={{
        items: [
          {
            key: "group",
            label: "Add a group",
            onClick: () => {
              setFilters([
                {
                  and: filters,
                },
                {
                  and: [],
                },
              ] as any);
            },
          },
        ],
      }}
      onClick={() =>
        setFilters([
          ...filters,
          newFilterOperator === "equals"
            ? {
                member: availableDimensions[0].key,
                operator: "equals",
                values: [],
              }
            : {
                member: availableDimensions[0].key,
                operator: "set",
              },
        ])
      }
      size="small"
      className="query-builder-filter-group-add"
    >
      <span style={{ width: "100%" }}>Add a filter</span>
    </Dropdown.Button>
  );
}
