import { Collapse, Tabs, Typography } from "antd";
import React from "react";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { RepeatableType } from "../domain";

interface IRepeatableWidgetRendererProps {
  object: IObject;
  type: RepeatableType;
  titleSize: 1 | 2 | 3 | 4 | 5;
  items: Array<{
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
  }>;
}

export function RepeatableWidgetRenderer(
  props: IRepeatableWidgetRendererProps
) {
  const { object, type, items, titleSize } = props;

  if (type === "TAB") {
    return (
      <Tabs tabBarStyle={{ marginBottom: 0 }} size="small" items={items} />
    );
  } else if (type === "SECTION") {
    return (
      <div>
        {items.map((i) => {
          return (
            <div key={i.key}>
              <div>
                <Typography.Title level={titleSize} style={{ marginBottom: 0 }}>
                  {i.label}
                </Typography.Title>
              </div>
              <div>{i.children}</div>
            </div>
          );
        })}
      </div>
    );
  } else if (type === "COLLAPSE") {
    return <Collapse ghost items={items} />;
  }

  return <div>Not implemented</div>;
}
