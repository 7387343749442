import { Tag as AntdTag } from "antd";
import * as React from "react";

export interface ITagProps {
  color?: string;
  id: string;
}

export function Tag(props: React.PropsWithChildren<ITagProps>) {
  const { children, id } = props;

  return <AntdTag>{children}</AntdTag>;
}
