import type { IRecord } from "../../../domain";
import type { ITextDataSheet } from "../../common/markdoc/interfaces";

export interface IEmailWidgetTextConfig {
  dataSheets: ITextDataSheet[];
  content?: string;
  align?: "left" | "right" | "center";
  spacing?: "normal" | "compact";
}

export const getDefaultConfig = (
  conf: IEmailWidgetTextConfig,
  record: IRecord
): IEmailWidgetTextConfig => {
  return {
    dataSheets: conf.dataSheets ? conf.dataSheets : [],
    content: conf.content ? conf.content : "",
    align: conf.align ? conf.align : "left",
    spacing: conf.spacing ? conf.spacing : "normal",
  };
};
