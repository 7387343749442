// @ts-nocheck
import { Ast, Tag } from "@markdoc/markdoc";

export const group = {
  render: "Group",
  attributes: {
    dataSheet: { type: Array },
    node: { type: String },
    type: {
      type: String,
      matches: ["paragraph", "list", "comma-separated"],
      default: "list",
    },
  },
  children: ["paragraph", "tag", "list"],
  transform(node, config) {
    const attributes = node.transformAttributes(config);

    const children: Array<any> = [];

    for (const item of attributes?.dataSheet || []) {
      const dataSheetName = Object.keys(item)?.[0]?.split("_")?.[0];
      const prevVariables = config.variables;
      const variables = Object.keys(prevVariables)
        .filter((k) => k !== dataSheetName)
        .reduce((acc, k) => {
          return {
            ...acc,
            [k]: prevVariables[k],
          };
        }, item);
      const newConfig = { ...config, variables };
      // we hydrate nodes for each instance
      const nodeFromAst = Ast.fromJSON(attributes.node);
      const resolvedNode = (nodeFromAst as any).resolve(newConfig);

      children.push(resolvedNode.transformChildren(newConfig));
    }

    const props = {
      type: attributes.type,
    };

    return new Tag("Group", props, children);
  },
};
