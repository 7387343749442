import { CloseOutlined } from "@ant-design/icons";
import type { BinaryOperator, Query } from "@cubejs-client/core";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  type FormInstance,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import cuid from "cuid";
import Feednack from "../../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../../components/layout/feedback/loading";
import type {
  AvailableDimension,
  IAvailableDimensionGroup,
} from "../../../../../../../../../components/measures/filter-item/FilterItem";
import type {
  IObject,
  IObjectQueryBuilderSectionItemConfig_Parsed,
} from "../../../../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../../../../interfaces/org";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../../services/LagoonService";
import { getObjectColumns } from "../../../../../../../../v2-demo/container/object/domain";
import { getAvailableDimensions } from "../../../../../../../../v2-demo/container/object/viewer/domain";
import { FilterEditor } from "../../../../../../../../v2-demo/container/record/component/widgets/related-lists/editor/FilterEditor";
import { useGetObjects } from "../../api";
import { findRelatedObjects } from "../domain";

export interface IForeignPropertySectionItemFormProps {
  org: IOrg;
  form: FormInstance;
  object: IObject;
}

export function ForeignPropertySectionItemForm(
  props: IForeignPropertySectionItemFormProps
) {
  const { org, object, form } = props;

  const { refetch, data, loading, error, pending } = useGetObjects({
    variables: { orgId: org.id },
  });

  const autocomplete = async (
    dimension: string,
    operator?: BinaryOperator,
    value?: string
  ): Promise<string[]> => {
    let query: Query = {
      dimensions: [dimension],
      limit: 50,
      filters: [
        {
          member: dimension,
          operator: "set",
        },
      ],
    };
    if (typeof value === "string" && value !== "" && operator) {
      query = {
        dimensions: [dimension],
        limit: 50,
        filters: [
          {
            member: dimension,
            operator: operator,
            values: [value],
          },
        ],
      };
    }
    return lagoonServiceLoad(
      org.id,
      query,
      "OBJECT",
      object.id,
      object.id,
      LagoonCallOrigin.WHALY_APP,
      undefined,
      undefined,
      false
    )
      .then((r) => {
        return r.tablePivot();
      })
      .then((r) => {
        return (r || []).map((d) => d[dimension] as string);
      });
  };

  if (loading || pending) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) {
    return (
      <Feednack>
        <div>Error while fetching objects</div>
      </Feednack>
    );
  }

  const allObjects = data ? data.allObjects : [];
  let linkedIds = [];
  findRelatedObjects(object, allObjects, linkedIds);
  const relatedObjects = linkedIds
    .filter((lid) => lid !== object.id)
    .map((lid) => allObjects.find((ao) => ao.id === lid))
    .filter((ao) => !!ao);

  const availableGroups = relatedObjects.map<IAvailableDimensionGroup>((ao) => {
    const availableColumns = getObjectColumns(ao);

    const availableDimensions = getAvailableDimensions(availableColumns, {
      type: "sortAndFilter",
    });
    return {
      type: "group",
      key: ao.id,
      label: ao.name,
      availableDimensions: availableDimensions,
    };
  });

  const availableColumns = getObjectColumns(object);

  const availableDimensions = getAvailableDimensions(availableColumns, {
    type: "sortAndFilter",
  });

  return (
    <>
      <Form.Item rules={[{ required: true }]} label="Label" name="label">
        <Input placeholder="What users will see" />
      </Form.Item>
      <Form.List name={["property"]}>
        {(f, s) => {
          return (
            <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
              <Col span={24}>
                <div>
                  <Typography.Text>Controls</Typography.Text>
                </div>
                <div>
                  <Typography.Text type="secondary">
                    Controls allow your users to fill some filter value using at
                    query time.
                  </Typography.Text>
                </div>
              </Col>
              <Col span={24}>
                <Space size={16} direction="vertical" style={{ width: "100%" }}>
                  {f.map((a, i) => {
                    return (
                      <Card key={a.key} size="small">
                        <Flex gap={8}>
                          <div style={{ flex: 1 }}>
                            <Form.Item
                              required
                              rules={[{ required: true }]}
                              style={{ marginBottom: 8 }}
                              label={"Property"}
                              name={[a.name, "key"]}
                            >
                              <Select size="small" style={{ width: "100%" }}>
                                {[
                                  ...availableDimensions,
                                  ...availableGroups,
                                ].map((af) => {
                                  if (af.type === "group") {
                                    return (
                                      <Select.OptGroup
                                        label={af.label}
                                        key={af.label}
                                      >
                                        {af.availableDimensions.map((ad) => {
                                          return (
                                            <Select.Option key={ad.key}>
                                              {ad.label}
                                            </Select.Option>
                                          );
                                        })}
                                      </Select.OptGroup>
                                    );
                                  }
                                  return (
                                    <Select.Option key={af.key}>
                                      {af.label}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[a.name, "config", "singleValueOnly"]}
                              valuePropName="checked"
                              noStyle
                            >
                              <Checkbox>Allow only single value</Checkbox>
                            </Form.Item>
                          </div>
                          <div>
                            <Button
                              size="small"
                              type="text"
                              icon={<CloseOutlined />}
                              onClick={() => s.remove(i)}
                            />
                          </div>
                        </Flex>
                      </Card>
                    );
                  })}
                  <Button
                    onClick={() => s.add({ controlApiName: cuid() })}
                    block
                    size="small"
                  >
                    Add new control
                  </Button>
                </Space>
              </Col>
            </Row>
          );
        }}
      </Form.List>
      <Form.Item noStyle shouldUpdate>
        {() => {
          const allDimensions = [
            ...availableDimensions,
            ...availableGroups.flatMap((ag) => ag.availableDimensions),
          ];

          const allControlsGroup: IAvailableDimensionGroup = {
            type: "group",
            key: "controls",
            label: "Controls",
            availableDimensions: (
              (form.getFieldValue("property") ||
                []) as IObjectQueryBuilderSectionItemConfig_Parsed
            )
              .filter((p) => {
                if (!p) {
                  return false;
                }
                if (!p.key) {
                  return false;
                }
                return true;
              })
              .flatMap<AvailableDimension>((p) => {
                const foundProperty = allDimensions.find(
                  (d) => d.key === p.key
                );
                if (!foundProperty) {
                  return [];
                }
                return {
                  ...foundProperty,
                  key: `controlled.${p.key!}`,
                  isControl: true,
                };
              }),
          };
          return (
            <Form.Item
              rules={[{ required: true }]}
              label="Rules"
              name="additionalFilters"
            >
              <FilterEditor
                autocomplete={autocomplete}
                availableDimensions={[
                  ...availableDimensions,
                  ...availableGroups,
                  allControlsGroup,
                ]}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
  );
}
