import type {
  BinaryFilter,
  Filter,
  LogicalAndFilter,
  LogicalOrFilter,
  Query,
  TableColumn,
  UnaryFilter,
} from "@cubejs-client/core";
import { Promise as BPromise } from "bluebird";
import _ from "lodash";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../../../interfaces/org";
import type {
  AndFilterItem,
  BinaryFilterItem as BinaryFilterItemMashup,
  FilterItem as FilterItemMashup,
  Filter as FilterMashup,
  OrFilterItem,
  SchemaResult,
  TableResult,
  Transformation,
  UnaryFilterItem as UnaryFilterItemMashup,
} from "../../../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../../../services/BrizoService";
import GraphQLService from "../../../../../../../../services/graphql/GraphQLService";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import { substitutionColumnPrefix } from "../../../widgets/generated-text/domain";
import type { ITextDataSheet } from "../interfaces";

const formatTransformationResultIntoString = (
  schema: SchemaResult,
  data: TableResult,
  select: string[]
) => {
  const schemaKeys = Object.keys(schema).filter((sk) => select.includes(sk));
  const fistLine = schemaKeys.reduce((acc, v) => {
    return `${acc}${v}\t`;
  }, "");

  const dataLines = data
    .map((d) => {
      return schemaKeys.reduce((acc, v) => {
        return `${acc}${d[v]}\t`;
      }, "");
    })
    .join("\n");

  return `\n${fistLine}\n${dataLines}\n`;
};

const formatRunResultsIntoString = (
  schema: TableColumn[],
  data: Array<{ [key: string]: boolean | number | string | null }>
) => {
  const schemaKeys = schema.map((s) => s.key);
  const fistLine = schema.reduce((acc, v) => {
    return `${acc}${v.shortTitle}\t`;
  }, "");

  const dataLines = data
    .map((d) => {
      return schemaKeys.reduce((acc, v) => {
        return `${acc}${d[v]}\t`;
      }, "");
    })
    .join("\n");

  return `\n${fistLine}\n${dataLines}`;
};

const substituteValuesInFilters = (record: IRecord, values?: string[]) => {
  if (!values) return;
  return values.flatMap((v) => {
    if (v.startsWith(substitutionColumnPrefix)) {
      const columnName = v.replaceAll(substitutionColumnPrefix, "");
      const newValue = record[columnName];
      if (!newValue) {
        return [];
      }
      return [_.toString(newValue)];
    } else {
      return [v];
    }
  });
};

export const fetchDatasheet = async (
  data: ITextDataSheet,
  org: IOrg,
  record: IRecord,
  object: IObject,
  ret: "STRING" | "JSON"
): Promise<{ [key: string]: any }> => {
  const renderLimit = (): number => {
    if (data.limit.type === "value") {
      const limit = parseFloat(data.limit.value);
      // we check that limit is a positive integer
      if (isNaN(limit)) return 0;
      if (limit < 0) return 0;
      return Math.ceil(limit);
    } else if (data.limit.type === "column") {
      const limit = parseFloat(record[data.limit.value] as any);
      if (limit) {
        if (isNaN(limit)) return 0;
        if (limit < 0) return 0;
        return Math.ceil(limit);
      }
    }
    return 10;
  };

  const filter: Filter = {
    [data.additionalFilters?.operator
      ? (data.additionalFilters?.operator as "and")
      : "and"]: data.additionalFilters.filters
      ? data.additionalFilters.filters
      : [],
  };

  const select = data.select || [];

  if (data.type === "OBJECT") {
    const foreignNakedProperty = object.foreignKeys.find(
      (fk) => fk.id === data.from
    );
    if (!foreignNakedProperty || !foreignNakedProperty.object) {
      throw new Error("Relationship not found");
    }
    const foreignObject = foreignNakedProperty.object;
    const foreignProperty = foreignObject.properties.find(
      (p) => p.id === foreignNakedProperty.id
    );
    if (!foreignProperty) {
      throw new Error("foreignProperty not found");
    }
    const columns = getObjectColumns(foreignObject);
    const selectedMetrics = columns
      .filter((c) => c.type === "metric" && select.includes(c.data.key))
      .map((c) => c.data.key);

    const selectedDimensions = columns
      .filter((c) => c.type === "property" && select.includes(c.data.key))
      .map((c) => c.data.key);

    const foreignAvailable = convertPropertyToAvailableProperties(
      foreignObject.table.cubeName,
      foreignObject,
      foreignProperty
    );
    const cubeName = object.table.cubeName;
    const recordFilters = [
      {
        member: `${foreignAvailable.key}_raw`,
        operator: "equals",
        values: [record[`${cubeName}.id`] as string],
      },
    ];

    const sortBy = (data.sortBy || []).filter((s) =>
      columns.map((c) => c.data.key).includes(s[0])
    );

    const computeSubstitutions = (fil: Filter[]): Filter[] => {
      return fil.map((f) => {
        if ((f as LogicalAndFilter).and) {
          return {
            and: computeSubstitutions((f as LogicalAndFilter).and),
          } as LogicalAndFilter;
        } else if ((f as LogicalOrFilter).or) {
          return {
            or: computeSubstitutions((f as LogicalOrFilter).or),
          } as LogicalOrFilter;
        } else {
          const substitute = substituteValuesInFilters(
            record,
            (f as BinaryFilter).values
          );
          return {
            ...f,
            values: substitute,
          } as UnaryFilter | BinaryFilter;
        }
      });
    };

    const filters = [
      {
        and: [...recordFilters, ...computeSubstitutions([filter])],
      },
    ] as Filter[];

    const query: Query = {
      measures: selectedMetrics,
      dimensions: selectedDimensions,
      filters: filters,
      order: sortBy,
      limit: renderLimit(),
    };
    const resultSet = await lagoonServiceLoad(
      org.id,
      query,
      "OBJECT",
      foreignObject.id,
      undefined,
      LagoonCallOrigin.WHALY_APP,
      undefined
    );
    const schema = resultSet.tableColumns();
    const dataPivot = resultSet.tablePivot();

    if (ret === "STRING") {
      return { [data.id]: formatRunResultsIntoString(schema, dataPivot) };
    } else {
      return { [data.id]: dataPivot };
    }
  } else if (data.type === "MODEL") {
    const convertFilterToMashupFilter = (f: Filter[]): FilterMashup => {
      return f.map<FilterItemMashup>((a) => {
        if ((a as LogicalAndFilter).and) {
          return {
            and: convertFilterToMashupFilter((a as LogicalAndFilter).and),
          } as AndFilterItem;
        } else if ((a as LogicalOrFilter).or) {
          return {
            or: convertFilterToMashupFilter((a as LogicalOrFilter).or),
          } as OrFilterItem;
        } else {
          const v = a as BinaryFilter | UnaryFilter;
          if (v.values) {
            return {
              column: v.member!,
              operator: v.operator,
              values: substituteValuesInFilters(record, v.values),
            } as BinaryFilterItemMashup;
          }
          return {
            column: v.member!,
            operator: v.operator,
          } as UnaryFilterItemMashup;
        }
      });
    };

    const parsedFilters = convertFilterToMashupFilter([filter]);

    const model = await GraphQLService<{
      Dataset: {
        id: string;
        warehouse: {
          id: string;
        };
      };
    }>(
      `query getModel($modelId: ID!) {
  Dataset(where: { id: $modelId }) {
    id
    warehouse {
    	id
  	}
  }
}`,
      {
        modelId: data.from,
      }
    );

    if (!model.Dataset) {
      throw new Error("Dataset not found");
    }
    const finalId = "final-table";
    const schemaTransformation: Transformation = {
      var: "schema",
      operation: {
        type: "Table.Schema",
        args: {
          table: finalId,
        },
      },
      domain: "dataset",
    };
    const baseTransformation: Transformation[] = [
      {
        var: "1",
        operation: {
          type: "Table.FromWhalyDataset",
          args: {
            datasetId: data.from,
          },
        },
        domain: "dataset",
      },
      {
        var: "2",
        operation: {
          type: "Table.SelectRows",
          args: {
            condition: parsedFilters,
            table: "1",
          },
        },
        domain: "dataset",
      },
      {
        var: finalId,
        operation: {
          type: "Table.FirstN",
          args: {
            countOrCondition: renderLimit(),
            table: "2",
          },
        },
        domain: "dataset",
      },
    ];
    const value = await computeTransformations(model.Dataset.warehouse.id, {
      values: baseTransformation,
      schema: [...baseTransformation, schemaTransformation],
    });

    if (ret === "STRING") {
      return {
        [data.id]: formatTransformationResultIntoString(
          value.data.schema as SchemaResult,
          value.data.values as TableResult,
          select
        ),
      };
    } else {
      return {
        [data.id]: value.data.values,
      };
    }
  } else {
    throw new Error("Not implemented");
  }
};

export const fetchDatasheetConcurrently = async (
  dataSheets: ITextDataSheet[],
  org: IOrg,
  record: IRecord,
  object: IObject
): Promise<{ [key: string]: string }> => {
  const d = await BPromise.map<ITextDataSheet, { [key: string]: string }>(
    dataSheets,
    (dataSheet) => fetchDatasheet(dataSheet, org, record, object, "STRING"),
    {
      concurrency: 4,
    }
  );
  return d.reduce<{ [key: string]: string }>((acc, v) => {
    return {
      ...acc,
      ...v,
    };
  }, {});
};

export const fetchDatasheetConcurrentlyReturnJSON = async (
  dataSheets: ITextDataSheet[],
  org: IOrg,
  record: IRecord,
  object: IObject
): Promise<{ [key: string]: Array<IRecord> }> => {
  const d = await BPromise.map<ITextDataSheet, { [key: string]: string }>(
    dataSheets,
    (dataSheet) => fetchDatasheet(dataSheet, org, record, object, "JSON"),
    {
      concurrency: 4,
    }
  );
  return d.reduce<{ [key: string]: Array<IRecord> }>((acc, v) => {
    return {
      ...acc,
      ...(v as any),
    };
  }, {});
};
