import type { Config, Node, RenderableTreeNode, Tag } from "@markdoc/markdoc";
import Markdoc from "@markdoc/markdoc";
import _ from "lodash";
import moment from "moment";
import type { IUser } from "../../../../../../../interfaces/user";
import { LocaleService } from "../../../../../../../services/localeService";
import { type AvailableColumn } from "../../../../object/domain";
import { parseLabel, type IRecord } from "../../../domain";

const parseNumber = (n: string) => {
  try {
    return parseFloat(n);
  } catch (err) {
    return n;
  }
};

const length = {
  transform(parameters) {
    const dataSheet = parameters[0];

    if (Array.isArray(dataSheet)) {
      return dataSheet.length;
    }

    return 0;
  },
};

const fromNow = (user: IUser) => ({
  transform(parameters) {
    const value = parameters[0];
    const type = parameters[1];

    const parseType = () => {
      if (
        type === "days" ||
        type === "weeks" ||
        type === "months" ||
        type === "year"
      ) {
        return type;
      }
      return "days";
    };

    try {
      const now = moment();
      const d = moment(value);

      return now.diff(d, parseType());
    } catch (err) {
      return value;
    }
  },
});

const renderLabel = {
  transform(parameters) {
    const label = parameters[0];
    const extract = parameters[1];

    if (label && typeof label === "string") {
      const { id, name, image } = parseLabel(label);
      if (extract === "id") {
        return id;
      }
      if (extract === "name") {
        return name;
      }
      if (extract === "image") {
        return image;
      }
      return name;
    }
    return label;
  },
};

const lte = {
  transform(parameters) {
    const number1 = parseNumber(parameters[0]);
    const number2 = parseNumber(parameters[1]);

    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 <= number2;
  },
};

const gte = {
  transform(parameters) {
    const number1 = parseNumber(parameters[0]);
    const number2 = parseNumber(parameters[1]);

    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 >= number2;
  },
};

const gt = {
  transform(parameters) {
    const number1 = parseNumber(parameters[0]);
    const number2 = parseNumber(parameters[1]);
    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 > number2;
  },
};

const lt = {
  transform(parameters) {
    const number1 = parseNumber(parameters[0]);
    const number2 = parseNumber(parameters[1]);

    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 < number2;
  },
};

const format = (user: IUser) => ({
  transform(parameters) {
    const value = parameters[0];
    const formatter = parameters[1];
    try {
      const v = parseFloat(value);
      if (isNaN(v)) {
        return value;
      }
      const localeService = new LocaleService(user.locale);
      const numeral = localeService.getNumberDefaultFormatting();
      if (formatter === "variant") {
        return numeral(v).format("+ 0,0[.]0%");
      }
      if (formatter === "percent") {
        return numeral(v).format("0,0[.]0%");
      }
      if (!!formatter) {
        return numeral(v).format(formatter);
      }
      // Default
      return numeral(v).format("0,0[.]0(0)");
    } catch (err) {
      return value;
    }
  },
});

const timeFormat = (user: IUser) => ({
  transform(parameters) {
    const value = parameters[0];
    const formatter = parameters[1];
    try {
      const d = moment(value);
      return d.format(formatter);
    } catch (err) {
      return value;
    }
  },
});

export const generateConfig = (
  user: IUser,
  availableColumns: AvailableColumn[]
): Config => ({
  functions: {
    lte,
    gte,
    lt,
    gt,
    length,
    renderLabel,
    format: format(user),
    fromNow: fromNow(user),
    timeFormat: timeFormat(user),
  },
});

export const convertKeyToMarkdocVariable = (key: string) => {
  return key.replaceAll(".", "_");
};

export const convertDataSheetNameToMarkdocVariable = (key: string) => {
  return `dataSheet_${key}`;
};

export const extractVariableFromMarkdocAst = (source: string): string[] => {
  const ast = Markdoc.parse(source);
  const variables: string[] = [];
  for (const node of ast.walk()) {
    if (
      node.attributes?.content?.$$mdtype === "Variable" &&
      Array.isArray(node.attributes?.content?.path)
    ) {
      variables.push(
        (node.attributes?.content.path as Array<string>).join(".")
      );
    } else if (node.attributes?.content?.$$mdtype === "Function") {
      const v = Object.values(node.attributes?.content?.parameters).filter(
        (p) => (p as any)?.$$mdtype === "Variable"
      );
      v.forEach((a) =>
        variables.push(((a as any).path as Array<string>).join("."))
      );
    }
    // if feel like we are not accounting for variable passed in tags here --- we should add it
  }
  return variables;
};

export const getParsedDoc = (
  source: string,
  record: IRecord,
  user: IUser,
  availableColumns: AvailableColumn[],
  additionalTransform?: object
): RenderableTreeNode => {
  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});

  const transformAst = (ast: Node): Node => {
    const newAst = _.cloneDeep(ast);
    for (const node of newAst.walk()) {
      // do something with each node
      // this is where we can extract variable names and inject the columns definition for each variables
      if (node.type === "tag" && node.tag === "group") {
        const newNode = structuredClone(node);
        node.attributes = {
          ...node.attributes,
          node: JSON.stringify(newNode),
        };
        node.annotations = [
          ...node.annotations,
          { type: "attribute", name: "node", value: JSON.stringify(newNode) },
        ];
      }
    }
    return newAst;
  };

  const config = generateConfig(user, availableColumns);
  const ast = Markdoc.parse(source);
  const newAst = transformAst(ast);
  const content = Markdoc.transform(newAst, {
    ...config,
    ...additionalTransform,
    variables: formattedRecord,
  });
  return content;
};

export const getFormattedRecord = (
  columns: AvailableColumn[],
  record: IRecord,
  dataSheetsData?: IRecord
) => {
  return Object.keys(record).reduce<IRecord>(
    (acc, v) => {
      const column = columns.find((c) => c.data.key === v);
      if (!column) {
        return acc;
      }

      const format = () => {
        if (column.type === "property" && column.data.type !== "standard") {
          const parsedValue = parseLabel(record[v] as string);
          return parsedValue.name ? parsedValue?.name : parsedValue.id;
        }
        return record[v];
      };

      return {
        ...acc,
        [v]: format(),
      };
    },
    dataSheetsData ? dataSheetsData : {}
  );
};

export const formatRenderableTreeForGroupsInEmail = (
  tree: RenderableTreeNode
): RenderableTreeNode => {
  const walk = (
    t: RenderableTreeNode,
    replaceNameAs?: string
  ): RenderableTreeNode => {
    if ((t as Tag)?.$$mdtype) {
      const ta = t as Tag;
      if (ta.name === "Group" && ta.attributes.type === "list") {
        return {
          ...ta,
          name: "ul",
          children: ta.children.map((c) => walk(c, "li")),
        };
      } else if (ta.name === "Group" && ta.attributes.type === "paragraph") {
        return {
          ...ta,
          name: "p",
          children: ta.children.map((c) => walk(c, "p")),
        };
      } else {
        return {
          ...ta,
          name: replaceNameAs ? replaceNameAs : ta.name,
          children: ta.children.map((c) => walk(c)),
        };
      }
    } else if (Array.isArray(t)) {
      return t.map((c) => walk(c, replaceNameAs)) as any;
    }
    return t;
  };
  return walk(tree);
};
