import type { AgGridReact } from "ag-grid-react";
import { Tag, Typography } from "antd";
import _ from "lodash";
import { useState } from "react";
import type {
  ISetTableQueryAction,
  TableQuery,
} from "../../../../../../../../components/ag-grid/object-table/domain";
import type {
  IObject,
  IObjectView,
} from "../../../../../../../../interfaces/object";
import type { AvailableColumn } from "../../../../domain";
import {
  type AvailableProperty,
  getAvailableDimensions,
} from "../../../domain";
import ObjectToolbarFilter, {
  getFilterLength,
} from "../../../toolbar/ObjectToobarFilter";
import { getTotalNumberOfFilters } from "./domain";

export interface IObjectQueryBuilderHeaderProps {
  tableQuery: TableQuery;
  activeView?: IObjectView;
  isStale: boolean;
  setTableQuery: React.Dispatch<ISetTableQueryAction>;
  gridRef: React.RefObject<AgGridReact>;
  object: IObject;
  availableColumns: AvailableColumn[];
}

export function ObjectQueryBuilderHeader(
  props: IObjectQueryBuilderHeaderProps
) {
  const { tableQuery, setTableQuery, object, availableColumns } = props;

  // used to rerender the components when grouping changes
  // as refs property changes don't trigger refresh
  const [toggle, setToggle] = useState<boolean>(false);

  const refreshComponent = () => {
    setTimeout(() => {
      setToggle(!toggle);
    }, 200);
  };

  const availableProperties = availableColumns
    .filter((c) => c.type === "property")
    .map((m) => m.data as AvailableProperty);

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setTableQuery({
      action: "setTableQuery",
      tableQuery: {
        ...tableQuery,
        queryBuilderItems: {},
        filters: [],
        presetFilters: [],
      },
    });
  };

  const numberOfAppliedFilters = getTotalNumberOfFilters(tableQuery, object);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        paddingLeft: 8,
        paddingRight: 8,
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography.Text strong>Query</Typography.Text>
        {numberOfAppliedFilters > 0 && (
          <Tag
            bordered={false}
            style={{ background: "#DBE0FD", color: "#3f6ac4", marginLeft: 8 }}
            closable
            onClose={onClick}
            onClick={onClick}
          >
            {numberOfAppliedFilters}
          </Tag>
        )}
      </div>
      <div>
        <ObjectToolbarFilter
          overrideName={"More"}
          presetValue={tableQuery.presetFilters ?? []}
          onPresetChange={(v) => {
            setTableQuery({ action: "setPresetFilters", presetIds: v });
            refreshComponent();
          }}
          availableDimensions={getAvailableDimensions(availableColumns, {
            type: "sortAndFilter",
          })}
          object={object}
          value={tableQuery.filters}
          onChange={(v) => {
            setTableQuery({ action: "setFilters", filters: v });
            refreshComponent();
          }}
        />
      </div>
    </div>
  );
}
