export interface ISpacingProps {
  size: "xxl" | "xl" | "l" | "m" | "s" | "xs";
}

export function Spacing(props: ISpacingProps) {
  const { size } = props;

  const renderMargin = () => {
    if (size === "xs") {
      return `0.5em`;
    }
    if (size === "s") {
      return `1.5em`;
    }
    if (size === "m") {
      return `2.5em`;
    }
    if (size === "l") {
      return `3.5em`;
    }
    if (size === "xl") {
      return `4.5em`;
    }
    if (size === "xxl") {
      return `5.5em`;
    }
  };

  return (
    <div style={{ width: "100%", height: 1, marginTop: renderMargin() }} />
  );
}
