import type { Query } from "@cubejs-client/core";
import { Form, Input, InputNumber, Select, Space, Typography } from "antd";
import type { FormInstance } from "antd/lib";
import React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import { ColumnsAddition } from "../../../../../../../../components/widget-options/ColumnsAddition";
import type { IObject } from "../../../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import {
  getObjectColumns,
  isAvailableMetric,
  isAvailableProperty,
} from "../../../../../object/domain";
import PropertySort from "../../../../../object/viewer/toolbar/PropertiesSelector/PropertySortSelector";
import type { IRecord } from "../../../../domain";

import { MarkdocFormItem } from "../../../common/markdoc/MarkdocFormItem";
import type { IWidget } from "../../../domain";
import { FilterEditor } from "../../related-lists/editor/FilterEditor";
import type { IWidgetKPIConfig } from "../domain";

interface IWidgetKPIEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetKPIConfig;
  form: FormInstance;
}

type Props = IWidgetKPIEditorProps & InjectedOrgProps;

function WidgetKPIEditor(props: Props) {
  const { widget, object, record, conf, form, org } = props;

  const [open, setOpen] = React.useState<boolean>(false);

  const objectColumns = getObjectColumns(object).filter(
    (o) => !o.data.key.endsWith(".label")
  );

  return (
    <>
      <ChartOptionCollapse dark title="Metric">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item noStyle name={["config", "columns"]}>
                    <ColumnsAddition
                      availableColumns={objectColumns.filter(isAvailableMetric)}
                    />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: "Theme",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    name={["config", "theme"]}
                    initialValue={"gray"}
                  >
                    <Select
                      style={{ width: "100%" }}
                      options={[
                        {
                          label: (
                            <Space>
                              <div
                                style={{
                                  background: "#E5E9F0",
                                  border: "1px solid #D8D8D8",
                                  height: 12,
                                  width: 12,
                                  borderRadius: 6,
                                }}
                              />
                              Gray
                            </Space>
                          ),
                          value: "gray",
                        },
                        {
                          label: (
                            <Space>
                              <div
                                style={{
                                  background: "#D1E2FF",
                                  border: "1px solid #D8D8D8",
                                  height: 12,
                                  width: 12,
                                  borderRadius: 6,
                                }}
                              />
                              Blue
                            </Space>
                          ),
                          value: "blue",
                        },
                        {
                          label: (
                            <Space>
                              <div
                                style={{
                                  background: "#FFE0CD",
                                  border: "1px solid #D8D8D8",
                                  height: 12,
                                  width: 12,
                                  borderRadius: 6,
                                }}
                              />
                              Orange
                            </Space>
                          ),
                          value: "orange",
                        },
                        {
                          label: (
                            <Space>
                              <div
                                style={{
                                  background: "#FEEAB6",
                                  border: "1px solid #D8D8D8",
                                  height: 12,
                                  width: 12,
                                  borderRadius: 6,
                                }}
                              />
                              Yellow
                            </Space>
                          ),
                          value: "yellow",
                        },
                        {
                          label: (
                            <Space>
                              <div
                                style={{
                                  background: "#CEF5D1",
                                  border: "1px solid #D8D8D8",
                                  height: 12,
                                  width: 12,
                                  borderRadius: 6,
                                }}
                              />
                              Green
                            </Space>
                          ),
                          value: "green",
                        },
                      ]}
                    />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: "Label",
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "label"]}>
                    <Input />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: "Secondary text",
                flex: 1,
              },
              {
                content: (
                  <MarkdocFormItem
                    columns={objectColumns}
                    fieldName={["config", "secondary"]}
                  />
                ),
                flex: `0 auto`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
      <ChartOptionCollapse dark title="Sparkline">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: "Type",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    name={["config", "type"]}
                    initialValue={"none"}
                  >
                    <Select style={{ width: "100%" }}>
                      <Select.Option value="none">None</Select.Option>
                      <Select.Option value="table">Table</Select.Option>
                      <Select.Option value="bar">Bar</Select.Option>
                      <Select.Option value="progress">Progress</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          {/* Bar */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const isBarSparkline =
                form.getFieldValue(["config", "type"]) === "bar";
              if (!isBarSparkline) return undefined;
              return (
                <>
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Related object"
                            required
                            style={{ marginBottom: 4 }}
                            name={[
                              "config",
                              "barConfig",
                              "foreignObjectPropertyId",
                            ]}
                          >
                            <Select
                              optionLabelProp="label"
                              style={{ width: "100%", maxWidth: "100%" }}
                              popupMatchSelectWidth={false}
                            >
                              {object.foreignKeys.map((ok) => {
                                return (
                                  <Select.Option
                                    label={ok.object.name}
                                    key={ok.id}
                                    value={ok.id}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {ok.object.name}{" "}
                                      <Typography.Text type="secondary">
                                        on {ok.label}
                                      </Typography.Text>
                                    </div>
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "barConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <Form.Item
                                    label="Measure"
                                    required
                                    style={{ marginBottom: 4 }}
                                    name={["config", "barConfig", "metric"]}
                                  >
                                    <ColumnsAddition
                                      max={1}
                                      availableColumns={availableColumns.filter(
                                        isAvailableMetric
                                      )}
                                    />
                                  </Form.Item>
                                ),
                                flex: 1,
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "barConfig", "metric"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "barConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <Form.Item
                                    label="Group by"
                                    required
                                    style={{ marginBottom: 4 }}
                                    name={["config", "barConfig", "groupBy"]}
                                  >
                                    <ColumnsAddition
                                      max={1}
                                      availableColumns={availableColumns.filter(
                                        isAvailableProperty
                                      )}
                                    />
                                  </Form.Item>
                                ),
                                flex: 1,
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "barConfig", "groupBy"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "barConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <div>
                                    Tooltip content
                                    <Form.Item
                                      hidden
                                      noStyle
                                      name={["config", "barConfig", "tooltip"]}
                                    />
                                  </div>
                                ),
                                flex: `1`,
                              },
                              {
                                content: (
                                  <div style={{ margin: "12px 0" }}>
                                    <MarkdocFormItem
                                      fieldName={[
                                        "config",
                                        "barConfig",
                                        "tooltip",
                                      ]}
                                      columns={availableColumns.filter(
                                        (ac) => ac.type === "metric"
                                      )}
                                    />
                                  </div>
                                ),
                                flex: "0 auto",
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "barConfig", "tooltip"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "barConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <Form.Item
                                    required
                                    noStyle
                                    name={["config", "barConfig", "filters"]}
                                  >
                                    <FilterEditor
                                      autocomplete={async (
                                        dimension,
                                        operator,
                                        value
                                      ) => {
                                        try {
                                          const dim = availableColumns
                                            .filter(isAvailableProperty)
                                            .find(
                                              (ac) =>
                                                ac.type === "property" &&
                                                dimension === ac.data.key
                                            );

                                          if (!dim) {
                                            throw new Error("Dim not found");
                                          }

                                          const dimKey =
                                            dim.data.sortAndFilterKey;
                                          if (!dimKey) {
                                            throw new Error("Dim not found");
                                          }

                                          let query: Query = {
                                            dimensions: [dimKey],
                                            limit: 50,
                                            filters: [
                                              {
                                                member: dimKey,
                                                operator: "set",
                                              },
                                            ],
                                          };
                                          if (
                                            typeof value === "string" &&
                                            value !== "" &&
                                            operator
                                          ) {
                                            query = {
                                              dimensions: [dimKey],
                                              limit: 50,
                                              filters: [
                                                {
                                                  member: dimKey,
                                                  operator: operator,
                                                  values: [value],
                                                },
                                              ],
                                            };
                                          }

                                          const data = await lagoonServiceLoad(
                                            org.id,
                                            query,
                                            "OBJECT",
                                            object.id,
                                            undefined,
                                            LagoonCallOrigin.WHALY_APP
                                          );

                                          const results = data.tablePivot();

                                          return results.map((r) =>
                                            r[dimKey]?.toString?.()
                                          );
                                        } catch (err) {
                                          console.error(err);
                                          throw new Error(
                                            "Can't fetch recommendations"
                                          );
                                        }
                                      }}
                                      availableDimensions={availableColumns
                                        .filter(isAvailableProperty)
                                        .map<AvailableDimension>((ac) => {
                                          const prop = ac.data;
                                          return {
                                            key: prop.key,
                                            label: prop.label,
                                            description: prop.description,
                                            type: "standard",
                                            domain: prop.domain,
                                          };
                                        })}
                                    />
                                  </Form.Item>
                                ),
                                flex: 1,
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "options", "filters"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 6 }} shouldUpdate>
                    <ChartOptionLine
                      items={[
                        {
                          content: "Limit",
                          flex: 1,
                        },
                        {
                          content: (
                            <Form.Item
                              noStyle
                              name={["config", "barConfig", "limit"]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                placeholder="4"
                              />
                            </Form.Item>
                          ),
                          flex: 1,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 6 }} shouldUpdate>
                    <ChartOptionLine
                      items={[
                        {
                          content: "Highlight",
                          flex: 1,
                        },
                        {
                          content: (
                            <Form.Item
                              noStyle
                              name={["config", "barConfig", "highlightLabel"]}
                            >
                              <Input />
                            </Form.Item>
                          ),
                          flex: 1,
                        },
                      ]}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          {/* Table */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const isTableSparkline =
                form.getFieldValue(["config", "type"]) === "table";
              if (!isTableSparkline) return undefined;
              return (
                <>
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Related object"
                            required
                            style={{ marginBottom: 4 }}
                            name={[
                              "config",
                              "tableConfig",
                              "foreignObjectPropertyId",
                            ]}
                          >
                            <Select
                              optionLabelProp="label"
                              style={{ width: "100%", maxWidth: "100%" }}
                              popupMatchSelectWidth={false}
                            >
                              {object.foreignKeys.map((ok) => {
                                return (
                                  <Select.Option
                                    label={ok.object.name}
                                    key={ok.id}
                                    value={ok.id}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {ok.object.name}{" "}
                                      <Typography.Text type="secondary">
                                        on {ok.label}
                                      </Typography.Text>
                                    </div>
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "tableConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <Form.Item
                                    label="Measure"
                                    required
                                    style={{ marginBottom: 4 }}
                                    name={["config", "tableConfig", "metric"]}
                                  >
                                    <ColumnsAddition
                                      max={1}
                                      availableColumns={availableColumns.filter(
                                        isAvailableMetric
                                      )}
                                    />
                                  </Form.Item>
                                ),
                                flex: 1,
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "tableConfig", "metric"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "tableConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <Form.Item
                                    label="Group by"
                                    required
                                    style={{ marginBottom: 4 }}
                                    name={["config", "tableConfig", "groupBy"]}
                                  >
                                    <ColumnsAddition
                                      max={1}
                                      availableColumns={availableColumns.filter(
                                        isAvailableProperty
                                      )}
                                    />
                                  </Form.Item>
                                ),
                                flex: 1,
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "tableConfig", "groupBy"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "tableConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <Form.Item
                                    required
                                    noStyle
                                    name={["config", "tableConfig", "filters"]}
                                  >
                                    <FilterEditor
                                      autocomplete={async (
                                        dimension,
                                        operator,
                                        value
                                      ) => {
                                        try {
                                          const dim = availableColumns
                                            .filter(isAvailableProperty)
                                            .find(
                                              (ac) =>
                                                ac.type === "property" &&
                                                dimension === ac.data.key
                                            );

                                          if (!dim) {
                                            throw new Error("Dim not found");
                                          }

                                          const dimKey =
                                            dim.data.sortAndFilterKey;
                                          if (!dimKey) {
                                            throw new Error("Dim not found");
                                          }

                                          let query: Query = {
                                            dimensions: [dimKey],
                                            limit: 50,
                                            filters: [
                                              {
                                                member: dimKey,
                                                operator: "set",
                                              },
                                            ],
                                          };
                                          if (
                                            typeof value === "string" &&
                                            value !== "" &&
                                            operator
                                          ) {
                                            query = {
                                              dimensions: [dimKey],
                                              limit: 50,
                                              filters: [
                                                {
                                                  member: dimKey,
                                                  operator: operator,
                                                  values: [value],
                                                },
                                              ],
                                            };
                                          }

                                          const data = await lagoonServiceLoad(
                                            org.id,
                                            query,
                                            "OBJECT",
                                            object.id,
                                            undefined,
                                            LagoonCallOrigin.WHALY_APP
                                          );

                                          const results = data.tablePivot();

                                          return results.map((r) =>
                                            r[dimKey]?.toString?.()
                                          );
                                        } catch (err) {
                                          console.error(err);
                                          throw new Error(
                                            "Can't fetch recommendations"
                                          );
                                        }
                                      }}
                                      availableDimensions={availableColumns
                                        .filter(isAvailableProperty)
                                        .map<AvailableDimension>((ac) => {
                                          const prop = ac.data;
                                          return {
                                            key: prop.key,
                                            label: prop.label,
                                            description: prop.description,
                                            type: "standard",
                                            domain: prop.domain,
                                          };
                                        })}
                                    />
                                  </Form.Item>
                                ),
                                flex: 1,
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "options", "filters"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const currentPropertyId = form.getFieldValue([
                        "config",
                        "tableConfig",
                        "foreignObjectPropertyId",
                      ]);
                      const propertyExists = currentPropertyId
                        ? object.foreignKeys.find(
                            (fk) => fk.id === currentPropertyId
                          )
                        : undefined;

                      if (currentPropertyId && propertyExists) {
                        const availableColumns = getObjectColumns(
                          propertyExists.object
                        );
                        return (
                          <ChartOptionLine
                            items={[
                              {
                                content: (
                                  <Form.Item
                                    label="Sort"
                                    required
                                    style={{ marginBottom: 4 }}
                                    name={["config", "tableConfig", "sortBy"]}
                                  >
                                    <PropertySort columns={availableColumns} />
                                  </Form.Item>
                                ),
                                flex: 1,
                              },
                            ]}
                          />
                        );
                      } else {
                        return (
                          <Form.Item
                            noStyle
                            hidden
                            name={["config", "tableConfig", "sortBy"]}
                          />
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 6 }} shouldUpdate>
                    <ChartOptionLine
                      items={[
                        {
                          content: "Limit",
                          flex: 1,
                        },
                        {
                          content: (
                            <Form.Item
                              noStyle
                              name={["config", "tableConfig", "limit"]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                placeholder="10"
                              />
                            </Form.Item>
                          ),
                          flex: 1,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 6 }} shouldUpdate>
                    <ChartOptionLine
                      items={[
                        {
                          content: "Highlight",
                          flex: 1,
                        },
                        {
                          content: (
                            <Form.Item
                              noStyle
                              name={["config", "tableConfig", "highlightLabel"]}
                            >
                              <Input />
                            </Form.Item>
                          ),
                          flex: 1,
                        },
                      ]}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>
    </>
  );
}

export default compose<Props, IWidgetKPIEditorProps>(WithOrg)(WidgetKPIEditor);
