import { Comment } from "@ant-design/compatible";
import { Avatar, Drawer, List, Space, Tooltip, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/error/Error";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IAuditLog } from "../../../interfaces/auditLog";
import type { IUser } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import "./HistoryDrawer.scss";
import type { HistoryValueRendererFieldType } from "./HistoryValueRenderer";
import { HistoryValueRenderer } from "./HistoryValueRenderer";
const { Text } = Typography;

interface formattedAuditLog {
  actions: Element[] | JSX.Element[] | [];
  content: Element | JSX.Element;
  date: Element | JSX.Element;
  user: IUser;
}

interface IHistoryDrawerProps {
  onClose: () => void;
  visible: boolean;
  relatedObjects: IRelatedObject[];
}

export interface IRelatedObject {
  recordId: string;
  tableName: "View" | "Dataset";
}

type Props = IHistoryDrawerProps & InjectedOrgProps;

function HistoryDrawer(props: Props) {
  const { onClose, visible, org, relatedObjects, user } = props;
  const [auditLogs, setAuditLogs] = React.useState<AsyncData<IAuditLog[]>>({
    status: "initial",
  });

  React.useEffect(() => {
    if (visible) {
      const query = {
        org: { id: org.id },
        fieldName_not: "query",
        OR: relatedObjects.map((ro) => ({
          AND: Object.keys(ro).map((k) => ({
            [k]: ro[k],
          })),
        })),
      };
      setAuditLogs({ status: "loading" });
      GraphQLService(
        `query getAuditLog($query:  AuditLogWhereInput!) {
          allAuditLogs(where: $query, sortBy: at_DESC, first: 100) {
            id
            tableName
            fieldName
            recordId
            previousValue
            newValue
            at
            user {
              id
              firstName
              lastName
              avatarColor
              gravatar
            }
          }
        }`,
        {
          query: query,
        }
      )
        .then((r) => {
          setAuditLogs({ status: "success", data: r.allAuditLogs });
        })
        .catch((err) => {
          setAuditLogs({ status: "error", error: err });
        });
    }
  }, [visible, org.id]);

  const renderInner = () => {
    if (auditLogs.status === "initial" || auditLogs.status === "loading") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }

    if (auditLogs.status === "error") {
      return (
        <Feednack>
          <Error>{JSON.stringify(auditLogs.error)}</Error>
        </Feednack>
      );
    }

    const renderChangeContent = (
      tableName: string,
      fieldPath: string,
      prevValue?: string,
      currValue?: string
    ) => {
      const [field] = fieldPath.split(".");
      let fieldType: HistoryValueRendererFieldType = "unknown";
      if (field === "sql") {
        fieldType = "sql";
      } else if (field === "query") {
        fieldType = "query";
      }
      if (prevValue && !currValue) {
        return (
          <div>
            Deleted the{" "}
            <HistoryValueRenderer
              previousValue={prevValue}
              newValue={null}
              fieldType={fieldType}
            >
              <span>
                {tableName.toLocaleLowerCase()} {field}
              </span>
            </HistoryValueRenderer>
          </div>
        );
      }
      if (!prevValue && currValue) {
        return (
          <div>
            Added the{" "}
            <HistoryValueRenderer
              previousValue={null}
              newValue={currValue}
              fieldType={fieldType}
            >
              <span>
                {tableName.toLocaleLowerCase()} {field}
              </span>
            </HistoryValueRenderer>
          </div>
        );
      }
      return (
        <div>
          Updated the{" "}
          <HistoryValueRenderer
            previousValue={prevValue}
            newValue={currValue}
            fieldType={fieldType}
          >
            <span>
              {tableName.toLocaleLowerCase()} {field}
            </span>
          </HistoryValueRenderer>{" "}
          property
        </div>
      );
    };

    const formattedAuditLogs: formattedAuditLog[] = auditLogs.data.map(
      (auditLog, i) => {
        return {
          actions: [],
          user: auditLog.user,
          content: (
            <div>
              {renderChangeContent(
                auditLog.tableName,
                auditLog.fieldName,
                auditLog.previousValue,
                auditLog.newValue
              )}
            </div>
          ),
          date: (
            <Tooltip title={moment(auditLog.at).format("YYYY-MM-DD HH:mm:ss")}>
              <span>{moment(auditLog.at).fromNow()}</span>
            </Tooltip>
          ),
        };
      }
    );

    const LIMIT = user.isAdmin ? 0 : 3;

    return (
      <Space className="history-drawer-comments" direction="vertical">
        <Text>View your dataset revision history and understand changes.</Text>
        <List
          className="comment-list"
          itemLayout="horizontal"
          dataSource={formattedAuditLogs}
          renderItem={(item, index) => (
            <li
              className={
                LIMIT && index >= LIMIT
                  ? "history-drawer-comment-blurred"
                  : "history-drawer-comment"
              }
            >
              <Comment
                actions={item.actions.map((a) => (
                  <>{a}</>
                ))}
                author={`${
                  item.user
                    ? `${item.user.firstName} ${item.user.lastName}`
                    : "Whaly App"
                }`}
                avatar={
                  item.user ? (
                    <UserAvatar size={"default"} user={item.user} />
                  ) : (
                    <Avatar>W</Avatar>
                  )
                }
                content={<>{item.content}</>}
                datetime={<>{item.date}</>}
              />
            </li>
          )}
        />
      </Space>
    );
  };

  return (
    <Drawer
      open={visible}
      destroyOnClose={true}
      onClose={onClose}
      width={"40%"}
      title="History"
      className="history-drawer"
    >
      {renderInner()}
    </Drawer>
  );
}

export default compose<Props, IHistoryDrawerProps>(WithOrg)(HistoryDrawer);
