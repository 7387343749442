import { Button, Space } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import {
  ResizableDrawerBody,
  ResizableDrawerHeader,
} from "../../../../../../components/resizable/Drawer";
import type { IExploration } from "../../../../../../interfaces/explorations";
import type { ITile } from "../../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../../routes/routes";
import ExplorationEditor from "../../../../../exploration/single/ExplorationEditor";
import type { ILagoonQuery } from "../../../../../exploration/single/domain";

interface IChartEditTileDrawerProps {
  setVisible: (b: boolean) => void;
  tile: ITile;
  onCancel?: (e: IExploration) => Promise<void>;
  onSave: (t: ITile, q: ILagoonQuery, e: IExploration) => Promise<void>;
  visible: boolean;
}

type Props = IChartEditTileDrawerProps & RouteComponentProps<{}>;

function ChartEditTileDrawer(props: Props) {
  const {
    tile,
    setVisible,
    onSave,
    match: { params },
  } = props;

  const initialQuery: ILagoonQuery = {
    ...(JSON.parse(tile.content) as ILagoonQuery),
    chartType: tile.chartType,
  };

  const [currentQuery, setCurrentQuery] =
    React.useState<ILagoonQuery>(initialQuery);
  const [isStale, setIsStale] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [exploration, setExploration] = React.useState<IExploration>();

  const updateQuery = () => {
    setSaving(true);
    onSave(tile, currentQuery, exploration)
      .then(() => {
        setVisible(false);
        setSaving(false);
      })
      .catch(() => setSaving(false));
  };

  return (
    <>
      <ResizableDrawerHeader
        title={`Updating tile: ${tile.name}`}
        extra={
          <Space>
            <Button
              loading={saving}
              disabled={saving || isStale}
              onClick={updateQuery}
              type="primary"
            >
              Update tile
            </Button>
          </Space>
        }
        onClose={async () => {
          if (props.onCancel) {
            await props.onCancel(exploration);
          }
          setVisible(false);
        }}
      />
      <ResizableDrawerBody>
        <ExplorationEditor
          isEmbeded={true}
          initialQuery={initialQuery}
          liftExploration={(e) => {
            setExploration(e);
          }}
          previousRoute={routeDescriptor.reportTileExplorationEdit.renderRoute({
            ...params,
          })}
          injectedExplorationSlug={tile.exploration.slug}
          getCurrentQuery={(
            isStale,
            analysisType,
            chartType,
            measures,
            dimensions,
            filters,
            filterOperator,
            dateRange,
            orderBy,
            limit,
            comparison,
            timeDimension,
            selectedGranularity,
            pivotConfig,
            chartOptions,
            forecast,
            extra,
            metricFilters,
            metricFilterOperator
          ) => {
            setIsStale(isStale);
            setCurrentQuery({
              selectedMeasures: measures,
              selectedDimensions: dimensions,
              filters: filters as any,
              filterOperator: filterOperator,
              selectedGranularity: selectedGranularity,
              dateRange: dateRange,
              comparison: comparison,
              selectedTime: timeDimension,
              analysisType: analysisType,
              orderBy: orderBy,
              limit: limit,
              pivotDimensions: pivotConfig,
              chartOptions: chartOptions,
              chartType: chartType,
              forecast: forecast,
              extra: extra,
              metricFilters,
              metricFilterOperator,
            });
          }}
          initialQueryLoad={true}
        />
      </ResizableDrawerBody>
    </>
  );
}

export default compose<Props, IChartEditTileDrawerProps>(withRouter)(
  ChartEditTileDrawer
);
