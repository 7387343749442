import Markdoc from "@markdoc/markdoc";
import { Typography } from "antd";
import React from "react";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import { fetchDatasheetConcurrentlyReturnJSON } from "../../../common/markdoc/datasheet/api";
import {
  convertDataSheetNameToMarkdocVariable,
  convertKeyToMarkdocVariable,
  getParsedDoc,
} from "../../../common/markdoc/domain";
import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetTextConfig } from "../domain";
import { getDefaultConfig } from "../domain";

interface IEmailWidgetTextRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetTextConfig> {}

export function EmailWidgetTextRenderer(props: IEmailWidgetTextRendererProps) {
  const { conf, record, object, user, edit, org } = props;

  const defaults = getDefaultConfig(conf, record);
  const align = defaults.align;
  const source = defaults.content as string;
  const columns = getObjectColumns(object);

  const dataSheets =
    conf?.dataSheets && Array.isArray(conf.dataSheets) ? conf.dataSheets : [];

  const [dataSheetsData, setDatasheetsData] = React.useState<
    AsyncData<{ [key: string]: Array<IRecord> }>
  >({
    status: "initial",
  });

  const fetchData = async () => {
    try {
      setDatasheetsData({ status: "loading" });
      // fetch all datasheet & materialize them
      const dataSheetsResults = await fetchDatasheetConcurrentlyReturnJSON(
        dataSheets,
        org,
        record,
        object
      );

      const formattedDatasheetResults = Object.keys(dataSheetsResults).reduce(
        (acc, v) => {
          const dataSheetVar = convertDataSheetNameToMarkdocVariable(v);
          return {
            ...acc,
            [dataSheetVar]: dataSheetsResults[v].map((d) => {
              return Object.keys(d).reduce((a, b) => {
                return {
                  ...a,
                  [`${dataSheetVar}_${convertKeyToMarkdocVariable(b)}`]: d[b],
                };
              }, {});
            }),
          };
        },
        {}
      );

      setDatasheetsData({
        status: "success",
        data: formattedDatasheetResults,
      });
    } catch (err) {
      console.error(err);
      setDatasheetsData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (source && dataSheets && dataSheets.length > 0 && !edit) {
      fetchData();
    }
  }, [source, dataSheets, record]);

  if (dataSheets && dataSheets.length > 0 && !edit) {
    if (
      dataSheetsData.status === "initial" ||
      dataSheetsData.status === "loading"
    ) {
      return (
        <div style={{ height: 250 }}>
          <Loading />
        </div>
      );
    }
    if (dataSheetsData.status === "error") {
      return (
        <div style={{ height: 250 }}>
          <Feednack>There was an error fetching your datasheets</Feednack>
        </div>
      );
    }
  }

  if (!source) {
    return (
      <Typography.Text type="secondary">
        Please configure this widget
      </Typography.Text>
    );
  }

  const dataSheetFormattedData =
    dataSheetsData.status === "success" ? dataSheetsData.data : ({} as any);

  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, dataSheetFormattedData);

  const content = getParsedDoc(source, formattedRecord, user, columns);

  const html = Markdoc.renderers.react(content, React);

  return (
    <div
      style={{
        textAlign: align,
      }}
    >
      {html}
    </div>
  );
}
