import * as React from "react";
import { parseLabel } from "../../../../../../domain";

export interface ILogoProps {
  src?: string;
  id: string;
  alt?: string;
  label?: string;
}

export function Logo(props: React.PropsWithChildren<ILogoProps>) {
  const { src, children, id, alt, label } = props;

  const getProps = (): { src: string; alt: string } => {
    if (label) {
      const { image, name } = parseLabel(label);
      return {
        src: image ?? src ?? "",
        alt: name ?? alt ?? "",
      };
    }
    return {
      src: src ?? "",
      alt: alt ?? "",
    };
  };

  const p = getProps();

  return (
    <div className="logo">
      <img src={p.src} alt={p.alt} />
    </div>
  );
}
