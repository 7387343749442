import { Card } from "antd";
import React from "react";
import type { IObject } from "../../../../../../interfaces/object";
import type { IRecordWidgetData, IWidget } from "../domain";
import type { BaseConfig } from "./domain";
import { rendererConfig } from "./domain";

interface IWidgetRendererProps {
  object: IObject;
  widget: IWidget;
  record: IRecordWidgetData;
  state: string | undefined;
  setState: (value: string) => void;
  edit?: boolean;
}

export function WidgetRenderer(props: IWidgetRendererProps) {
  const { widget, object, record, edit, state, setState } = props;

  const renderer = rendererConfig[widget.type];

  if (!renderer) {
    return <Card size="small">Can't find widget type</Card>;
  }

  const wrapInCard = (node: React.ReactElement, conf: BaseConfig) => {
    if (renderer.removeCard || conf?._wly?.removeCard) {
      return node;
    }
    return (
      <Card size="small" title={widget.name}>
        {node}
      </Card>
    );
  };

  const conf = renderer.parseConfig(widget, object);

  if (record.status === "loading") {
    return wrapInCard(renderer.renderLoadingComponent(widget, conf), conf);
  }

  return wrapInCard(
    renderer.renderComponent(
      widget,
      object,
      record.data,
      conf,
      edit ? edit : false,
      state,
      setState
    ),
    conf
  );
}
