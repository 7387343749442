import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetTextConfig } from "./domain";
import { EmailWidgetTextEditor } from "./editor/EmailWidgetTextEditor";
import { EmailWidgetTextMjml } from "./mjml/EmailWidgetTextMjml";
import { EmailWidgetTextRenderer } from "./web-widget/EmailWidgetTextRenderer";

export const emailWidgetTextDefinition: IEmailRendererConfig<IEmailWidgetTextConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetTextConfig = {
        dataSheets: [],
        content: "",
        align: "left",
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetTextConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailWidgetTextEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          form={form}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailWidgetTextRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailWidgetTextMjml,
  };
