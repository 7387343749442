import { EditOutlined } from "@ant-design/icons";
import type { Query } from "@cubejs-client/core";
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { AvailableProperty } from "../../../../../object/viewer/domain";
import PropertySort from "../../../../../object/viewer/toolbar/PropertiesSelector/PropertySortSelector";
import { convertKeyToMarkdocVariable } from "../../../common/markdoc/domain";
import { MarkdocEditor } from "../../../common/markdoc/editor/MarkdocEditor";
import { FilterEditor } from "../../../widgets/related-lists/editor/FilterEditor";
import type { IWidgetEmailEditorProps } from "../../domain";
import type { IEmailWidgetRepeatableConfig } from "../domain";

interface IEmailWidgetRepeatableEditorProps
  extends IWidgetEmailEditorProps<IEmailWidgetRepeatableConfig> {}

export function EmailWidgetRepeatableEditor(
  props: IEmailWidgetRepeatableEditorProps
) {
  const { object, form, org } = props;

  const [open, setOpen] = useState<boolean>(false);
  const columns = getObjectColumns(object);

  return (
    <ChartOptionCollapse dark title="Repeatable Configuration">
      <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
        <ChartOptionLine
          items={[
            {
              content: (
                <Form.Item
                  label="Type"
                  style={{ marginBottom: 0 }}
                  name={["config", "type"]}
                >
                  <Select style={{ width: "100%" }}>
                    <Select.Option value="LIST">List</Select.Option>
                  </Select>
                </Form.Item>
              ),
              flex: 1,
            },
          ]}
        />
        <ChartOptionLine
          items={[
            {
              content: (
                <Form.Item
                  label="Related Object"
                  required
                  style={{ marginBottom: 0 }}
                  name={["config", "foreignObjectPropertyId"]}
                >
                  <Select style={{ width: "100%" }}>
                    {object.foreignKeys.map((ok) => {
                      return (
                        <Select.Option
                          label={ok.object.name}
                          key={ok.id}
                          value={ok.id}
                        >
                          <div
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            {ok.object.name}{" "}
                            <Typography.Text type="secondary">
                              on {ok.label}
                            </Typography.Text>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ),
              flex: 1,
            },
          ]}
        />
        <Form.Item noStyle shouldUpdate>
          {() => {
            const currentPropertyId = form.getFieldValue([
              "config",
              "foreignObjectPropertyId",
            ]);
            const propertyExists = currentPropertyId
              ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
              : undefined;

            if (currentPropertyId && propertyExists) {
              const availableColumns = getObjectColumns(propertyExists.object);
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: (
                        <Form.Item
                          style={{ marginBottom: 4 }}
                          label={"Sort"}
                          name={["config", "options", "sortBy"]}
                        >
                          <PropertySort columns={availableColumns} />
                        </Form.Item>
                      ),
                      flex: 1,
                    },
                  ]}
                />
              );
            } else {
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "sortBy"]}
                />
              );
            }
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const currentPropertyId = form.getFieldValue([
              "config",
              "foreignObjectPropertyId",
            ]);
            const propertyExists = currentPropertyId
              ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
              : undefined;

            if (currentPropertyId && propertyExists) {
              const availableColumns = getObjectColumns(propertyExists.object);
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: (
                        <Form.Item
                          required
                          noStyle
                          name={["config", "options", "filters"]}
                        >
                          <FilterEditor
                            autocomplete={async (
                              dimension,
                              operator,
                              value
                            ) => {
                              try {
                                const dim = availableColumns.find(
                                  (ac) =>
                                    ac.type === "property" &&
                                    dimension === ac.data.key
                                );

                                const dimKey = (dim?.data as AvailableProperty)
                                  .sortAndFilterKey;

                                if (!dimKey || !dim) {
                                  throw new Error("Dim not found");
                                }

                                let query: Query = {
                                  dimensions: [dimKey],
                                  limit: 50,
                                  filters: [
                                    {
                                      member: dimKey,
                                      operator: "set",
                                    },
                                  ],
                                };
                                if (
                                  typeof value === "string" &&
                                  value !== "" &&
                                  operator
                                ) {
                                  query = {
                                    dimensions: [dimKey],
                                    limit: 50,
                                    filters: [
                                      {
                                        member: dimKey,
                                        operator: operator,
                                        values: [value],
                                      },
                                    ],
                                  };
                                }

                                const data = await lagoonServiceLoad(
                                  org.id,
                                  query,
                                  "OBJECT",
                                  object.id,
                                  undefined,
                                  LagoonCallOrigin.WHALY_APP
                                );

                                const results = data.tablePivot();

                                return results.map((r) =>
                                  r[dimKey]?.toString?.()
                                );
                              } catch (err) {
                                console.error(err);
                                throw new Error("Can't fetch recommendations");
                              }
                            }}
                            availableDimensions={availableColumns
                              .filter((ac) => ac.type === "property")
                              .map<AvailableDimension>((ac) => {
                                const prop = ac.data as AvailableProperty;
                                return {
                                  key: prop.key,
                                  label: prop.label,
                                  description: prop.description,
                                  type: "standard",
                                  domain: prop.domain,
                                };
                              })}
                          />
                        </Form.Item>
                      ),
                      flex: 1,
                    },
                  ]}
                />
              );
            } else {
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "filters"]}
                />
              );
            }
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const currentPropertyId = form.getFieldValue([
              "config",
              "foreignObjectPropertyId",
            ]);
            const propertyExists = currentPropertyId
              ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
              : undefined;

            if (currentPropertyId && propertyExists) {
              const availableLayouts = propertyExists.object.layouts || [];
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: (
                        <Form.Item
                          label="Layout"
                          required
                          name={[
                            "config",
                            "options",
                            "foreignObjectSelectedlayout",
                          ]}
                          style={{ marginBottom: 0 }}
                        >
                          <Select
                            placeholder={"Default layout"}
                            style={{ width: "100%" }}
                          >
                            {availableLayouts
                              .filter((l) => l.type === "EMAIL")
                              .map((l) => {
                                return (
                                  <Select.Option key={l.id} value={l.id}>
                                    {l.name ? l.name : "Untitled"}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      ),
                      flex: 1,
                    },
                  ]}
                />
              );
            }
          }}
        </Form.Item>
        <ChartOptionLine
          style={{ marginTop: 12 }}
          items={[
            {
              content: "Limit",
              flex: 1,
            },
            {
              content: (
                <Form.Item noStyle name={["config", "options", "limit"]}>
                  <InputNumber style={{ width: "100%" }} min={1} />
                </Form.Item>
              ),
              flex: 1,
            },
          ]}
        />
        <ChartOptionLine
          items={[
            {
              content: <div>No items text</div>,
              flex: `1`,
            },
            {
              content: (
                <>
                  <Button
                    shape="circle"
                    onClick={() => setOpen(true)}
                    size="small"
                    type="text"
                    icon={<EditOutlined />}
                  />
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "fallback", "content"]}
                  />
                  <Modal
                    open={open}
                    onCancel={() => setOpen(false)}
                    destroyOnClose
                    maskClosable={false}
                    title={"Edit markdown"}
                    className="markdown-modal"
                    width={"50%"}
                    okText={"Save"}
                    onOk={() => {
                      setOpen(false);
                    }}
                  >
                    <Form.Item noStyle name={["config", "fallback", "content"]}>
                      <MarkdocEditor
                        columnsSuggestions={columns.map((c) => ({
                          key: `$${convertKeyToMarkdocVariable(c.data.key)}`,
                          label: c.data.label,
                        }))}
                      />
                    </Form.Item>
                  </Modal>
                </>
              ),
              flex: `0 auto`,
            },
          ]}
        />
      </Space>
    </ChartOptionCollapse>
  );
}
