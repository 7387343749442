import type { BinaryFilter, UnaryFilter } from "@cubejs-client/core";
import type { IGroup } from "./group";
import type { IOrg } from "./org";
import type { IAccessType } from "./reportSharing";
import type { ISchedule } from "./schedule";
import type { IDataset } from "./sources";
import type { ITable } from "./table";
import type { DataType } from "./transformations";
import type { IUser, IUserGravatarInfoWithGuestUser } from "./user";

export interface IObject {
  id: string;
  name: string;
  slug: string;
  color?: string;
  icon?: string;
  cubeName: string;
  model: IDataset;
  isDeleted: boolean;
  canBeListed: boolean;
  primaryLabel?: string;
  primaryImage?: string;
  org: IOrg;
  rowLevelFilters: string;
  registeredActions: IObjectRegisteredAction[];
  presetFilters?: IObjectPresetFilter[];
  layouts?: Array<IObjectLayout>;
  defaultLayout: IObjectLayout;
  position: number;
  table: ITable;
  defaultView?: IObjectView;
  isExtractEnabled: boolean;
  extractSchedule?: ISchedule;
  properties: Array<IObjectProperty>;
  foreignKeys: Array<IObjectProperty>;
  views: IObjectView[];
  queryBuilderSections: IObjectQueryBuilderSection[];
  canBeManagedByCurrentUser: boolean;
  createdAt: string;
  createdBy: IUser;
  updatedAt: string;
  updatedBy: IUser;
}

export interface IObjectSharing {
  id: string;
  user?: IUserGravatarInfoWithGuestUser;
  group?: IGroup;
  accessType: IAccessType;
}

export interface IObjectRegisteredAction {
  id: string;
  name: string;
  description: string;
  displayName: string;
  icon: string;
  config: string;
  type: "FEEDBACK" | "EXTERNAL_LINK";
  object: IObject;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IObjectLayout {
  id: string;
  name: string;
  config: string;
  header: string;
  type: IObjectLayoutType;
  cols: Array<IObjectLayoutCol>;
  rows: Array<IObjectLayoutRow>;
  widgets: Array<IObjectLayoutWidget>;
  object: IObject;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export type IObjectLayoutType = "EMAIL" | "RECORD";

interface IObjectLayoutRow {
  id: string;
  name?: string;
  displayFilters?: string;
  config: string;
  position: number;
}

interface IObjectLayoutCol {
  id: string;
  name?: string;
  config: string;
  position: number;
  row: {
    id: string;
  };
}

interface IObjectLayoutWidget {
  id: string;
  name?: string;
  displayFilters: string;
  config: string;
  position: number;
  type: string;
  parentColumn: {
    id: string;
  };
}

export interface IObjectProperty {
  id: string;
  propertyType: IObjectPropertyType;
  cubeName: string;
  columnName: string;
  columnDomain: DataType;
  label: string;
  description?: string;
  formatter?: IObjectPropertyFormats;
  sortingAndFilteringColumn?: string;
  hierarchyPath?: string;
  formatterConfig?: string;
  foreignKey?: IObject;
  userPropertyMapping?: string;
  object: IObject;
  createdAt: string;
  createdBy: IUser;
  updatedAt: string;
  updatedBy: IUser;
}

export type IObjectPropertyType = "standard" | "foreignKey" | "userKey";

export type IObjectPropertyFormats = "pills" | "activities" | "score";

export type IObjectPropertyPillsFormatterConfig = {
  type: "pills";
  config: { default: string; values: Array<{ value: string; color: string }> };
};

export type IObjectPropertyScoreFormatterConfig = {
  type: "score";
  config: { sortingKey?: string };
};

export type IObjectPropertyActivitiesFormatterConfig = {
  type: "activities";
  config: {
    sortingKey: string;
    dateRange: {
      window: "lookback" | "lookforward";
      unit: "day" | "month" | "week" | "year";
      number: number;
    };
    series: Array<{
      key: string;
      type: "bubble" | "band";
      color: string;
      label: string;
      drillObjectId?: string | undefined;
    }>;
  };
};

export type IObjectPropertyFormat =
  | IObjectPropertyPillsFormatterConfig
  | IObjectPropertyActivitiesFormatterConfig
  | IObjectPropertyScoreFormatterConfig;

export enum IObjectViewType {
  "MANUAL" = "MANUAL",
  "QUERY" = "QUERY",
}

export enum IObjectViewView {
  "table" = "table",
}

export interface IObjectView {
  id: string;
  name: string;
  slug: string;
  type: IObjectViewView;
  viewType: IObjectViewType;
  config?: string;
  object?: IObject;
  isPersonal: boolean;
  isDeleted: boolean;
  org: IOrg;
  createdAt: string;
  createdBy: IUser;
  updatedAt: string;
  updatedBy: IUser;
}

export interface IObjectPresetFilter {
  id: string;
  name: string;
  object: IObject;
  order: number;
  value: {
    operator: "and" | "or";
    filters: (UnaryFilter | BinaryFilter)[];
  };
  isDeleted: boolean;
  org: IOrg;
  createdAt: string;
  createdBy: IUser;
  updatedAt: string;
  updatedBy: IUser;
}

export interface IObjectQueryBuilderSection {
  id: string;
  name: string;
  object: IObject;
  order: number;
  type: IObjectQueryBuilderSectionType;
  isDeleted: boolean;
  items: IObjectQueryBuilderSectionItem[];
  org: IOrg;
  createdAt: string;
  createdBy: IUser;
  updatedAt: string;
  updatedBy: IUser;
}

export interface IObjectQueryBuilderSectionItem {
  id: string;
  label: string;
  property: string; // IObjectQueryBuilderSectionItemConfig_Parsed
  order: number;
  additionalFilters: string;
  isDeleted: boolean;
  org: IOrg;
  createdAt: string;
  createdBy: IUser;
  updatedAt: string;
  updatedBy: IUser;
}

export type IObjectQueryBuilderSectionItemConfig_Parsed =
  Array<IObjectQueryBuilderSectionItemConfigSingle_Parsed>;

export interface IObjectQueryBuilderSectionItemConfigSingle_Parsed {
  key: string;
  config: {
    singleValueOnly: boolean;
    limit?: number;
  };
}

export type IObjectQueryBuilderSectionType =
  | "OWN_PROPERTIES"
  | "FOREIGN_PROPERTIES";
