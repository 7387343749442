import _ from "lodash";
import type { ConfigType } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyRetentionChartIcon } from "../../icons/custom-icons/WlyRetentionChartIcon";
import type { SingleChartDefinition } from "../domain";

export const getLabelKey = (dimensions: string[]) => dimensions[0];
export const getPeriodKey = (dimensions: string[]) => dimensions[1];
export const getValueKey = (measures: string[]) => measures[0];
export const getSizeKey = (measures: string[]) => measures[1];

type DataLineValue = string | number | boolean;
type DataLine = { [key: string]: DataLineValue };

const getNumber = (value: DataLineValue | undefined): number => {
  switch (typeof value) {
    case "string":
      return parseInt(value);
    case "number":
      return value;
    default:
      return NaN;
  }
};
const getDataLineWithPercentage = (
  dataLine: DataLine,
  sizeKey: string,
  valueKey: string
): DataLine => {
  const [, size] =
    Object.entries(dataLine).find(([key]) => key === sizeKey) ?? [];
  const sizeAsNumber = getNumber(size);

  return Object.fromEntries(
    Object.entries(dataLine).map(([key, value]) => {
      const valueAsNumber = getNumber(value);

      if (
        key.includes(valueKey) &&
        !isNaN(valueAsNumber) &&
        !isNaN(sizeAsNumber)
      ) {
        return [key, valueAsNumber / sizeAsNumber];
      } else {
        return [key, value];
      }
    })
  );
};

const compareDataLine = (
  a: DataLine,
  b: DataLine,
  labelKey: string
): number => {
  const aValue = a[labelKey];
  const bValue = b[labelKey];
  if (typeof aValue === "string" && typeof bValue === "string")
    return aValue.localeCompare(bValue);
  if (typeof aValue === "boolean" && typeof bValue === "boolean")
    return +aValue - +bValue;
  if (typeof aValue === "number" && typeof bValue === "number")
    return aValue - bValue;

  return 0;
};

export const retentionChartDefinition: SingleChartDefinition = {
  label: "Retention chart",
  allowTimeComparison: false,
  minMetrics: 2,
  maxMetrics: 2,
  minDimensions: 2,
  maxDimensions: 2,
  getLabelKey,
  getPeriodKey,
  getValueKey,
  getSizeKey,
  icon: <WlyRetentionChartIcon />,
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: () => false,
    },
    left: {
      editable: false,
    },
  },
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs) => undefined,
  downloadCSV: ({ query, resultSet, additionalResultSet }) => {
    if (
      resultSet.status !== "success" ||
      additionalResultSet?.status !== "success"
    ) {
      return undefined;
    }

    const labelKey = getLabelKey(query.selectedDimensions);
    const periodKey = getPeriodKey(query.selectedDimensions);
    const valueKey = getValueKey(query.selectedMeasures);
    const sizeKey = getSizeKey(query.selectedMeasures);

    const usePercentage = query.chartOptions?.["retention-percent"] ?? true;

    // we recreate the data based on the resultSet and additionalResultSet
    let data = [...resultSet.data.tablePivot()]
      .map((line) => {
        const match =
          additionalResultSet.data
            .tablePivot({
              y: [periodKey],
            })
            .find((l) => l[labelKey] === line[labelKey]) || {};

        const cleanedMatch = _.omit(match, labelKey);
        const dataLine: DataLine = {
          ...line,
          ...cleanedMatch,
        };

        return usePercentage
          ? getDataLineWithPercentage(dataLine, sizeKey, valueKey)
          : dataLine;
      })
      .sort((a, b) => compareDataLine(a, b, labelKey));

    // we recreate the headers based on the resultSet and additionalResultSet
    const rawHeaders = [
      ...resultSet.data.tableColumns(),
      ...additionalResultSet.data
        .tableColumns({
          y: [periodKey],
        })
        ?.filter((col) => col.key !== labelKey),
    ];

    // we make sure the the headers follows the columns order
    const headers = Object.keys(data[0] || {}).map(
      (k) => rawHeaders.find((h) => h.key === k.split(",")[0])?.shortTitle ?? ""
    );

    const csvData = {
      headers: headers,
      data: data,
    };
    return csvData;
  },
  config: (a) => {
    const ret: Array<ConfigType> = [
      "retention-percent",
      "retention-colors",
      "palette-continue",
    ];
    return ret;
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  canPredict: (analysisType, query) => false,
  extractCustomizationSeries: (series, d) => {
    return {
      series: [{ key: "retention", label: "Retention" }],
      customColor: false,
      customLabel: false,
      customType: [],
    };
  },
};
