import { Typography } from "antd";

export interface IListProps {}

export function List(props: React.PropsWithChildren<IListProps>) {
  const { children } = props;
  return (
    <Typography.Paragraph>
      <ul>{children}</ul>
    </Typography.Paragraph>
  );
}
