import { Tag, type RenderableTreeNode } from "@markdoc/markdoc";

export const spacing = {
  render: "Spacing",
  children: [
    "paragraph",
    "tag",
    "list",
    "strong",
    "italic",
    "link",
    "em",
    "image",
    "heading",
  ],
  attributes: {
    size: {
      type: String,
      default: "m",
      matches: ["xs", "s", "m", "l", "xl", "xxl"],
    },
  },
  transform(node, config) {
    const attributes = node.transformAttributes(config);
    const children: RenderableTreeNode[] = node.transformChildren(config);
    return new Tag(`Spacing`, { ...attributes }, children);
  },
};
