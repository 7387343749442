import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import Error from "../../../../../components/error/Error";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IActiveObject } from "../../domain";
import { useGetSources } from "./api/useGetSources";
import ConnectorViewer from "./ConnectorViewer";

interface IConnectorViewerWrapperProps {
  activeObject: IActiveObject;
  currentWarehouse: IDestination;
}

type Props = IConnectorViewerWrapperProps &
  RouteComponentProps<{ organizationSlug: string; sourceSlug: string }>;

function ConnectorViewerWrapper(props: Props) {
  const { sources, pending, loading, error, refetch } = useGetSources({
    variables: { id: props.activeObject.value },
  });
  const hasSources = sources.length >= 1;

  if (!hasSources && (pending || loading)) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }
  if (error) {
    return (
      <Feednack>
        <Error />
      </Feednack>
    );
  }
  if (!hasSources) {
    return (
      <Feednack>
        <Error />
      </Feednack>
    );
  }

  return (
    <ConnectorViewer
      activeObject={props.activeObject}
      sources={sources}
      currentWarehouse={props.currentWarehouse}
      refetchSources={refetch}
    />
  );
}

export default compose<Props, IConnectorViewerWrapperProps>(withRouter)(
  ConnectorViewerWrapper
);
