import { Flex, Space, Typography } from "antd";
import { WlyCard } from "../../../../components/cards/WlyCard";
import { LabelRenderer } from "../../../../components/catalog/label/LabelRenderer";
import { compose } from "../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../components/sources/SourceImageRenderer";
import type { ISource } from "../../../../interfaces/sources";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SourceStatusRenderer from "../status/SourceStatusRenderer";

interface SourceOverviewProps {
  source: ISource;
  refetchSources: () => Promise<void>;
}

type Props = SourceOverviewProps & InjectedOrgProps;

const SourceOverview = ({ source, refetchSources }: Props): JSX.Element => {
  const { logo, name, label, description, website } =
    source.sourceMeta.publicInfo;

  return (
    <WlyCard>
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <div>
          <Flex gap={12} align="center" justify="space-between">
            <Flex gap={24} align="center">
              <div>
                <SourceImageRenderer img={logo} alt={name} size={40} />
              </div>
              <div>
                <Space direction="vertical" size={0}>
                  <Typography.Text strong style={{ fontSize: 20 }}>
                    {source.name}
                  </Typography.Text>
                  <Typography.Text type="secondary">{name}</Typography.Text>
                </Space>
              </div>
            </Flex>
            <div>
              <LabelRenderer label={label} />
            </div>
          </Flex>
        </div>

        {description}

        <a target="_blank" rel="noopener noreferrer" href={website}>
          {name} Website
        </a>

        <div>
          <div className="title">Your source status:</div>
          <SourceStatusRenderer
            source={source}
            refetchSources={refetchSources}
          />
        </div>
      </Space>
    </WlyCard>
  );
};

export default compose<Props, SourceOverviewProps>(WithOrg)(SourceOverview);
