import type { BinaryFilter, UnaryFilter } from "@cubejs-client/core";
import moment from "moment";
import type { IObject } from "../../../../../../../interfaces/object";
import type { DataType } from "../../../../../../../interfaces/transformations";
import { getObjectColumns } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import type { IWidgetVisibilityFiltersValue } from "./WidgetVisibilityFilters";

const evaluateUnaryBinaryFilter = (
  filter: UnaryFilter | BinaryFilter,
  dimensionValue: string | null,
  columnDomain: DataType
): boolean => {
  switch (filter.operator) {
    case "equals":
      if (columnDomain === "TIME") {
        return moment(dimensionValue).isSame(moment(filter.values?.[0]));
      }
      return (filter.values ? filter.values : []).some(
        (v) => v?.toString() === dimensionValue
      );
    case "notEquals":
      if (columnDomain === "TIME") {
        return !moment(dimensionValue).isSame(moment(filter.values?.[0]));
      }
      return (filter.values ? filter.values : []).every(
        (v) => v?.toString() !== dimensionValue
      );
    case "set":
      return !!dimensionValue;
    case "notSet":
      return !!!dimensionValue;
    case "afterDate":
      return moment(dimensionValue).isAfter(moment(filter.values?.[0]));
    case "afterOrOnDate":
      return moment(dimensionValue).isSameOrAfter(moment(filter.values?.[0]));
    case "beforeDate":
      return moment(dimensionValue).isBefore(moment(filter.values?.[0]));
    case "beforeOrOnDate":
      return moment(dimensionValue).isSameOrBefore(moment(filter.values?.[0]));
    case "inDateRange":
      return moment(dimensionValue).isBetween(
        moment(filter.values?.[0]),
        moment(filter.values?.[1])
      );
    case "notInDateRange":
      return !moment(dimensionValue).isBetween(
        moment(filter.values?.[0]),
        moment(filter.values?.[1])
      );
    case "contains":
      return (filter.values ? filter.values : []).some((v) =>
        dimensionValue?.toLowerCase().includes(v.toLowerCase())
      );
    case "notContains":
      return !(filter.values ? filter.values : []).some((v) =>
        dimensionValue?.toLowerCase().includes(v.toLowerCase())
      );
    case "startsWith":
      return (filter.values ? filter.values : []).some((v) =>
        dimensionValue?.toLowerCase()?.startsWith(v?.toString().toLowerCase())
      );
    case "endsWith":
      return (filter.values ? filter.values : []).some((v) =>
        dimensionValue?.toLowerCase()?.endsWith(v?.toString().toLowerCase())
      );
    case "notStartsWith":
      return !(filter.values ? filter.values : []).some((v) =>
        dimensionValue?.toLowerCase()?.startsWith(v?.toString().toLowerCase())
      );
    case "notEndsWith":
      return (filter.values ? filter.values : []).some((v) =>
        dimensionValue?.toLowerCase()?.endsWith(v?.toString().toLowerCase())
      );
    case "gt":
      try {
        const isGt = (filter.values ? filter.values : [])
          .filter((v) => !isNaN(+v))
          .some((v) => +dimensionValue > +v);
        return isGt;
      } catch (error) {
        console.error(error);
        return false;
      }
    case "gte":
      try {
        const isGte = (filter.values ? filter.values : [])
          .filter((v) => !isNaN(+v))
          .some((v) => +dimensionValue >= +v);
        return isGte;
      } catch (error) {
        console.error(error);
        return false;
      }
    case "lt":
      try {
        const isLt = (filter.values ? filter.values : [])
          .filter((v) => !isNaN(+v))
          .some((v) => +dimensionValue < +v);
        return isLt;
      } catch (error) {
        console.error(error);
        return false;
      }
    case "lte":
      try {
        const isLte = (filter.values ? filter.values : [])
          .filter((v) => !isNaN(+v))
          .some((v) => +dimensionValue <= +v);
        return isLte;
      } catch (error) {
        console.error(error);
        return false;
      }
  }
};

export const shouldDisplayWidget = (
  value: IWidgetVisibilityFiltersValue,
  record: IRecord,
  object: IObject,
  state: { [key: string]: string }
): boolean => {
  if (!value || value?.filters?.length === 0) return true;

  const availableColumns = getObjectColumns(object);
  const filtersMatch = (value.filters ? value.filters : []).map((filter) => {
    if (filter.member?.startsWith("widgetState::")) {
      // this is a widget state
      return evaluateUnaryBinaryFilter(filter, state[filter.member], "STRING");
    } else {
      // we consider it is an object column
      const column = availableColumns?.find?.(
        (c) => c.data.key === filter.member
      );
      const getSubstituteColumn = () => {
        if (column?.type === "metric") {
          return column.data.key;
        }
        return column.data.sortAndFilterKey;
      };
      return evaluateUnaryBinaryFilter(
        filter,
        record[getSubstituteColumn()]?.toString?.(),
        column?.type === "metric" ? "NUMERIC" : column.data.domain ?? "STRING"
      );
    }
  });

  if (value.operator === "and") {
    if (value.action === "show") {
      return filtersMatch.every((filtersMatch) => filtersMatch === true);
    } else if (value.action === "hide") {
      return !filtersMatch.every((filtersMatch) => filtersMatch === true);
    }
  } else if (value.operator === "or") {
    if (value.action === "show") {
      return filtersMatch.some((filtersMatch) => filtersMatch === true);
    } else if (value.action === "hide") {
      return !filtersMatch.some((filtersMatch) => filtersMatch === true);
    }
  }
};
