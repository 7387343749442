import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import type {
  BinaryFilter,
  BinaryOperator,
  TimeDimensionGranularity,
  UnaryFilter,
} from "@cubejs-client/core";
import { InputNumber, Select, Space, Tooltip } from "antd";
import _ from "lodash";
import * as React from "react";
import type { ChartType } from "../../../../../components/chart/domain";
import { ChartDefinition } from "../../../../../components/chart/domain";
import { SwitchIcons } from "../../../../../components/form/elements/switch-icons/SwitchIcons";
import type { IComparisonPeriod } from "../../../../../components/measures/comparison-selector/ComparisonSelector";
import ComparisonSelector from "../../../../../components/measures/comparison-selector/ComparisonSelector";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../../components/measures/filter-item/FilterItem";
import {
  validateFilters,
  validateUsedDimensioFilters,
} from "../../../../../components/measures/filter-item/domain";
import type { MeasureItemSortValue } from "../../../../../components/measures/measure-item/MeasureItem";
import MeasureListSelector from "../../../../../components/measures/measure-list-selector/MeasureListSelector";
import { MeasureSingleSelector } from "../../../../../components/measures/measure-single-selector/MeasureSingleSelector";
import MeasureSort from "../../../../../components/measures/measure-sort/MeasureSort";
import type { IExplorationVersion } from "../../../../../interfaces/explorations";
import { isMacintosh } from "../../../../../utils/isMacinthosh";
import { ChartOptionCollapse } from "../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../chart-options/components/ChartOptionLine";
import type { IWlyDatePickerInputValue } from "../../../../reports/view/filters/date-filter/WlyDatePicker";
import {
  WlyDatePicker,
  convertWlyDatePickerValueToMoment,
} from "../../../../reports/view/filters/date-filter/WlyDatePicker";
import type {
  FilterOperator,
  IAnalysisType,
  IForecastConfig,
  ILagoonQuery,
  ILagoonQueryExtra,
} from "../../domain";
import { AnalysisDefiniton } from "../../domain";
import { ChartSelector } from "./ChartSelector";
import "./QueryBuilder.scss";
import { DEFAULT_ROW_LIMIT } from "./domain";
import { QueryBuilderFilter } from "./query-builder-items/QueryBuilderFilter";
import { QueryBuilderMetricFilter } from "./query-builder-items/QueryBuilderMetricFilter";

type QueryBuilderFormFields = "MEASURE" | "DIMENSION" | "TIME" | "FILTER";

type QueryBuilderFormValidationError = {
  field?: QueryBuilderFormFields;
  message: string;
};

type QueryBuilderFormValidation = {
  isValid: boolean;
  errors?: Array<QueryBuilderFormValidationError>;
};

export type QueryBuilderFormStatus = {
  formValidation: QueryBuilderFormValidation;
  form: {
    isStale: boolean;
    isLoading: boolean;
    isExplorationVersionOutdated: boolean;
  };
  value?: QueryBuilderFormValue;
};

type ChartAnalysisDisplaySettings = {
  metrics: {
    display: boolean;
    min?: number;
    max?: number;
  };
  dimensions: {
    display: boolean;
    min?: number;
    max?: number;
  };
  time: {
    dimension: {
      required: boolean;
    };
    granularity: {
      display: boolean;
      allowed?: TimeDimensionGranularity[];
    };
    comparison: {
      display: boolean;
    };
  };
  extra: {
    display: boolean;
  };
  pivot: {
    display: boolean;
  };
  forecast: {
    display: boolean;
  };
  sort: {
    display: boolean;
    allowMetrics: boolean;
    allowDimensions: boolean;
  };
};

export type QueryBuilderFormValue = {
  analysisType: IAnalysisType;
  chartType: ChartType;
  measures: string[];
  dimensions: string[];
  filters: (UnaryFilter | BinaryFilter)[];
  filterOperator: FilterOperator;
  metricFilters: (UnaryFilter | BinaryFilter)[];
  metricFilterOperator: FilterOperator;
  dateRange: IWlyDatePickerInputValue;
  orderBy: Array<[string, MeasureItemSortValue]>;
  limit?: number;
  comparison?: IComparisonPeriod;
  timeDimension?: string;
  selectedGranularity?: TimeDimensionGranularity;
  pivotConfig?: string[];
  forecast: IForecastConfig;
  extra?: ILagoonQueryExtra;
};

interface IQueryBuilderProps {
  chartType: ChartType;
  explorationVersion: IExplorationVersion;
  availableMetrics: AvailableMetric[];
  availableDimensions: AvailableDimension[];
  availableTime: AvailableDimension[];
  loading: boolean;
  initialQuery?: ILagoonQuery;
  initialQueryLoad?: boolean;
  isStale: boolean;
  autocomplete: (
    dimensionName: string,
    operator: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
  onRunQuery: (options: QueryBuilderFormValue) => void;
  setStale: (isStale: boolean) => void;
  onFormUpdate?: (update: QueryBuilderFormStatus) => void;
}

interface IState {
  selectedMeasures: string[];
  selectedDimensions: string[];
  selectedTime: string;
  dateRange: IWlyDatePickerInputValue;
  comparison: IComparisonPeriod;
  selectedGranularity: TimeDimensionGranularity;
  filterOperator: FilterOperator;
  filters: (UnaryFilter | BinaryFilter)[];
  metricFilterOperator: FilterOperator;
  metricFilters: (UnaryFilter | BinaryFilter)[];
  analysisType: IAnalysisType;
  chartType: ChartType;
  orderBy: Array<[string, MeasureItemSortValue]>;
  limit: number;
  pivotConfig: string[];
  extra: ILagoonQueryExtra;
  formSnapshotOnLastRun: string;
  explorationVersionOnLastRun: number;
  forecast: IForecastConfig;
}

type Props = IQueryBuilderProps;

class QueryBuilder extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedMeasures: [],
      selectedDimensions: [],
      selectedTime: undefined,
      comparison: undefined,
      selectedGranularity: undefined,
      dateRange: "LAST_7_DAYS",
      filterOperator: "and",
      filters: [],
      metricFilterOperator: "and",
      metricFilters: [],
      orderBy: [],
      limit: undefined,
      formSnapshotOnLastRun: "",
      explorationVersionOnLastRun: this.props.explorationVersion.value,
      analysisType: props.initialQuery?.analysisType
        ? props.initialQuery.analysisType
        : "CATEGORIES",
      chartType: props.initialQuery?.chartType
        ? props.initialQuery.chartType
        : "table",
      pivotConfig:
        props.initialQuery?.pivotDimensions?.length > 0
          ? props.initialQuery?.pivotDimensions
          : null,
      extra: props.initialQuery?.extra ? props.initialQuery?.extra : null,
      forecast: props.initialQuery?.forecast
        ? props.initialQuery?.forecast
        : {
            enabled: false,
          },
      ...props.initialQuery,
    };
    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  takeFormSnapshot() {
    const snapshot = this.getFormSnapshot(this.state);
    if (this.state.formSnapshotOnLastRun !== snapshot) {
      this.setState({ formSnapshotOnLastRun: snapshot });
    }
  }

  getFormSnapshot(state: IState) {
    const statePropertiesToMonitor = _.pick(state, [
      "chartType",
      "analysisType",
      "selectedMeasures",
      "selectedDimensions",
      "filters",
      "filterOperator",
      "dateRange",
      "orderBy",
      "limit",
      "comparison",
      "selectedTime",
      "selectedGranularity",
      "pivotConfig",
      "forecast",
      "geoMapNameDimension",
      "extra",
      "metricFilters",
      "metricFilterOperator",
    ]);
    return JSON.stringify(statePropertiesToMonitor);
  }

  // used by ref in chart overlay
  listAvailableMeasureKeys(): Array<AvailableMetric | AvailableDimension> {
    const displaySettings = this.getDisplaySettings(this.state);

    const m = displaySettings.metrics.display
      ? this.props.availableMetrics.filter(
          (m) => !this.state.selectedMeasures.includes(m.key)
        )
      : [];

    const d = displaySettings.dimensions.display
      ? this.props.availableDimensions.filter(
          (m) => !this.state.selectedDimensions.includes(m.key)
        )
      : [];

    const t = this.props.availableTime;

    return _.uniq([...m, ...d, ...t]);
  }

  // used by ref in chart overlay
  onExternalMeasureAdd(m: string): void {
    // check if time
    if (this.props.availableTime.map((at) => at.key).includes(m)) {
      this.setState({ selectedTime: m });
      // check if dim
    } else if (
      this.props.availableDimensions.map((ad) => ad.key).includes(m) &&
      this.getDisplaySettings(this.state).dimensions.display
    ) {
      this.setState({
        selectedDimensions: [...this.state.selectedDimensions, m],
      });
      // check if met
    } else if (
      this.props.availableMetrics.map((am) => am.key).includes(m) &&
      this.getDisplaySettings(this.state).metrics.display
    ) {
      this.setState({
        selectedMeasures: [...this.state.selectedMeasures, m],
      });
    }
  }

  onKeyPressed(e: KeyboardEvent) {
    if (
      (isMacintosh() ? e.metaKey : e.ctrlKey) &&
      e.key?.toLowerCase() === "enter" &&
      this.validateForm().isValid &&
      !this.props.loading
    ) {
      this.runQuery();
      e.preventDefault();
    }
  }

  isFormDirty(): boolean {
    const snapshot = this.getFormSnapshot(this.state);
    if (snapshot !== this.state.formSnapshotOnLastRun) return true;
    return false;
  }

  isTimeComparisonDisabled(): boolean {
    if (!this.state.selectedTime) return true;
    if (!this.state.dateRange) return true;
    // todo: disable comparison in all time ?
    return false;
  }

  componentDidMount() {
    if (this.props.initialQueryLoad && this.props.initialQuery) {
      this.runQuery();
    }
    this.takeFormSnapshot();
    this.bubbleFormData();
    document.addEventListener("keydown", this.onKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  componentDidUpdate(prevProps: IQueryBuilderProps, prevState: IState) {
    this.bubbleFormData();
    if (this.isFormDirty() && this.props.isStale === false) {
      this.props.setStale(true);
    } else if (!this.isFormDirty() && this.props.isStale === true) {
      this.props.setStale(false);
    }

    // we reset some values on analysis type change
    if (prevState.analysisType !== this.state.analysisType) {
      if (this.state.analysisType === "METRIC") {
        this.setState({
          analysisType: "METRIC",
          selectedMeasures: this.state.selectedMeasures,
          selectedDimensions: [],
          selectedGranularity: undefined,
          metricFilters: [],
          metricFilterOperator: "and",
          orderBy: [],
        });
      } else if (this.state.analysisType === "TIME") {
        this.setState({
          analysisType: "TIME",
          selectedGranularity: "day",
          orderBy: [],
        });
      } else if (this.state.analysisType === "CATEGORIES") {
        this.setState({
          analysisType: "CATEGORIES",
          selectedGranularity: undefined,
          orderBy: [],
        });
      }
    }
    const previousDisplaySettings = this.getDisplaySettings(prevState);
    const currentDisplaySettings = this.getDisplaySettings(this.state);

    // we update the time granularity is it's not supported anymore
    if (
      currentDisplaySettings.time.granularity.allowed &&
      !currentDisplaySettings.time.granularity.allowed.includes(
        this.state.selectedGranularity
      )
    ) {
      const defaultValue: TimeDimensionGranularity =
        currentDisplaySettings.time.granularity.allowed.includes("day")
          ? "day"
          : currentDisplaySettings.time.granularity.allowed[0];
      this.setState({ selectedGranularity: defaultValue });
    }
    // we delete the pivot value if the current analysis does not support pivot anymore
    if (
      previousDisplaySettings.pivot.display &&
      !currentDisplaySettings.pivot.display
    ) {
      this.setState({ pivotConfig: null });
    }
    // we delete the pivot value if the current analysis does not support pivot anymore
    if (
      previousDisplaySettings.extra.display &&
      !currentDisplaySettings.extra.display
    ) {
      this.setState({ extra: null });
    }
    // we delete the pivot dimension if it's not used in the query anymore
    if (
      !_.isEqual(prevState.selectedDimensions, this.state.selectedDimensions) &&
      this.state.pivotConfig?.length
    ) {
      const newPivotConfig = this.state.pivotConfig
        .filter((pc) => this.state.selectedDimensions.includes(pc))
        .filter((pc) => pc);

      if (!newPivotConfig.length) {
        this.setState({ pivotConfig: null });
      }
    }
    // we disable the forecast if the current query does not support it anymore
    if (
      prevState.forecast.enabled &&
      this.state.forecast.enabled &&
      currentDisplaySettings.forecast.display === false
    ) {
      this.setState({ forecast: { enabled: false } });
    }

    // we delete sorted measures if they are not in query anymore
    if (
      !_.isEqual(prevState.selectedDimensions, this.state.selectedDimensions) ||
      (!_.isEqual(prevState.selectedMeasures, this.state.selectedMeasures) &&
        this.state.orderBy.length)
    ) {
      this.setState({
        orderBy: this.state.orderBy.filter(
          (ob) =>
            this.state.selectedDimensions.includes(ob[0]) ||
            this.state.selectedMeasures.includes(ob[0])
        ),
      });
    }
    // we delete time comparison if chart does not support it anymore
    if (
      this.state.comparison &&
      prevState.comparison &&
      (currentDisplaySettings.time.comparison.display === false ||
        this.isTimeComparisonDisabled())
    ) {
      this.setState({ comparison: undefined });
    }
  }

  getDisplaySettings = (state: IState): ChartAnalysisDisplaySettings => {
    const chartDefinition = ChartDefinition[state.chartType];
    const analysisDefinition = AnalysisDefiniton.find(
      (a) => a.key === state.analysisType
    );

    const displaySettings: ChartAnalysisDisplaySettings = {
      metrics: {
        display: true,
      },
      dimensions: {
        display: true,
      },
      time: {
        dimension: {
          required: state.analysisType === "TIME" ? true : false,
        },
        comparison: {
          display: chartDefinition.allowTimeComparison,
        },
        granularity: {
          display: true,
        },
      },
      pivot: {
        display: chartDefinition.pivot(state.analysisType),
      },
      extra: {
        display: chartDefinition.queryExtra?.length > 0,
      },
      forecast: {
        display: chartDefinition.canPredict(state.analysisType, {
          pivotDimensions: state.pivotConfig,
          comparison: state.comparison,
          selectedDimensions: state.selectedDimensions,
        }),
      },
      sort: {
        display:
          analysisDefinition.allowSortMetrics ||
          analysisDefinition.allowSortDimensions,
        allowMetrics: analysisDefinition.allowSortMetrics,
        allowDimensions: analysisDefinition.allowSortDimensions,
      },
    };

    if (
      typeof analysisDefinition.allowedMetrics === "boolean" &&
      analysisDefinition.allowedMetrics === false
    ) {
      displaySettings.metrics.display = false;
    }

    if (
      typeof analysisDefinition.allowedDimensions === "boolean" &&
      analysisDefinition.allowedDimensions === false
    ) {
      displaySettings.dimensions.display = false;
    }

    if (
      typeof analysisDefinition.allowedGranularity === "boolean" &&
      analysisDefinition.allowedGranularity === false
    ) {
      displaySettings.time.granularity.display = false;
    } else {
      if (typeof chartDefinition.allowedGranularities === "object") {
        displaySettings.time.granularity.allowed =
          chartDefinition.allowedGranularities;
      } else {
        displaySettings.time.granularity.allowed = [
          "hour",
          "day",
          "week",
          "month",
          "quarter",
          "year",
        ];
      }
    }

    if (typeof analysisDefinition.allowedMetrics === "number") {
      displaySettings.metrics.max = analysisDefinition.allowedMetrics;
    }

    if (typeof analysisDefinition.allowedDimensions === "number") {
      displaySettings.dimensions.max = analysisDefinition.allowedDimensions;
    }

    if (typeof chartDefinition.minMetrics === "number") {
      displaySettings.metrics.min = chartDefinition.minMetrics;
    }

    if (typeof chartDefinition.maxMetrics === "number") {
      displaySettings.metrics.max = chartDefinition.maxMetrics;
    }

    if (typeof chartDefinition.minDimensions === "number") {
      displaySettings.dimensions.min = chartDefinition.minDimensions;
    }

    if (typeof chartDefinition.maxDimensions === "number") {
      displaySettings.dimensions.max = chartDefinition.maxDimensions;
    }

    return displaySettings;
  };

  validateForm = (): QueryBuilderFormValidation => {
    const {
      selectedDimensions,
      selectedMeasures,
      selectedGranularity,
      selectedTime,
      filters,
      analysisType,
    } = this.state;
    const { availableMetrics, availableDimensions, availableTime } = this.props;
    const displaySettings = this.getDisplaySettings(this.state);
    const selectedAnalysis = AnalysisDefiniton.find(
      (a) => a.key === analysisType
    );

    const errors: QueryBuilderFormValidationError[] = [];

    // we check for deleted measures
    const usesDeletedDimension =
      selectedDimensions.length > 0 &&
      !selectedDimensions.every((sm) =>
        availableDimensions.find((ad) => ad.key === sm)
      );

    const usesDeletedDimensionInFilter = !validateUsedDimensioFilters(filters);

    const usesDeletedMetrics =
      selectedMeasures.length > 0 &&
      !selectedMeasures.every((sm) =>
        availableMetrics.find((am) => am.key === sm)
      );

    const usesDeletedTime =
      selectedTime && !availableTime.find((at) => at.key === selectedTime);

    const usesDeletedMeasure =
      usesDeletedDimension ||
      usesDeletedDimensionInFilter ||
      usesDeletedMetrics ||
      usesDeletedTime;

    if (usesDeletedMeasure) {
      errors.push({
        message: "Deleted measures should be removed from the query",
      });
    }

    if (
      typeof displaySettings.metrics.min === "number" &&
      typeof displaySettings.metrics.max === "number"
    ) {
      if (
        displaySettings.metrics.min === displaySettings.metrics.max &&
        selectedMeasures.length !== displaySettings.metrics.min
      ) {
        errors.push({
          field: "MEASURE",
          message: `Exactly ${displaySettings.metrics.min} measure${
            displaySettings.metrics.min > 1 ? "s" : ""
          } required`,
        });
      }
      if (
        displaySettings.metrics.min < displaySettings.metrics.max &&
        (selectedMeasures.length < displaySettings.metrics.min ||
          selectedMeasures.length > displaySettings.metrics.max)
      ) {
        errors.push({
          field: "MEASURE",
          message: `Between ${displaySettings.metrics.min} and ${
            displaySettings.metrics.max
          } measure${displaySettings.metrics.min > 1 ? "s" : ""} required`,
        });
      }
    } else if (
      typeof displaySettings.metrics.min === "number" &&
      !displaySettings.metrics.max
    ) {
      if (selectedMeasures.length < displaySettings.metrics.min) {
        errors.push({
          field: "MEASURE",
          message: `At least ${displaySettings.metrics.min} measure${
            displaySettings.metrics.min > 1 ? "s" : ""
          } required`,
        });
      }
    }

    if (
      typeof displaySettings.dimensions.min === "number" &&
      typeof displaySettings.dimensions.max === "number"
    ) {
      if (
        displaySettings.dimensions.min === displaySettings.dimensions.max &&
        selectedDimensions.length !== displaySettings.dimensions.min
      ) {
        errors.push({
          field: "DIMENSION",
          message: `Exactly ${displaySettings.dimensions.min} dimension${
            displaySettings.metrics.min > 1 ? "s" : ""
          } required`,
        });
      }
      if (
        displaySettings.dimensions.min < displaySettings.dimensions.max &&
        (selectedDimensions.length < displaySettings.dimensions.min ||
          selectedDimensions.length > displaySettings.dimensions.max)
      ) {
        errors.push({
          field: "DIMENSION",
          message: `Between ${displaySettings.dimensions.min} and ${
            displaySettings.dimensions.max
          } dimension${displaySettings.metrics.min > 1 ? "s" : ""} required`,
        });
      }
    } else if (
      typeof displaySettings.dimensions.min === "number" &&
      !displaySettings.dimensions.max
    ) {
      if (selectedDimensions.length < displaySettings.dimensions.min) {
        errors.push({
          field: "DIMENSION",
          message: `At least ${displaySettings.dimensions.min} dimension${
            displaySettings.metrics.min > 1 ? "s" : ""
          } required`,
        });
      }
    }

    const missingTime =
      selectedAnalysis.key === "TIME" &&
      (!selectedTime || !selectedGranularity);

    if (missingTime) {
      errors.push({
        field: "TIME",
        message: "Time dimension is required in timeseries",
      });
    }

    // We must check that all filters with operator different of
    //  NotSet and Set have non null values
    const filterValue = validateFilters(filters);
    if (!filterValue) {
      errors.push({ field: "FILTER", message: "Missing filter value" });
    }

    if (errors.length) {
      return {
        isValid: false,
        errors,
      };
    } else {
      return {
        isValid: true,
      };
    }
  };

  getDisabledAnalysis = (): Array<IAnalysisType> => {
    const { availableTime, availableMetrics } = this.props;
    const disabledAnalysis = [];
    AnalysisDefiniton.forEach((analysis) => {
      if (
        (analysis.allowedMetrics !== false && availableMetrics.length === 0) ||
        (analysis.allowedGranularity && availableTime.length === 0)
      ) {
        disabledAnalysis.push(analysis.key);
      }
    });
    return disabledAnalysis;
  };

  // this function will be triggered by refs using
  // the QueryBuilderSubmitButton component
  runQuery() {
    const { onRunQuery } = this.props;
    this.takeFormSnapshot();
    this.props.setStale(false);
    this.setState({
      explorationVersionOnLastRun: this.props.explorationVersion.value,
    });

    const options: QueryBuilderFormValue = {
      analysisType: this.state.analysisType,
      chartType: this.state.chartType,
      measures: this.state.selectedMeasures,
      dimensions: this.state.selectedDimensions,
      filters: this.state.filters,
      filterOperator: this.state.filterOperator,
      metricFilters: this.state.metricFilters,
      metricFilterOperator: this.state.metricFilterOperator,
      dateRange: this.state.dateRange,
      orderBy: this.state.orderBy,
      limit: this.state.limit,
      comparison: this.state.comparison,
      timeDimension: this.state.selectedTime,
      selectedGranularity: this.state.selectedGranularity,
      pivotConfig: this.state.pivotConfig,
      extra: this.state.extra,
      forecast: this.state.forecast,
    };
    return this.state.analysisType ? onRunQuery(options) : null;
  }

  bubbleFormData() {
    const { isStale, explorationVersion, loading, onFormUpdate } = this.props;
    const { explorationVersionOnLastRun } = this.state;

    if (onFormUpdate) {
      onFormUpdate({
        formValidation: this.validateForm(),
        form: {
          isStale: isStale,
          isLoading: loading,
          isExplorationVersionOutdated:
            explorationVersion.value !== explorationVersionOnLastRun,
        },
        value: {
          analysisType: this.state.analysisType,
          chartType: this.state.chartType,
          measures: this.state.selectedMeasures,
          dimensions: this.state.selectedDimensions,
          filters: this.state.filters,
          filterOperator: this.state.filterOperator,
          dateRange: this.state.dateRange,
          orderBy: this.state.orderBy,
          limit: this.state.limit,
          comparison: this.state.comparison,
          timeDimension: this.state.selectedTime,
          selectedGranularity: this.state.selectedGranularity,
          pivotConfig: this.state.pivotConfig,
          forecast: this.state.forecast,
          extra: this.state.extra,
          metricFilters: this.state.metricFilters,
          metricFilterOperator: this.state.metricFilterOperator,
        },
      });
    }
  }

  public render() {
    const {
      availableDimensions,
      availableMetrics,
      availableTime,
      autocomplete,
    } = this.props;

    const displaySettings = this.getDisplaySettings(this.state);

    const availableSortByMeasures: Array<AvailableDimension | AvailableMetric> =
      [];

    if (displaySettings.sort.allowMetrics) {
      availableSortByMeasures.push(
        ...this.state.selectedMeasures
          .map((sm) => availableMetrics.find((am) => am.key === sm))
          .filter((m) => m)
      );
    }

    if (displaySettings.sort.allowDimensions) {
      availableSortByMeasures.push(
        ...this.state.selectedDimensions
          .map((sm) => availableDimensions.find((am) => am.key === sm))
          .filter((m) => m)
      );
    }

    const formErrors = this.validateForm().errors
      ? this.validateForm().errors
      : [];

    const displaySectionTitle = (section: QueryBuilderFormFields) => {
      let title: string = null;
      if (section === "MEASURE") {
        title = "Measure";
      } else if (section === "DIMENSION") {
        title = "Group by";
        if (this.state.chartType === "interractive-pin-map") {
          title = "Location dimension";
        }
      } else if (section === "TIME") {
        title = "Using time";
      } else if (section === "FILTER") {
        title = "Filter";
      }

      const fieldErrors = formErrors
        .filter((e) => e.field === section)
        .map((e) => e.message);

      if (fieldErrors.length && this.isFormDirty()) {
        return (
          <Tooltip
            title={fieldErrors.map((m, i) => (
              <React.Fragment key={i}>
                {m}
                <br />
              </React.Fragment>
            ))}
            placement={"left"}
            mouseLeaveDelay={0}
          >
            <Space>
              {title}
              <div className="pulsing-dot" />
            </Space>
          </Tooltip>
        );
      } else {
        return title;
      }
    };

    const form: Array<{ element: React.ReactNode; position: number }> = [
      {
        element: (
          <div
            style={{
              padding: 8,
              borderTop: "1px solid #ececec",
              borderBottom: "1px solid #ececec",
            }}
          >
            <ChartSelector
              value={{
                analysisType: this.state.analysisType,
                chartType: this.state.chartType,
              }}
              onChange={(value) => {
                this.setState({
                  chartType: value.chartType,
                  analysisType: value.analysisType,
                });
              }}
              disabledAnalysis={this.getDisabledAnalysis()}
            />
          </div>
        ),
        position: 0,
      },
      {
        element: (
          <ChartOptionCollapse title={displaySectionTitle("MEASURE")}>
            <MeasureListSelector
              measures={availableMetrics}
              zone="metrics"
              accept={["metric"]}
              max={displaySettings.metrics.max}
              value={this.state.selectedMeasures}
              onChange={(m) => {
                this.setState({
                  selectedMeasures: [...m],
                });
              }}
            />
          </ChartOptionCollapse>
        ),
        position: 10,
      },
      {
        element: (
          <ChartOptionCollapse
            title={displaySectionTitle("TIME")}
            defaultExpanded={
              availableDimensions.length &&
              availableDimensions.filter((d) => d.domain === "TIME").length > 0
                ? true
                : false
            }
          >
            <Space style={{ width: "100%" }} direction="vertical">
              <MeasureSingleSelector
                measures={availableTime}
                type="dimension"
                zone="time_dimension"
                value={this.state.selectedTime}
                onChange={(v) => this.setState({ selectedTime: v })}
              />
              {displaySettings.time.granularity.display && (
                <ChartOptionLine
                  items={[
                    {
                      flex: 1,
                      content: "Granularity",
                    },
                    {
                      flex: 0,
                      content: (
                        <Select<TimeDimensionGranularity>
                          size="small"
                          disabled={!this.state.selectedTime}
                          defaultValue={
                            displaySettings.time.granularity.allowed?.includes?.(
                              "day"
                            )
                              ? "day"
                              : displaySettings.time.granularity.allowed?.[0]
                          }
                          value={this.state.selectedGranularity}
                          onChange={(v) =>
                            this.setState({
                              selectedGranularity: v,
                            })
                          }
                          popupMatchSelectWidth={false}
                        >
                          {displaySettings.time.granularity.allowed?.map?.(
                            (granularity, i) => {
                              return (
                                <Select.Option value={granularity} key={i}>
                                  {granularity[0].toUpperCase() +
                                    granularity.slice(1)}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      ),
                    },
                  ]}
                />
              )}
              {displaySettings.time.comparison.display && (
                <ChartOptionLine
                  items={[
                    {
                      flex: 1,
                      content: "Compare to",
                    },
                    {
                      flex: 1,
                      content: (
                        <ComparisonSelector
                          datesToCompare={convertWlyDatePickerValueToMoment(
                            this.state.dateRange
                          )}
                          value={this.state.comparison}
                          onChange={(c) => this.setState({ comparison: c })}
                          disabled={this.isTimeComparisonDisabled()}
                          size="small"
                        />
                      ),
                    },
                  ]}
                />
              )}
              <ChartOptionLine
                items={[
                  {
                    flex: "0 0 80px",
                    content: <div style={{ width: 80 }}>Time frame</div>,
                  },
                  {
                    flex: 1,
                    content: (
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <WlyDatePicker
                          value={this.state.dateRange}
                          onChange={(key) => this.setState({ dateRange: key })}
                          disabled={!this.state.selectedTime}
                          size="small"
                          block
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </Space>
          </ChartOptionCollapse>
        ),
        position: 20,
      },
      {
        element: displaySettings.dimensions.display && (
          <ChartOptionCollapse title={displaySectionTitle("DIMENSION")}>
            <MeasureListSelector
              measures={availableDimensions}
              zone="dimensions"
              accept={["dimension"]}
              max={displaySettings.dimensions.max}
              value={this.state.selectedDimensions}
              onChange={(m) => {
                this.setState({
                  selectedDimensions: [...m],
                });
              }}
            />
          </ChartOptionCollapse>
        ),
        position: 30,
      },

      {
        element: (
          <ChartOptionCollapse
            title={displaySectionTitle("FILTER")}
            defaultExpanded={this.state.filters.length === 0 ? false : true}
          >
            <QueryBuilderFilter
              availableDimensions={availableDimensions}
              autocomplete={autocomplete}
              filterOperator={this.state.filterOperator}
              filters={this.state.filters}
              setFilters={(filters) => this.setState({ filters })}
              setFilterOperator={(filterOperator) =>
                this.setState({ filterOperator })
              }
            />
          </ChartOptionCollapse>
        ),
        position: 40,
      },
      {
        element: this.state.analysisType !== "METRIC" && (
          <ChartOptionCollapse
            title={"Metric filter"}
            defaultExpanded={
              this.state.metricFilters.length === 0 ? false : true
            }
          >
            <QueryBuilderMetricFilter
              availableMetrics={availableMetrics}
              filterOperator={this.state.metricFilterOperator}
              filters={this.state.metricFilters}
              setFilters={(filters) =>
                this.setState({ metricFilters: filters })
              }
              setFilterOperator={(filterOperator) =>
                this.setState({ metricFilterOperator: filterOperator })
              }
            />
          </ChartOptionCollapse>
        ),
        position: 45,
      },
      {
        element: displaySettings.sort.display &&
          availableSortByMeasures.length > 0 && (
            <ChartOptionCollapse
              title="Sort"
              defaultExpanded={
                this.state.orderBy && this.state.orderBy.length > 0
                  ? true
                  : false
              }
            >
              <MeasureSort
                measures={availableSortByMeasures}
                value={this.state.orderBy}
                onChange={(v) => this.setState({ orderBy: v })}
              />
            </ChartOptionCollapse>
          ),
        position: 50,
      },
      {
        element: (
          <ChartOptionCollapse
            title={"Advanced"}
            defaultExpanded={
              this.state.limit !== 500 ||
              this.state.forecast.enabled ||
              this.state.pivotConfig?.length > 0
                ? true
                : false
            }
          >
            <Space style={{ width: "100%" }} direction="vertical">
              {displaySettings.pivot.display && (
                <ChartOptionLine
                  items={[
                    {
                      flex: 1,
                      content: "Pivot",
                    },
                    {
                      flex: 1,
                      content: (
                        <MeasureSingleSelector
                          measures={availableDimensions.filter((d) =>
                            this.state.selectedDimensions.includes(d.key)
                          )}
                          type="dimension"
                          zone="pivot"
                          value={
                            this.state.pivotConfig &&
                            this.state.pivotConfig.length > 0
                              ? this.state.pivotConfig[0]
                              : null
                          }
                          onChange={(v) => {
                            if (!v) {
                              this.setState({ pivotConfig: null });
                            } else {
                              this.setState({ pivotConfig: [v] });
                            }
                          }}
                        />
                      ),
                    },
                  ]}
                />
              )}
              {displaySettings.forecast.display && (
                <ChartOptionLine
                  items={[
                    {
                      flex: 1,
                      content: "Forecast",
                    },
                    {
                      flex: 0,
                      content: (
                        <SwitchIcons
                          icons={{
                            true: <EyeOutlined />,
                            false: <EyeInvisibleOutlined />,
                          }}
                          value={this.state.forecast.enabled}
                          onChange={(v) =>
                            this.setState({ forecast: { enabled: v } })
                          }
                        />
                      ),
                    },
                  ]}
                />
              )}
              <ChartOptionLine
                items={[
                  {
                    flex: 1,
                    content: "Row limit",
                  },
                  {
                    flex: 0,
                    content: (
                      <InputNumber
                        size={"small"}
                        min={1}
                        max={50000}
                        placeholder={DEFAULT_ROW_LIMIT.toString()}
                        value={this.state.limit}
                        onChange={(e) =>
                          this.setState({ limit: e ? e : undefined })
                        }
                      />
                    ),
                  },
                ]}
              />
            </Space>
          </ChartOptionCollapse>
        ),
        position: 60,
      },
      {
        element: displaySettings.extra.display &&
          ChartDefinition[this.state.chartType].queryExtra.includes(
            "pin-map-chart-label"
          ) && (
            <ChartOptionCollapse
              title={"Label dimension"}
              defaultExpanded={
                this.state.extra?.["pin-map-chart-label"]?.dimensions?.length >
                0
              }
            >
              <MeasureSingleSelector
                measures={availableDimensions.filter(
                  (d) => d.domain === "STRING" || d.domain === "NUMERIC"
                )}
                type="dimension"
                zone={null}
                value={
                  this.state.extra?.["pin-map-chart-label"]?.dimensions
                    ?.length > 0
                    ? this.state.extra?.["pin-map-chart-label"]?.dimensions[0]
                    : null
                }
                onChange={(v) => {
                  if (!v) {
                    this.setState({
                      extra: {
                        ...this.state.extra,
                        "pin-map-chart-label": { dimensions: null },
                      },
                    });
                  } else {
                    this.setState({
                      extra: {
                        ...this.state.extra,
                        "pin-map-chart-label": { dimensions: [v] },
                      },
                    });
                  }
                }}
              />
            </ChartOptionCollapse>
          ),
        position: 31,
      },
      {
        element: displaySettings.extra.display &&
          ChartDefinition[this.state.chartType].queryExtra.includes(
            "pin-map-color"
          ) && (
            <ChartOptionCollapse
              title={"Color dimension"}
              defaultExpanded={
                this.state.extra?.["pin-map-colo"]?.dimensions?.length > 0
              }
            >
              <MeasureSingleSelector
                measures={availableDimensions.filter(
                  (d) => d.domain === "STRING" || d.domain === "NUMERIC"
                )}
                type="dimension"
                zone={null}
                value={
                  this.state.extra?.["pin-map-color"]?.dimensions?.length > 0
                    ? this.state.extra?.["pin-map-color"]?.dimensions[0]
                    : null
                }
                onChange={(v) => {
                  if (!v) {
                    this.setState({
                      extra: {
                        ...this.state.extra,
                        "pin-map-color": { dimensions: null },
                      },
                    });
                  } else {
                    this.setState({
                      extra: {
                        ...this.state.extra,
                        "pin-map-color": { dimensions: [v] },
                      },
                    });
                  }
                }}
              />
            </ChartOptionCollapse>
          ),
        position: 32,
      },
    ];

    return (
      <div className="query-builder">
        {form
          .sort((a, b) => {
            if (a.position > b.position) return 1;
            if (b.position > a.position) return -1;
            return 0;
          })
          .map((f, i) => (
            <React.Fragment key={i}>{f.element}</React.Fragment>
          ))}
      </div>
    );
  }
}

export default QueryBuilder;
