import { ExpandAltOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { Base64 } from "js-base64";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { rationalizeFilters } from "../../../../../../components/measures/filter-item/domain";
import {
  ResizableDrawerBody,
  ResizableDrawerHeader,
} from "../../../../../../components/resizable/Drawer";
import type { IExploration } from "../../../../../../interfaces/explorations";
import type { IFilter, ITile } from "../../../../../../interfaces/reports";
import { IUserRoleType } from "../../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../../routes/routes";
import {
  applyFiltersToLagoonQuery,
  parseTileContent,
} from "../../../../../../services/tileService";
import ExplorationEditor from "../../../../../exploration/single/ExplorationEditor";
import type { ILagoonQuery } from "../../../../../exploration/single/domain";
import AddTileToQuestion from "../../../../../exploration/single/visualization/actions/AddTileToQuestion";
import AddTileToReport from "../../../../../exploration/single/visualization/actions/AddTileToReport";
import WithOrg from "../../../../../orgs/WithOrg";
import HasRoleAccess from "../../../../../user-settings/HasRoleAccess";
import type { FilterMapStore } from "../../../domain";

interface IChartExploreTileDrawerProps {
  setVisible: (b: boolean) => void;
  visible: boolean;
  tile: ITile;
  filterInfo?: {
    reportFilters: IFilter[];
    filters: FilterMapStore;
  };
  embededWorkbench?: boolean;
  currentReportId: string;
  onCancel?: (e: IExploration) => Promise<void>;
  onAddToReport?: () => Promise<void>;
}

type Props = IChartExploreTileDrawerProps & RouteComponentProps<{}>;

function ChartExploreTileDrawer(props: Props) {
  const {
    setVisible,
    tile,
    match: { params },
    filterInfo,
    history,
  } = props;

  let initialQuery: ILagoonQuery = {
    ...parseTileContent(tile.content),
    chartType: tile.chartType,
  };

  if (filterInfo) {
    const { dateRange, filters, filterOperator } = applyFiltersToLagoonQuery(
      initialQuery,
      filterInfo.reportFilters,
      filterInfo.filters,
      tile.id
    );
    // we have to do this for some reason :(
    initialQuery.dateRange = JSON.stringify(dateRange) as any;
    initialQuery.filterOperator = filterOperator;
    initialQuery.filters = rationalizeFilters(filters) as any;
  }

  const [currentQuery, setCurrentQuery] = React.useState<
    ILagoonQuery | undefined
  >({ ...initialQuery });
  const [isStale, setIsStale] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string | undefined>();
  const [addNewTileToReport, setAddNewTileToReport] =
    React.useState<boolean>(false);
  const [addNewTileToQuestion, setAddNewTileToQuestion] =
    React.useState<boolean>(false);

  return (
    <>
      <ResizableDrawerHeader
        title={!title ? "Loading..." : title}
        extra={
          <Space>
            <Button
              type="text"
              target="_blank"
              icon={<ExpandAltOutlined />}
              onClick={() => {
                history.push(
                  routeDescriptor["explore"].renderRoute(
                    {
                      ...props.match.params,
                      explorationSlug: tile.exploration.slug,
                    },
                    {
                      query: Base64.encode(JSON.stringify(currentQuery)),
                      loadQuery: true,
                    }
                  )
                );
              }}
              rel="noreferrer"
            >
              Open in a new tab
            </Button>
            {props.onAddToReport && (
              <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
                <Space>
                  <Button
                    onClick={() => setAddNewTileToReport(true)}
                    disabled={isStale}
                    style={{ marginRight: 6 }}
                    type="primary"
                  >
                    Add to report
                  </Button>
                  <Button
                    onClick={() => setAddNewTileToQuestion(true)}
                    disabled={isStale}
                    type="primary"
                  >
                    Save as a Question
                  </Button>
                </Space>
              </HasRoleAccess>
            )}
          </Space>
        }
        onClose={async () => {
          if (props.onCancel) {
            await props.onCancel(tile.exploration);
          }
          setVisible(false);
        }}
      />
      <ResizableDrawerBody>
        <ExplorationEditor
          isEmbeded={true}
          liftExploration={(e) => setTitle(`Exploring: ${e.name}`)}
          previousRoute={routeDescriptor["reportExploration"].renderRoute({
            ...params,
          })}
          injectedExplorationSlug={tile.exploration.slug}
          initialQuery={initialQuery}
          getCurrentQuery={(
            isStale,
            analysisType,
            chartType,
            measures,
            dimensions,
            filters,
            filterOperator,
            dateRange,
            orderBy,
            limit,
            comparison,
            timeDimension,
            selectedGranularity,
            pivotConfig,
            chartOptions,
            forecast,
            extra,
            metricFilters,
            metricFilterOperator
          ) => {
            setIsStale(isStale);
            setCurrentQuery({
              selectedMeasures: measures,
              selectedDimensions: dimensions,
              filters: filters as any,
              filterOperator: filterOperator,
              selectedGranularity: selectedGranularity,
              dateRange: dateRange,
              comparison: comparison,
              selectedTime: timeDimension,
              analysisType: analysisType,
              orderBy: orderBy,
              limit: limit,
              pivotDimensions: pivotConfig,
              chartOptions: chartOptions,
              chartType: chartType,
              metricFilterOperator: metricFilterOperator,
              metricFilters: metricFilters,
              forecast: forecast,
              extra: extra,
            });
          }}
          initialQueryLoad={true}
        />
      </ResizableDrawerBody>
      {currentQuery && currentQuery.chartType && (
        <AddTileToReport
          chartType={currentQuery.chartType}
          analysisType={currentQuery.analysisType}
          explorationId={tile.exploration.id}
          visible={addNewTileToReport}
          query={currentQuery!}
          onCancel={() => setAddNewTileToReport(false)}
          afterSave={props.onAddToReport}
          currentReportId={props.currentReportId}
        />
      )}
      {currentQuery && currentQuery.chartType && (
        <AddTileToQuestion
          chartType={currentQuery.chartType}
          analysisType={currentQuery.analysisType}
          explorationId={tile.exploration.id}
          visible={addNewTileToQuestion}
          query={currentQuery!}
          onCancel={() => setAddNewTileToQuestion(false)}
          afterSave={props.onAddToReport}
          currentReportId={props.currentReportId}
        />
      )}
    </>
  );
}

export default compose<Props, IChartExploreTileDrawerProps>(
  withRouter,
  WithOrg
)(ChartExploreTileDrawer);
