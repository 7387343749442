import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import _ from "lodash";
import * as React from "react";
import {
  ScrollMenu,
  VisibilityContext,
  type publicApiType,
} from "react-horizontal-scrolling-menu";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import {
  type IObject,
  type IObjectRegisteredAction,
} from "../../../../../../../../interfaces/object";
import {
  IUserFeatureType,
  type IUser,
} from "../../../../../../../../interfaces/user";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import {
  getObjectColumns,
  isAvailableProperty,
} from "../../../../../object/domain";
import { type IRecord } from "../../../../domain";
import { type IWidgetParsedConfig } from "../domain";
import { ProductSuggestionCard } from "./product/ProductSuggestionCard";
import ProductSuggestionDetail from "./product/ProductSuggestionDetail";

export interface ISuggestedCategoryRendererProps {
  category: string;
  showTitle: boolean;
  data: { [key: string]: any[] };
  object: IObject;
  action?: IObjectRegisteredAction;
  user: IUser;
  conf: IWidgetParsedConfig;
  parentContext: string;
  reload?: () => void;
}

function LeftArrow() {
  const { scrollPrev, useIsVisible } =
    React.useContext<publicApiType>(VisibilityContext);
  const isFirstItemVisible = useIsVisible("first", false);

  return (
    <Button
      shape="circle"
      size="large"
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      icon={<LeftOutlined style={{ fontSize: 16 }} />}
      style={{
        transform: "scale(0.7)",
        translate: -3,
        marginTop: -2,
      }}
    />
  );
}

function RightArrow() {
  const { scrollNext, useIsVisible } =
    React.useContext<publicApiType>(VisibilityContext);
  const isLastItemVisible = useIsVisible("last", false);

  return (
    <Button
      shape="circle"
      size="large"
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      icon={<RightOutlined style={{ fontSize: 16 }} />}
      style={{
        transform: "scale(0.7)",
        translate: 3,
        marginTop: -2,
      }}
    />
  );
}

type Props = ISuggestedCategoryRendererProps & InjectedOrgProps;

function SuggestedCategoryRenderer(props: Props) {
  const {
    category,
    data,
    conf,
    object,
    user,
    action,
    userFeatures,
    parentContext,
    showTitle,
    reload,
  } = props;

  const [selectedRecord, setSelectedRecord] = React.useState<IRecord | null>(
    null
  );

  const columns = getObjectColumns(object);

  const categoryColumn = columns
    .filter(isAvailableProperty)
    .find((c) => c.data.key === conf.categoryKey);

  const categoryLabel =
    categoryColumn?.data.type === "primaryKey" ||
    categoryColumn?.data.type === "foreignKey"
      ? category?.split("||")[1]
      : category;

  const categoryData = data[category];

  const onSelect = (r: IRecord | null) => {
    if (userFeatures.includes(IUserFeatureType.TMP_OBJECT_DOCUMENTS)) {
      setSelectedRecord(r);
    }
  };

  return (
    <div key={category} style={{ paddingBottom: 24 }}>
      {showTitle && (
        <Typography.Title level={5}>
          {categoryLabel}{" "}
          <Typography.Text type="secondary">
            ({categoryData?.length})
          </Typography.Text>
        </Typography.Title>
      )}
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {(categoryData || []).map((p, i) => {
          return (
            <ProductSuggestionCard
              key={i}
              user={user}
              object={object}
              data={p}
              conf={conf}
              action={action}
              columns={columns}
              onSelect={onSelect}
              selected={!!selectedRecord && !!_.isEqual(p, selectedRecord)}
              parentContext={parentContext}
              reload={reload}
            />
          );
        })}
      </ScrollMenu>
      {selectedRecord && (
        <ProductSuggestionDetail
          object={object}
          data={selectedRecord}
          conf={conf}
          action={action}
          columns={columns}
          onSelect={() => setSelectedRecord(null)}
          user={user}
        />
      )}
    </div>
  );
}

export default compose<Props, ISuggestedCategoryRendererProps>(WithOrg)(
  SuggestedCategoryRenderer
);
