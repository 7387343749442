import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import type {
  BinaryFilter,
  BinaryOperator,
  Filter,
  Query,
} from "@cubejs-client/core";
import type { UnaryFilter } from "@cubejs-client/core-next";
import { Button, Flex, Space, Typography } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import useToken from "antd/es/theme/useToken";
import _ from "lodash";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { AvailableDimension } from "../../../../../../components/measures/filter-item/FilterItem";
import type { IObject } from "../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../services/LagoonService";
import type { FilterOperator } from "../../../../../exploration/single/domain";
import { QueryBuilderFilter } from "../../../../../exploration/single/visualization/query-builder/query-builder-items/QueryBuilderFilter";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { getObjectPresetFilters } from "../../domain";

interface IObjectToolbarFilterProps {
  presetValue: string[];
  onPresetChange: (v: string[]) => void;
  availableDimensions: AvailableDimension[];
  object: IObject;
  value: Filter[];
  onChange: (value: Filter[]) => void;
  overrideName?: string;
}

type Props = IObjectToolbarFilterProps & InjectedOrgProps;

export const getFilterLength = (value: Filter[], presetValue: string[]) => {
  const filterOperator = (
    typeof value?.[0] === "object" ? Object.keys(value[0])[0] : "and"
  ) as FilterOperator;
  const filters = (value?.[0]?.[filterOperator] ?? []) as (
    | BinaryFilter
    | UnaryFilter
  )[];
  const length = (filters.length || 0) + presetValue.length;
  return length;
};

function ObjectToolbarFilter(props: Props) {
  const {
    presetValue,
    onPresetChange,
    availableDimensions,
    object,
    value,
    org,
    onChange,
    overrideName,
  } = props;
  const [, token] = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const filterOperator = (
    typeof value?.[0] === "object" ? Object.keys(value[0])[0] : "and"
  ) as FilterOperator;

  const filters = (value?.[0]?.[filterOperator] ?? []) as (
    | BinaryFilter
    | UnaryFilter
  )[];

  const presets = getObjectPresetFilters(object);

  const onFiltersChange = (filters) => {
    onChange([{ [filterOperator]: filters }] as Filter[]);
  };

  const onFilterOperatorChange = (fo) => {
    onChange([{ [fo]: filters }] as Filter[]);
  };

  const getFilterSentence = () => {
    const length = getFilterLength(value, presetValue);
    if (!length) return overrideName ? overrideName : "Filter";
    return (
      <Space>
        <span>
          {overrideName ? overrideName : "Filters"}: {length}
        </span>
        <CloseOutlined
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChange([{ [filterOperator]: [] } as unknown as Filter]);
            onPresetChange([]);
          }}
        />
      </Space>
    );
  };

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => {
        return (
          <div
            className="wlydatepicker-custom-menu"
            style={{
              ...contentStyle,
            }}
          >
            <div
              style={{
                minWidth: 500,
                margin: "4px 12px",
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              <Flex style={{ marginBottom: 4 }}>
                <div style={{ flex: 1 }}>
                  <Typography.Text strong>Filters</Typography.Text>
                </div>
                <Button
                  type="text"
                  size="small"
                  onClick={() => {
                    onChange([{ [filterOperator]: [] } as unknown as Filter]);
                    onPresetChange([]);
                  }}
                >
                  Clear
                </Button>
              </Flex>

              {presets.length > 0 && (
                <>
                  <Space
                    size={12}
                    direction="vertical"
                    style={{ display: "flex" }}
                  >
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      Preset filters
                    </Typography.Text>
                    <Space wrap>
                      {presets.map((pf) => {
                        const isActive: boolean = presetValue.includes(pf.id);
                        return (
                          <Button
                            key={pf.id}
                            type="text"
                            size="small"
                            shape="round"
                            style={
                              isActive
                                ? {
                                    border: "1px solid #DBE0FD",
                                    background: "#DBE0FD",
                                    color: "#3f6ac4",
                                  }
                                : { border: "1px solid #D9D9D9" }
                            }
                            onClick={() => {
                              if (isActive) {
                                onPresetChange(
                                  presetValue.filter((v) => v !== pf.id)
                                );
                              } else {
                                onPresetChange(_.uniq([...presetValue, pf.id]));
                              }
                            }}
                          >
                            {pf.name}
                          </Button>
                        );
                      })}
                    </Space>
                    <div style={{ height: 12 }} />
                  </Space>
                </>
              )}

              {presets.length > 0 && (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  Advanced filters
                </Typography.Text>
              )}
              <QueryBuilderFilter
                availableDimensions={availableDimensions}
                autocomplete={async (
                  dimension: string,
                  operator?: BinaryOperator,
                  value?: string
                ): Promise<string[]> => {
                  let query: Query = {
                    dimensions: [dimension],
                    limit: 50,
                    filters: [
                      {
                        member: dimension,
                        operator: "set",
                      },
                    ],
                  };
                  if (typeof value === "string" && value !== "" && operator) {
                    query = {
                      dimensions: [dimension],
                      limit: 50,
                      filters: [
                        {
                          member: dimension,
                          operator: operator,
                          values: [value],
                        },
                      ],
                    };
                  }
                  return lagoonServiceLoad(
                    org.id,
                    query,
                    "OBJECT",
                    object.id,
                    object.id,
                    LagoonCallOrigin.WHALY_APP,
                    undefined,
                    undefined,
                    false
                  )
                    .then((r) => {
                      return r.tablePivot();
                    })
                    .then((r) => {
                      return (r || []).map((d) => d[dimension] as string);
                    });
                }}
                filterOperator={filterOperator as FilterOperator}
                filters={filters}
                setFilters={onFiltersChange}
                setFilterOperator={onFilterOperatorChange}
                newFilterOperator="equals"
              />
            </div>
          </div>
        );
      }}
    >
      <div>
        <Button
          size="small"
          type="text"
          icon={<FilterOutlined />}
          style={
            filters?.length || presetValue.length
              ? {
                  background: "#DBE0FD",
                  color: "#3f6ac4",
                }
              : undefined
          }
        >
          {getFilterSentence()}
        </Button>
      </div>
    </Dropdown>
  );
}

export default compose<Props, IObjectToolbarFilterProps>(WithOrg)(
  ObjectToolbarFilter
);
