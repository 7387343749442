import { CloseOutlined, FullscreenOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Modal,
  Skeleton,
  Space,
  Typography,
} from "antd";
import _ from "lodash";
import { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import { routeDescriptor } from "../../../../../routes/routes";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import RecordView from "../../record/RecordView";
import { EmbedType } from "../../record/domain";
import "./RecordModal.scss";
import type { IRecordModalContext } from "./RecordModalContext";
import { RecordModalContext } from "./RecordModalContext";

interface IRecordModalProps {
  open: boolean;
  onClose: () => void;
}

type Props = IRecordModalProps &
  InjectedOrgProps &
  RouteComponentProps<{ layoutId?: string }>;

function RecordModal(props: Props) {
  const {
    history,
    match: { params },
    open,
    org,
    onClose,
  } = props;

  // const [opacity, setOpacity] = React.useState<number>(0);
  const [modalContext, setModalContext] = useState<
    IRecordModalContext | undefined
  >(undefined);

  const context = {
    value: modalContext,
    setValue: (v: IRecordModalContext) =>
      !_.isEqual(v, modalContext) ? setModalContext(v) : undefined,
  };

  // React.useEffect(() => {
  //   const getElement = () => {
  //     const el = document.querySelectorAll(".record-modal .record-body");
  //     if (el && el.length) {
  //       return el[0];
  //     }
  //   };
  //   const el = getElement() || document;
  //   const onSroll = () => {
  //     const el = document.querySelectorAll(
  //       ".record-modal .record-body .record-view-header "
  //     );
  //     if (el && el.length) {
  //       const l = el[0];
  //       const dr = l.getBoundingClientRect();

  //       if (dr.top > 44) {
  //         return setOpacity(0);
  //       }
  //       const percentage = Math.abs((dr.top - 44) / dr.height);
  //       if (percentage > 1) {
  //         return setOpacity(1);
  //       }

  //       return setOpacity(percentage);
  //     }
  //   };
  //   el.addEventListener("scroll", onSroll);
  //   return () => el.removeEventListener("scroll", onSroll);
  // }, []);

  const onFullScreen = () => {
    history.replace(
      routeDescriptor["object-record"].renderRoute({
        organizationSlug: org.slug,
        ...params,
      })
    );
  };

  const renderModalHeader = () => {
    if (
      !modalContext ||
      modalContext?.status === "initial" ||
      modalContext.status === "loading"
    ) {
      return (
        <div className="record-title">
          <div className="record-title-content">
            <div
              style={{ display: "flex", gap: 8, alignItems: "center" }}
              key="1"
            >
              <div style={{ flex: `0 24px` }}>
                <Skeleton.Button
                  shape="circle"
                  size="small"
                  active
                  style={{ width: 22 }}
                />
              </div>
              <div style={{ flex: `1` }}>
                <Skeleton.Button
                  shape="round"
                  size="small"
                  active
                  style={{ width: 75 }}
                />
              </div>
            </div>
          </div>
          <div className="record-title-action">
            <Space>
              {/* <Button
                  onClick={onFullScreen}
                  icon={<FolderOutlined style={{ color: "gray" }} />}
                  type="text"
                  shape="circle"
                  size="small"
                />
                <Divider type="vertical" /> */}
              <Button
                onClick={onFullScreen}
                icon={<FullscreenOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
              <Button
                onClick={onClose}
                icon={<CloseOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
            </Space>
          </div>
        </div>
      );
    }

    if (modalContext.status === "error") {
      return (
        <div className="record-title">
          <div className="record-title-content">
            <div
              style={{ display: "flex", gap: 8, alignItems: "center" }}
              key="1"
            >
              <div style={{ flex: `1` }}>
                <Typography.Text type="danger">
                  Error while fetching your record
                </Typography.Text>
              </div>
            </div>
          </div>
          <div className="record-title-action">
            <Space>
              {/* <Button
                  onClick={onFullScreen}
                  icon={<FolderOutlined style={{ color: "gray" }} />}
                  type="text"
                  shape="circle"
                  size="small"
                />
                <Divider type="vertical" /> */}
              <Button
                onClick={onFullScreen}
                icon={<FullscreenOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
              <Button
                onClick={onClose}
                icon={<CloseOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
            </Space>
          </div>
        </div>
      );
    }

    const data = modalContext.data;

    return (
      <div className="record-title">
        <div className="record-title-content">
          {data && (
            <div
              style={{ display: "flex", gap: 8, alignItems: "center" }}
              key="1"
            >
              {data?.image ? (
                <div style={{ flex: `0 24px` }}>
                  <Avatar size={24} src={data?.image} />
                </div>
              ) : null}
              <div style={{ flex: `1` }}>
                <Typography.Text strong>
                  {data?.name ? data?.name : data?.id}
                </Typography.Text>
              </div>
            </div>
          )}
        </div>
        <div className="record-title-action">
          <Space>
            {data.additionalButtons && data.additionalButtons?.length
              ? [
                  ...data.additionalButtons,
                  <Divider key="div" type="vertical" />,
                ]
              : null}
            <Button
              onClick={onFullScreen}
              icon={<FullscreenOutlined style={{ color: "gray" }} />}
              type="text"
              shape="circle"
              size="small"
            />
            <Button
              onClick={onClose}
              icon={<CloseOutlined style={{ color: "gray" }} />}
              type="text"
              shape="circle"
              size="small"
            />
          </Space>
        </div>
      </div>
    );
  };

  const layoutId = params.layoutId;

  return (
    <RecordModalContext.Provider value={context}>
      <Modal
        maskClosable={false}
        closable={false}
        width={"90%"}
        centered
        className="record-modal"
        destroyOnClose={true}
        styles={{
          body: {
            height: "100%",
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        onCancel={onClose}
        footer={null}
        open={open}
      >
        {renderModalHeader()}
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            maxHeight: "calc(100% - 44px)",
            height: "100%",
          }}
        >
          {/* <div
            className="record-panel whly-sub-menu"
            style={{
              borderRight: `1px solid ${BORDER_COLOR}`,
            }}
          >
            <SubMenuItem
              displayName="Informations"
              selected={layoutId === "72"}
              onClick={() =>
                history.push(`/danone/object-centered/stores/3/72`)
              }
            />
            <ClosableContent
              name={"Rayons"}
              avoidClosing={false}
              items={[
                {
                  label: (
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <div>
                          <Typography.Text>Ultra frais</Typography.Text>
                        </div>
                      </div>
                      <div style={{ flex: `0 auto` }}>
                        <div
                          style={{
                            height: 26,
                            backgroundColor: "#FFE8EE",
                            display: "flex",
                            gap: 8,
                            padding: `0 12px`,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 16,
                            color: "#FE779D",
                          }}
                        >
                          <div>
                            <Typography.Text>8.5</Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  isSelected: layoutId === "73",
                  route: "/danone/object-centered/stores/3/73",
                },
                {
                  label: (
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <div>
                          <Typography.Text>Boisson sans alcool</Typography.Text>
                        </div>
                      </div>
                      <div style={{ flex: `0 auto` }}>
                        <div
                          style={{
                            height: 26,
                            backgroundColor: "#FEFAEC",
                            display: "flex",
                            gap: 8,
                            padding: `0 12px`,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 16,
                            color: "#FDD15E",
                          }}
                        >
                          <div>
                            <Typography.Text>7.5</Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  isSelected: layoutId === "74",
                  route: "/danone/object-centered/stores/3/74",
                },
                {
                  label: (
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <div>
                          <Typography.Text>Boisson végétale</Typography.Text>
                        </div>
                      </div>
                      <div style={{ flex: `0 auto` }}>
                        <div
                          style={{
                            height: 26,
                            backgroundColor: "#FEFAEC",
                            display: "flex",
                            gap: 8,
                            padding: `0 12px`,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 16,
                            color: "#FDD15E",
                          }}
                        >
                          <div>
                            <Typography.Text>6.5</Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  isSelected: layoutId === "75",
                  route: "/danone/object-centered/stores/3/75",
                },
                {
                  label: (
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <div>
                          <Typography.Text>
                            Alimentation Infantile
                          </Typography.Text>
                        </div>
                      </div>
                      <div style={{ flex: `0 auto` }}>
                        <div
                          style={{
                            height: 26,
                            backgroundColor: "#E6F8E8",
                            display: "flex",
                            gap: 8,
                            padding: `0 12px`,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 16,
                            color: "#7EE786",
                          }}
                        >
                          <div>
                            <Typography.Text>4.5</Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  isSelected: layoutId === "76",
                  route: "/danone/object-centered/stores/3/76",
                },
              ].map((i, key) => {
                return {
                  key: key.toString(),
                  label: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: 4,
                        paddingBottom: 4,
                      }}
                    >
                      {i.label}
                    </div>
                  ),
                  selected: i.isSelected as boolean,
                  onClick: () => history.push(i.route),
                };
              })}
            />
            <ClosableContent
              name={"Magasin à visiter le même jour"}
              avoidClosing={false}
              items={[
                {
                  label: "ITM SM VULBENS",
                  subLabel: "UF",
                  logo: "https://cdn.whaly.io/retail-demo/intermarche.webp",
                  rating: 8.4,
                  isSelected: false,
                  route: "/danone/object-centered/stores/20/73",
                },
                {
                  label: "CRF MARKET AMANCY",
                  subLabel: "BSA",
                  logo: "https://cdn.whaly.io/retail-demo/carrefour.png",
                  rating: 7.3,
                  isSelected: false,
                  route: "/danone/object-centered/stores/6/74",
                },
                {
                  label: "MIGROS ETREMBIERES",
                  subLabel: "BSA",
                  logo: "https://cdn.whaly.io/retail-demo/cora.png",
                  rating: 6.3,
                  isSelected: false,
                  route: "/danone/object-centered/stores/22/74",
                },
              ].map((i, key) => {
                return {
                  key: key.toString(),
                  label: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 12,
                      }}
                    >
                      <div style={{ flex: `0 auto` }}>
                        <img
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                          }}
                          src={i.logo}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <div>
                          <Typography.Text>{i.label}</Typography.Text>
                        </div>
                      </div>
                      <div style={{ flex: `0 auto` }}>
                        <div
                          style={{
                            height: 26,
                            backgroundColor:
                              i.rating > 8 ? "#FFE8EE" : "#FEFAEC",
                            display: "flex",
                            gap: 8,
                            padding: `0 12px`,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 16,
                          }}
                        >
                          <div>
                            <Typography.Text>{i.rating}</Typography.Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  selected: i.isSelected,
                  onClick: () => history.push(i.route),
                };
              })}
            />
          </div> */}
          <div style={{ flex: 1, minWidth: 0 }} className="record-body">
            <RecordView
              homePageConfig={layoutId ? { layoutId: layoutId } : undefined}
              embedType={EmbedType.recordModal}
            />
          </div>
          {modalContext &&
          modalContext.status === "success" &&
          modalContext.data.recommendations &&
          modalContext.data.recommendations.length ? (
            <div className="record-panel" id="record-panel-modal-context">
              {modalContext.data.recommendations}
            </div>
          ) : null}
        </div>
      </Modal>
    </RecordModalContext.Provider>
  );
}

export default compose<Props, IRecordModalProps>(
  withRouter,
  WithOrg
)(RecordModal);
