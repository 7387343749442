import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import * as React from "react";
import type { ITextDataSheet } from "./interfaces";
import { MarkdocEditor } from "./editor/MarkdocEditor";
import type { AvailableColumn } from "../../../../object/domain";
import { convertDataSheetNameToMarkdocVariable, convertKeyToMarkdocVariable } from "./domain";

export interface IMarkdocFormItemProps {
  datasheets?: ITextDataSheet[];
  columns?: AvailableColumn[];
  fieldName?: string[] | string;
}

export function MarkdocFormItem(props: IMarkdocFormItemProps) {
  const { datasheets, fieldName, columns } = props;
  const [openPromptModal, setPromptModalOpen] = React.useState<boolean>(false);
  return (
    <>
      <Form.Item noStyle hidden name={fieldName} />
      <Button
        onClick={() => setPromptModalOpen(true)}
        size="small"
        icon={<EditOutlined />}
      >
        Edit
      </Button>
      <Modal
        open={openPromptModal}
        onCancel={() => setPromptModalOpen(false)}
        destroyOnClose
        maskClosable={false}
        className="markdown-modal"
        title={"Edit prompt"}
        width={"50%"}
        okText={"Save"}
        onOk={() => {
          setPromptModalOpen(false);
        }}
      >
        <Form.Item noStyle name={fieldName}>
          <MarkdocEditor
            columnsSuggestions={(columns || []).map((c) => ({
              key: `$${convertKeyToMarkdocVariable(c.data.key)}`,
              label: c.data.label,
            }))}
            datasheetsSuggestions={(datasheets || []).map((d) => ({
              key: `$${convertDataSheetNameToMarkdocVariable(d.id)}`,
              label: d.name,
            }))}
          />
        </Form.Item>
      </Modal>
    </>
  );
}
