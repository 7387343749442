import { Typography } from "antd";
import { getObjectColumns } from "../../../../../object/domain";
import { shouldDisplayWidget } from "../../../common/visibility-filters/domain";
import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetPropertyListConfig } from "../domain";
import { getDefaultConfig, renderColors, renderValue } from "../domain";

interface IEmailWidgetPropertyListRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetPropertyListConfig> {}

export function EmailWidgetPropertyListRenderer(
  props: IEmailWidgetPropertyListRendererProps
) {
  const { conf, record, object, user } = props;

  const objectColumns = getObjectColumns(object);
  const defaults = getDefaultConfig(conf, record);

  const renderAlign = () => {
    if (defaults.align === "center") {
      return defaults.align;
    }
    if (defaults.align === "right") {
      return "flex-end";
    }
    return "flex-start";
  };

  if (!defaults.columns.length) {
    return (
      <div
        style={{
          height: 32,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Text>Please configure this widget</Typography.Text>
      </div>
    );
  }

  const renderProperty = (p: string) => {
    const foundColumn = objectColumns.find((oc) => oc.data.key === p);
    if (!foundColumn) {
      return (
        <div>
          <Typography.Text type="danger">
            Property does not exist
          </Typography.Text>
        </div>
      );
    }

    const getStatus = () => {
      return "normal";
    };

    const color = renderColors(getStatus() as any);

    return (
      <div
        style={{
          padding: `6px 12px`,
          color: color.fontColor,
          backgroundColor: color.bgColor,
          borderRadius: 16,
          height: 32,
        }}
      >
        {renderValue(foundColumn, conf, record, user.locale)}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: renderAlign(),
        flexWrap: "wrap",
        gap: 8,
      }}
    >
      {defaults.columns
        .map((c) => {
          return objectColumns.find((a) => a.data.key === c);
        })
        .filter((r) => !!r)
        .filter((r) => {
          const currentConf =
            conf.options?.[r!.data.key.replaceAll(".", "_")]?.displayFilter;
          if (!currentConf) {
            return true;
          }
          return shouldDisplayWidget(currentConf, record, object, {});
        })
        .map((c) => renderProperty(c!.data.key))}
    </div>
  );
}
