import type { Moment } from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IReport } from "../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../routes/routes";
import type { Store } from "../../chart/card/ChartCard";
import type { ActionType, DataMapStore } from "../../domain";
import QuestionContent from "./QuestionContent";
import QuestionHeader from "./QuestionHeader";

interface IQuestionWrapperProps {
  hideLayout?: boolean;
  editing?: boolean;
  report: IReport;
  fetched?: Moment;
  forceCacheRefresh: (force: boolean) => Promise<any>;
  explorations: IExploration[];
  actionType?: ActionType;
  reloadReport: (reportSlug: string, orgId) => Promise<any>;
  embededWorkbench?: boolean;
  saving: (saving: boolean) => void;
  setExplorations: (e: IExploration[]) => void;
  setDataStoreValue: (key: string, value: Store) => void;
  dataStore: DataMapStore;
  setReport: (r: IReport) => void;
  refreshReport: () => any;
  onOpenConsole?: (c?: string) => void;
  disableDrills?: boolean;
  isDisplayedInWorkspace?: boolean;
}

type Props = IQuestionWrapperProps &
  RouteComponentProps<{
    organizationSlug: string;
    reportSlug: string;
    tileSlug?: string;
    explorationSlug?: string;
  }>;

class QuestionWrapper extends React.Component<Props> {
  public render() {
    const {
      editing,
      hideLayout,
      report,
      fetched,
      forceCacheRefresh,
      explorations,
      actionType,
      refreshReport,
      embededWorkbench,
      saving,
      setExplorations,
      reloadReport,
      setDataStoreValue,
      dataStore,
      setReport,
      history,
      match: { params },
      onOpenConsole,
      disableDrills,
      isDisplayedInWorkspace,
    } = this.props;
    return (
      <>
        <QuestionHeader
          hideLayout={hideLayout}
          editing={editing}
          report={report}
          onEditClick={() => {
            if (report.tiles.length && report.tiles[0]) {
              history.push(
                routeDescriptor.reportTileExplorationEdit.renderRoute({
                  ...params,
                  tileSlug: report.tiles[0].slug,
                  explorationSlug: report.tiles[0].exploration.slug,
                })
              );
            }
          }}
          forceCacheRefresh={forceCacheRefresh}
          onOpenConsole={onOpenConsole}
          isDisplayedInWorkspace={isDisplayedInWorkspace}
        />
        <QuestionContent
          report={report}
          editing={!!editing}
          actionType={actionType}
          refreshReport={refreshReport}
          embededWorkbench={embededWorkbench}
          saving={saving}
          explorations={explorations}
          setExplorations={setExplorations}
          reloadReport={reloadReport}
          setDataStoreValue={setDataStoreValue}
          dataStore={dataStore}
          setReport={setReport}
          disableDrills={disableDrills}
          isDisplayedInWorkspace={isDisplayedInWorkspace}
          forceCacheRefresh={forceCacheRefresh}
          onOpenConsole={onOpenConsole}
        />
      </>
    );
  }
}

export default compose<Props, IQuestionWrapperProps>(withRouter)(
  QuestionWrapper
);
