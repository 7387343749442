import { type MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { ITextDataSheet } from "../../common/markdoc/interfaces";
import { type BaseConfig } from "../domain";
import type { IFilterEditorValue } from "../related-lists/domain";

export interface IGeneratedTextConfig extends BaseConfig {
  model: string;
  prompt: string;
  dataSheets: Array<ITextDataSheet>;
}

export const substitutionColumnPrefix = `_wly:`;
