import type {
  Completion,
  CompletionContext,
  CompletionSource,
} from "@codemirror/autocomplete";
import { autocompletion } from "@codemirror/autocomplete";

export type FormulaEditorColumnAutocompleteValue = {
  label: string;
  key: string;
  highlightColor?: "green" | "lightblue" | "purple";
  detail?: string;
  section?: string;
};

const formatColumnsSuggestions = (
  suggestions: FormulaEditorColumnAutocompleteValue[]
): Completion[] => {
  const options = suggestions.map<Completion>((v) => ({
    label: v.label,
    apply: v.key,
    detail: v.section,
  }));
  return options;
};

export function CalculatedMetricFormulaEditorAutocomplete(
  availableColumns: FormulaEditorColumnAutocompleteValue[]
) {
  const columnSuggestions: CompletionSource = (context: CompletionContext) => {
    let word = context.matchBefore(/\w*/);
    if (word.from === word.to && !context.explicit) return null;

    return {
      from: word.from,
      options: formatColumnsSuggestions(availableColumns),
    };
  };

  return autocompletion({
    override: [columnSuggestions],
  });
}
