import { Tag, type RenderableTreeNode } from "@markdoc/markdoc";

export const floatingMenu = {
  render: "FloatingMenu",
  children: [
    "paragraph",
    "tag",
    "list",
    "strong",
    "italic",
    "link",
    "em",
    "image",
    "heading",
  ],
  attributes: {},
  transform(node, config) {
    const attributes = node.transformAttributes(config);
    const children: RenderableTreeNode[] = node.transformChildren(config);
    return new Tag(`FloatingMenu`, { ...attributes }, children);
  },
};
