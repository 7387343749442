import { MinusCircleOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Button, Card, Checkbox, Form, Input, Space } from "antd";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetTabConfig } from "../domain";

interface TabsWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetTabConfig;
  form: FormInstance;
}

export function TitleWidgetEditor(props: TabsWidgetEditorProps) {
  const { widget, object, record, conf, form } = props;

  return (
    <>
      <ChartOptionCollapse dark title="Alert Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: <div>Name</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "apiName"]}>
                    <Input />
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: (
                  <div>
                    <div style={{ marginBottom: 4 }}>Tabs</div>
                    <Form.List name={["config", "keys"]}>
                      {(fields, { add, remove }, { errors }) => {
                        const previousValues = form.getFieldValue([
                          "config",
                          "keys",
                        ]);
                        const previousCheckedIndex = (
                          previousValues || []
                        ).findIndex((v) => v.defaultSelected === true);
                        return (
                          <div>
                            <Space
                              direction="vertical"
                              style={{ width: "100%" }}
                            >
                              {fields.map((f, i) => {
                                return (
                                  <Card key={f.key} size="small">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 8,
                                      }}
                                    >
                                      <div style={{ flex: 1 }}>
                                        <Form.Item
                                          name={[f.name, "label"]}
                                          label="Label"
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={[f.name, "apiName"]}
                                          label="Api name"
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={[f.name, "defaultSelected"]}
                                          label="Label"
                                          valuePropName="checked"
                                          noStyle
                                        >
                                          <Checkbox
                                            onChange={(e) => {
                                              if (e.target.checked === true) {
                                                form.setFieldValue(
                                                  [
                                                    "config",
                                                    "keys",
                                                    previousCheckedIndex,
                                                    "defaultSelected",
                                                  ],
                                                  false
                                                );
                                              }
                                              return e;
                                            }}
                                          >
                                            Is default value
                                          </Checkbox>
                                        </Form.Item>
                                      </div>
                                      <div style={{ flex: 0 }}>
                                        <Button
                                          size="small"
                                          icon={<MinusCircleOutlined />}
                                          type="text"
                                          shape="circle"
                                          onClick={() => remove(i)}
                                        />
                                      </div>
                                    </div>
                                  </Card>
                                );
                              })}
                              <Button
                                block
                                size="small"
                                onClick={() =>
                                  add({
                                    label: "",
                                    defaultSelected: false,
                                    apiName: "",
                                  })
                                }
                              >
                                Add
                              </Button>
                            </Space>
                          </div>
                        );
                      }}
                    </Form.List>
                  </div>
                ),
                flex: 1,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
