import { Form } from "antd";
import type { FormInstance } from "antd/lib";
import { SchemaSelect } from "../../common/SchemaSelect";
import { type SchemaResult } from "../../../../../../../interfaces/transformations";

interface IScoreFormatterProps {
  form: FormInstance;
  schema: SchemaResult;
}

export function ScoreFormatter(props: IScoreFormatterProps) {
  const { form, schema } = props;

  return (
    <>
      <Form.Item name={["sortingAndFilteringColumn"]} label="Sorting property">
        <SchemaSelect schema={schema} alreadyUsed={[]} />
      </Form.Item>
    </>
  );
}
