import { EditOutlined } from "@ant-design/icons";
import type { Query } from "@cubejs-client/core";
import type { FormInstance } from "antd";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import type { PopoverProps } from "antd/lib";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { ColumnsAddition } from "../../../../../../../../components/widget-options/ColumnsAddition";
import type { IObject } from "../../../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import { getAvailableDimensions } from "../../../../../object/viewer/domain";
import PropertyGroupsSelector from "../../../../../object/viewer/toolbar/PropertiesSelector/PropertyGroupsSelector";
import PropertySort from "../../../../../object/viewer/toolbar/PropertiesSelector/PropertySortSelector";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IRelatedListConfig } from "../domain";
import { FilterEditor } from "./FilterEditor";

interface IWidgetRelatedListEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IRelatedListConfig;
  form: FormInstance;
}

type Props = IWidgetRelatedListEditorProps & InjectedOrgProps;

function WidgetRelatedListEditor(props: Props) {
  const { widget, object, record, conf, form, org } = props;
  return (
    <>
      <ChartOptionCollapse dark title="Related List Configuration">
        <Space style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item
                    label="Related Object"
                    required
                    style={{ marginBottom: 4 }}
                    name={["config", "foreignObjectPropertyId"]}
                  >
                    <Select
                      optionLabelProp="label"
                      style={{ width: "100%", maxWidth: "100%" }}
                      popupMatchSelectWidth={false}
                    >
                      {object.foreignKeys.map((ok) => {
                        return (
                          <Select.Option
                            label={ok.object.name}
                            key={ok.id}
                            value={ok.id}
                          >
                            <div
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {ok.object.name}{" "}
                              <Typography.Text type="secondary">
                                on {ok.label}
                              </Typography.Text>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <Form.Item noStyle shouldUpdate>
            {() => {
              const currentPropertyId = form.getFieldValue([
                "config",
                "foreignObjectPropertyId",
              ]);
              const propertyExists = currentPropertyId
                ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
                : undefined;

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Columns"
                            required
                            style={{ marginBottom: 4 }}
                            name={["config", "options", "columns"]}
                          >
                            <ColumnsAddition
                              availableColumns={availableColumns}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "columns"]}
                  />
                );
              }
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const currentPropertyId = form.getFieldValue([
                "config",
                "foreignObjectPropertyId",
              ]);
              const propertyExists = currentPropertyId
                ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
                : undefined;

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );

                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            style={{ marginBottom: 4 }}
                            label={"Sort"}
                            name={["config", "options", "sortBy"]}
                          >
                            <PropertySort columns={availableColumns} />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              }
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "sortBy"]}
                />
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const currentPropertyId = form.getFieldValue([
                "config",
                "foreignObjectPropertyId",
              ]);
              const propertyExists = currentPropertyId
                ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
                : undefined;

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );

                const availableDimensions = getAvailableDimensions(
                  availableColumns.filter((p) => {
                    if (p.type === "property") {
                      return p.data.type !== "primaryKey";
                    } else {
                      return true;
                    }
                  }),
                  {
                    type: "display",
                  }
                );

                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            style={{ marginBottom: 4 }}
                            label={"Group"}
                            name={["config", "options", "groupBy"]}
                          >
                            <PropertyGroupsSelector
                              measures={availableDimensions}
                              zone="dimensions"
                              accept={["property"]}
                              showTimeAgg
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              }
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "groupBy"]}
                />
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const currentPropertyId = form.getFieldValue([
                "config",
                "foreignObjectPropertyId",
              ]);
              const propertyExists = currentPropertyId
                ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
                : undefined;

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );
                const availableDimensions = getAvailableDimensions(
                  availableColumns,
                  { type: "sortAndFilter" }
                );
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            required
                            noStyle
                            name={["config", "options", "filters"]}
                          >
                            <FilterEditor
                              autocomplete={async (
                                dimension,
                                operator,
                                value
                              ) => {
                                try {
                                  const dim = availableDimensions.find(
                                    (ac) => dimension === ac.key
                                  );

                                  if (!dim) {
                                    throw new Error("Dim not found");
                                  }

                                  let query: Query = {
                                    dimensions: [dim.key],
                                    limit: 50,
                                    filters: [
                                      {
                                        member: dim.key,
                                        operator: "set",
                                      },
                                    ],
                                  };
                                  if (
                                    typeof value === "string" &&
                                    value !== "" &&
                                    operator
                                  ) {
                                    query = {
                                      dimensions: [dim.key],
                                      limit: 50,
                                      filters: [
                                        {
                                          member: dim.key,
                                          operator: operator,
                                          values: [value],
                                        },
                                      ],
                                    };
                                  }

                                  const data = await lagoonServiceLoad(
                                    org.id,
                                    query,
                                    "OBJECT",
                                    object.id,
                                    undefined,
                                    LagoonCallOrigin.WHALY_APP
                                  );

                                  const results = data.tablePivot();

                                  return results.map((r) =>
                                    r[dim.key]?.toString?.()
                                  );
                                } catch (err) {
                                  console.error(err);
                                  throw new Error(
                                    "Can't fetch recommendations"
                                  );
                                }
                              }}
                              availableDimensions={getAvailableDimensions(
                                availableColumns,
                                { type: "sortAndFilter" }
                              )}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "filters"]}
                  />
                );
              }
            }}
          </Form.Item>

          <ChartOptionLine
            style={{ marginBottom: 6 }}
            items={[
              {
                content: "Hide pagination",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name={["config", "options", "hidePagination"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />

          <ChartOptionLine
            style={{ marginBottom: 6 }}
            items={[
              {
                content: "Row number",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name={["config", "options", "showRowNumber"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />

          <Form.Item noStyle shouldUpdate>
            {() => {
              const hidePagination = form.getFieldValue([
                "config",
                "options",
                "hidePagination",
              ]);
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: hidePagination ? "Limit" : "Page size",
                      flex: 1,
                    },
                    {
                      content: (
                        <Form.Item
                          noStyle
                          name={["config", "options", "pageSize"]}
                        >
                          <InputNumber size="small" />
                        </Form.Item>
                      ),
                      flex: 0,
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>
      <Form.Item noStyle shouldUpdate={true}>
        {() => {
          const columns = form.getFieldValue(["config", "options", "columns"]);

          const columnsSettings = (columns ?? []).flatMap((column, index) => {
            const currentPropertyId = form.getFieldValue([
              "config",
              "foreignObjectPropertyId",
            ]);

            const propertyExists = currentPropertyId
              ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
              : undefined;

            if (currentPropertyId && propertyExists) {
              const availableColumn = getObjectColumns(
                propertyExists.object
              ).find((c) => c.data.key === column);
              const columnName = availableColumn
                ? availableColumn.data.label
                : column;
              let canDisplayImage: boolean = false;
              if (
                availableColumn?.type === "property" &&
                availableColumn.data.type === "primaryKey"
              ) {
                canDisplayImage = true;
              }
              if (
                availableColumn?.type === "property" &&
                availableColumn.data.type === "foreignKey"
              ) {
                canDisplayImage = true;
              }

              const columnPopover: PopoverProps = {
                title: columnName,
                content: (
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={12}
                  >
                    <div>
                      <Typography.Text strong>Column name</Typography.Text>
                      <br />
                      <Form.Item
                        name={[
                          "config",
                          "options",
                          "columnsSettings",
                          column,
                          "label",
                        ]}
                        noStyle
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div>
                      <Typography.Text strong>Display image</Typography.Text>
                      <br />
                      <Form.Item
                        name={[
                          "config",
                          "options",
                          "columnsSettings",
                          column,
                          "displayImage",
                        ]}
                        noStyle
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                    <div>
                      <Typography.Text strong>Initial size</Typography.Text>
                      <br />
                      <Form.Item
                        name={[
                          "config",
                          "options",
                          "columnsSettings",
                          column,
                          "initialSizeType",
                        ]}
                        noStyle
                      >
                        <Select
                          style={{ width: "100%" }}
                          options={[
                            { label: "Fill space", value: "fill" },
                            { label: "Fixed width", value: "fixedWidth" },
                          ]}
                        />
                      </Form.Item>
                      <br />
                      <Form.Item noStyle shouldUpdate>
                        {() => {
                          const displaySize =
                            form.getFieldValue([
                              "config",
                              "options",
                              "columnsSettings",
                              column,
                              "initialSizeType",
                            ]) === "fixedWidth";

                          if (displaySize) {
                            return (
                              <Form.Item
                                name={[
                                  "config",
                                  "options",
                                  "columnsSettings",
                                  column,
                                  "initialSizeWidth",
                                ]}
                                style={{ paddingTop: 8 }}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                  }}
                                  suffix="px"
                                  placeholder="size"
                                />
                              </Form.Item>
                            );
                          }
                        }}
                      </Form.Item>
                    </div>
                  </Space>
                ),
              };

              return (
                <ChartOptionLine
                  key={index}
                  items={[
                    {
                      content: <div>{columnName}</div>,
                      flex: 1,
                      popover: columnPopover,
                    },
                    {
                      content: (
                        <Button
                          size="small"
                          type="text"
                          shape="circle"
                          icon={<EditOutlined />}
                        />
                      ),
                      popover: columnPopover,
                      flex: "0 auto",
                    },
                  ]}
                ></ChartOptionLine>
              );
            } else {
              return [];
            }
          });

          if (!columnsSettings?.length) {
            return null;
          }

          return (
            <ChartOptionCollapse dark title="Columns Settings">
              <Space style={{ width: "100%" }} direction="vertical">
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: "100%" }}
                >
                  {columnsSettings}
                </Space>
              </Space>
            </ChartOptionCollapse>
          );
        }}
      </Form.Item>
    </>
  );
}

export default compose<Props, IWidgetRelatedListEditorProps>(WithOrg)(
  WidgetRelatedListEditor
);
