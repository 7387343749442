import {
  authenticatedRequest,
  getAccessToken,
  getAuthorizationType,
} from "../authService";

import type { ApolloError, DocumentNode } from "@apollo/client";
import {
  ApolloClient,
  createHttpLink,
  from,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import type { OperationDefinitionNode } from "graphql";
import { Kind, OperationTypeNode } from "graphql";
import StorageService from "../StorageService";

const getHttpErrorCode = (error: ApolloError): number | undefined => {
  if (error.networkError && "statusCode" in error.networkError) {
    return error.networkError.statusCode;
  }

  return undefined;
};

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

const coreUri =
  StorageService.getItem("_wly_override_core_uri") ??
  window.WHALY_CONSTANTS.API_URL;

const httpLink = createHttpLink({
  uri: `${coreUri}/v1/graphql`,
});

const authLink = setContext((_, { headers, body }) => {
  const token = getAccessToken();
  const type = getAuthorizationType();

  return {
    headers: {
      ...headers,
      authorization: token ? `${type} ${token}` : "",
    },
    body: {
      ...body,
      operationName: _.operationName,
    },
  };
});

export const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

type GqlFetchWithRetryParams = {
  operation: OperationTypeNode | undefined;
  query: DocumentNode;
  queryType: string | undefined;
  variables: any;
  retries?: number;
};
const gqlFetchWithRetry = async <T = any>({
  operation,
  query,
  queryType,
  variables,
  retries = 3,
}: GqlFetchWithRetryParams): Promise<T> => {
  try {
    await authenticatedRequest();

    const { errors, data } =
      operation === OperationTypeNode.QUERY
        ? await client.query({
            query,
            variables,
            fetchPolicy: "network-only",
          })
        : await client.mutate({
            mutation: query,
            variables,
            fetchPolicy: "network-only",
          });

    if (errors) {
      throw new Error(JSON.stringify(errors));
    }
    if (!data) {
      throw new Error("There was an error with your query");
    }
    if (queryType) {
      return data[queryType] as T;
    }

    return data as T;
  } catch (error) {
    const httpErrorCode =
      "networkError" in error
        ? getHttpErrorCode(error as ApolloError)
        : undefined;

    if (retries > 0 && httpErrorCode === 502) {
      await delay(2000);
      return gqlFetchWithRetry({
        operation,
        query,
        queryType,
        variables,
        retries: retries - 1,
      });
    } else {
      console.error(error);
      throw new Error(error);
    }
  }
};

const graphQlService = <T = any>(
  query: string | DocumentNode,
  variables: any,
  queryType?: string
): Promise<T> => {
  const gqlQuery = typeof query === "string" ? gql(query) : query;
  const operationDefinitions = gqlQuery.definitions.filter(
    (d) => d.kind === Kind.OPERATION_DEFINITION
  ) as OperationDefinitionNode[];

  if (operationDefinitions.length > 1) {
    throw new Error("Please pass only one OperationDefinition");
  }

  const operation = operationDefinitions.at(0)?.operation;

  return gqlFetchWithRetry({
    operation,
    query: gqlQuery,
    queryType,
    variables,
  });
};

export default graphQlService;
