import type { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { DatasetItem } from "../../../containers/exploration/single/visualization/chart/domain";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { UserLocale } from "../../../interfaces/user";
import { getFontColorFromBackground } from "../../../utils/colorUtils";
import { calculateReverseDistance } from "../../../utils/distance";
import "../../ag-grid/theme/ag-theme-whaly.scss";
import { compose } from "../../compose/WlyCompose";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../measures/filter-item/FilterItem";
import {
  getDefaultCollection,
  getSelectedPalette,
} from "../../palette/utils/paletteData";
import PaletteGenerator from "../../palette/utils/PaletteGenerator";
import { customFormatter } from "../utils/optionsHelper";
import {
  getLabelKey,
  getPeriodKey,
  getSizeKey,
  getValueKey,
} from "./RetentionChart.definition";

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 100,
  sortable: false,
  resizable: false,
  suppressMovable: true,
  menuTabs: [],
};

interface RetentionChartProps {
  availableMetrics: AvailableMetric[];
  availableDimensions: AvailableDimension[];
  selectedDimensions: string[];
  selectedMeasures: string[];
  dataset: DatasetItem[];
  additionalDataset?: DatasetItem[];
  chartOptions?: ChartOption;
  locale: UserLocale;
  onDrill?: (options: {
    periodValue: string;
    cohortLabel: string;
    periodKey: string;
  }) => void;
}

type Props = RetentionChartProps & InjectedOrgProps;

const RetentionChart = ({
  org,
  availableMetrics,
  availableDimensions,
  selectedDimensions,
  selectedMeasures,
  dataset,
  additionalDataset = [],
  chartOptions,
  locale,
  onDrill,
}: Props) => {
  const labelKey = getLabelKey(selectedDimensions);
  const periodKey = getPeriodKey(selectedDimensions);
  const valueKey = getValueKey(selectedMeasures);
  const sizeKey = getSizeKey(selectedMeasures);

  const cohortSize = availableMetrics.find((am) => am.key === sizeKey);
  const cohortValue = availableMetrics.find((am) => am.key === valueKey);
  const cohortLabel = availableDimensions.find((am) => am.key === labelKey);

  const periodValues: string[] = [];

  const retentionData = dataset.map((d) => {
    const periods = Object.fromEntries(
      additionalDataset
        .filter((ad) => ad.x.startsWith(`${d.x},`))
        .map((ad) => {
          const periodValue = ad.x.split(",")[1];
          if (!periodValues.includes(periodValue)) {
            periodValues.push(periodValue);
          }
          return [`${periodValue},${valueKey}`, ad[`${valueKey}.current`]];
        })
    );

    return {
      [labelKey]: d.x,
      [sizeKey]: d[`${sizeKey}.current`],
      ...periods,
    };
  });

  const defaultPaletteCollection = getDefaultCollection(org);
  const defaultPalette =
    defaultPaletteCollection.diverging[0] ??
    defaultPaletteCollection.sequential[0];

  const palette = new PaletteGenerator({
    ...(chartOptions?.["palette-continue"]
      ? getSelectedPalette(org, chartOptions["palette-continue"])
      : defaultPalette),
    numberOfColors: 101,
  });

  const displayPercent = chartOptions?.["retention-percent"] ?? true;
  const showColors = chartOptions?.["retention-colors"] ?? true;
  const firstPeriod = periodValues.sort((a, b) => a.localeCompare(b))[0];

  const colDef: ColDef[] = [
    {
      headerName: cohortLabel?.label ?? labelKey,
      field: labelKey,
      sort: "asc",
    },
    {
      headerName: cohortSize?.label ?? sizeKey,
      field: sizeKey,
      hide: !sizeKey,
      valueFormatter: ({ value }) => {
        if (!value || value === "") {
          return value;
        }
        return customFormatter(value, locale, cohortSize?.formatter);
      },
    },
    ...periodValues
      .sort((a, b) => a.localeCompare(b))
      .map(
        (pk) =>
          ({
            headerName: pk,
            field: `${pk},${valueKey}`,
            onCellClicked: ({ value, data }) => {
              if (onDrill && value) {
                const cohortLabel = data[labelKey];
                onDrill({ cohortLabel, periodValue: pk, periodKey });
              }
            },
            valueFormatter: ({ value, data }) => {
              if (!value || value === "") {
                return value;
              }
              if (displayPercent) {
                return customFormatter(
                  value / data[sizeKey ?? `${firstPeriod},${valueKey}`],
                  locale,
                  {
                    format: "NUMBER",
                    customFormatting: "0.[0]%",
                  }
                );
              } else {
                return customFormatter(value, locale, cohortValue?.formatter);
              }
            },
            cellStyle: ({ value, data, colDef }) => {
              const defaultStyle = {
                textAlign: "center",
                cursor: !!onDrill && !!value ? "pointer" : "default",
              };

              if (palette && showColors) {
                try {
                  const backgroundColor = palette.getColorAtIndex(
                    calculateReverseDistance(
                      0,
                      data[sizeKey],
                      data[colDef.field!]
                    )
                  );
                  const fontColor = getFontColorFromBackground(backgroundColor);

                  return {
                    ...defaultStyle,
                    background: backgroundColor,
                    color: fontColor,
                  };
                } catch {
                  return defaultStyle;
                }
              } else {
                return defaultStyle;
              }
            },
          } as ColDef)
      ),
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AgGridReact
        className="ag-theme-retention"
        defaultColDef={defaultColDef}
        columnDefs={colDef}
        rowData={retentionData}
        suppressFieldDotNotation={true}
        suppressContextMenu={true}
        suppressCellFocus={true}
      />
    </div>
  );
};

export default compose<Props, RetentionChartProps>(WithOrg)(RetentionChart);
