import Markdoc from "@markdoc/markdoc";
import React from "react";
import { useLagoonQuery } from "../../../../../../../../components/hooks/query/useLagoonQuery";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import RecordView from "../../../../RecordView";
import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetRepeatableConfig } from "../domain";
import { getEmailWidetRepeatableQueryConfig } from "../domain";
import { getObjectColumns } from "../../../../../object/domain";
import {
  convertKeyToMarkdocVariable,
  getParsedDoc,
} from "../../../common/markdoc/domain";

interface IEmailWidgetRepeatableRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetRepeatableConfig> {}

export function EmailWidgetRepeatableRenderer(
  props: IEmailWidgetRepeatableRendererProps
) {
  const { conf, record, object, user, org } = props;

  const queryConfig = getEmailWidetRepeatableQueryConfig({
    object,
    record,
    conf,
  });

  const query = queryConfig.isValid ? queryConfig.query : undefined;

  const { data, loading, error } = useLagoonQuery(query, {
    objectType: "OBJECT",
    objectId: object.id,
    org: org,
    enabled: !!query,
  });

  if (!queryConfig.isValid) {
    return <>Please configure this widget</>;
  }

  if (loading) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) {
    return <Feednack>{error.message}</Feednack>;
  }

  const renderItem = (recordId: string) => {
    return (
      <RecordView
        homePageConfig={{
          recordId: recordId,
          objectId: queryConfig.foreignObject.id,
          layoutId: conf.options?.foreignObjectSelectedlayout,
          hideHeader: true,
        }}
        isSubLayout={true}
      />
    );
  };

  const torender = (data ?? []).map(
    (datapoint) => datapoint[queryConfig.dimensionKey]
  );

  if (torender.length) {
    return <>{torender.map((d, i) => renderItem(d as string))}</>;
  }

  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});

  const content = getParsedDoc(
    conf.fallback?.content ?? "",
    formattedRecord,
    user,
    getObjectColumns(object)
  );

  const formattedContent = Markdoc.renderers.react(content, React);

  return <>{formattedContent}</>;
}
