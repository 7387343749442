import {
  CompassOutlined,
  MoreOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { sql } from "@codemirror/lang-sql";
import CodeMirror from "@uiw/react-codemirror";
import type { MenuProps } from "antd";
import { Button, Dropdown, Empty, Flex, Tabs, Tooltip } from "antd";
import _ from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Card from "../../../../../components/cards/Card";
import { ChartDefinition } from "../../../../../components/chart/domain";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import { encodeLagoonQueryForURL } from "../../../../../helpers/queryStringHelpers";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IReport } from "../../../../../interfaces/reports";
import { IUserRoleType } from "../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import { downloadCsv } from "../../../../../utils/csvDownload";
import { STALE_DATA_MESSAGE } from "../../../../exploration/single/visualization/chart/Chart";
import ChartSizer from "../../../../exploration/single/visualization/chart/ChartSizer";
import ChartWarnings from "../../../../exploration/single/visualization/chart/ChartWarnings";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../../../user-settings/HasRoleAccess";
import type { Store } from "../../chart/card/ChartCard";
import type { ChartEditionInitialData } from "../../chart/edition/ChartEditionDrawer";
import { ChartEditionDrawer } from "../../chart/edition/ChartEditionDrawer";
import type { ActionType, DataMapStore } from "../../domain";
import {
  CREATE_TILE,
  RELOAD_EXPLORATION,
  UPDATE_TILE_FULL,
} from "../../domain";
import { convertWlyDatePickerValueToString } from "../../filters/date-filter/WlyDatePicker";

interface IQuestionContentProps {
  report: IReport;
  editing: boolean;
  actionType: ActionType;
  refreshReport: () => any;
  embededWorkbench?: boolean;
  saving: (s: boolean) => void;
  explorations: IExploration[];
  setExplorations: (e: IExploration[]) => void;
  reloadReport: (reportSlug: string, orgId: string) => Promise<any>;
  setDataStoreValue: (key: string, value: Store) => void;
  dataStore: DataMapStore;
  setReport: (r: IReport) => void;
  disableDrills?: boolean;
  isDisplayedInWorkspace?: boolean;
  forceCacheRefresh: (force: boolean) => Promise<any>;
  onOpenConsole?: (c?: string) => void;
}

interface IState {
  selectedView: "VISUALIZATION" | "DATA" | "SQL";
  showActionDrawer: boolean;
  warnings: string[];
}

type Props = IQuestionContentProps &
  RouteComponentProps<{ tileSlug?: string; explorationSlug?: string }> &
  InjectedOrgProps;

class QuestionContent extends React.Component<Props, IState> {
  viz: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedView: "VISUALIZATION",
      showActionDrawer: false,
      warnings: [],
    };

    this.viz = React.createRef();
  }

  componentDidMount(): void {
    this.setWarnings();
  }

  componentDidUpdate(prevProps: Props) {
    this.setWarnings();
  }

  setWarnings = () => {
    const { report, explorations } = this.props;
    const explorationId = report.tiles[0]?.exploration?.id;
    const exploration = explorations.find((r) => r.id === explorationId);
    if (
      exploration &&
      exploration.hasUpstreamErrors &&
      !this.state.warnings.includes(STALE_DATA_MESSAGE)
    ) {
      this.setState({ warnings: [...this.state.warnings, STALE_DATA_MESSAGE] });
    }
  };

  onTileUpdate = (id: string, data: any) => {
    const { report, setReport } = this.props;

    this.props.saving(true);
    return GraphQLService(UPDATE_TILE_FULL, {
      id: id,
      data: data,
      reportId: report.id,
    })
      .then((r) => {
        setReport({
          ...report,
          tiles: [...report.tiles.filter((t) => t.id !== id), r.updateTile],
          updatedAt: r.updateReport.updatedAt,
          updatedBy: r.updateReport.updatedBy,
        });
        this.props.saving(false);
      })
      .catch(() => {
        this.props.saving(false);
      });
  };

  debouncedTileUpdate = _.debounce(this.onTileUpdate, 500);

  renderActionType = (): ChartEditionInitialData | undefined => {
    const {
      actionType,
      match: { params },
      org,
      report,
      explorations,
      reloadReport,
      setExplorations,
      setDataStoreValue,
      dataStore,
    } = this.props;

    if (!actionType) {
      return undefined;
    }
    switch (actionType) {
      case "CREATE_TILE":
        if (!params.explorationSlug) {
          throw new Error("Exploration slug must be defined...");
        }
        return {
          actionType: "CREATE_TILE",
          explorationSlug: this.props.match.params.explorationSlug,
          onSave: async (q, e) => {
            await GraphQLService(CREATE_TILE, {
              data: {
                slug: "tile",
                name: "Untitled",
                content: JSON.stringify({
                  ...q,
                  dateRange: convertWlyDatePickerValueToString(q.dateRange),
                }),
                width: 0,
                height: 0,
                left: 0,
                top: 0,
                type: "chart",
                analysisType: q.analysisType,
                chartType: q.chartType,
                org: {
                  connect: {
                    id: org.id,
                  },
                },
                report: {
                  connect: {
                    id: report.id,
                  },
                },
                exploration: {
                  connect: {
                    id: e.id,
                  },
                },
              },
              reportId: report.id,
            })
              .then(() => {
                return GraphQLService(RELOAD_EXPLORATION, {
                  explorationId: e.id,
                }).then((ex) => ex.Exploration as IExploration);
              })
              .then((explo) => {
                const newExplorations = explorations.map((ex) => {
                  if (ex.id === explo.id) {
                    return explo;
                  }
                  return ex;
                });
                setExplorations(newExplorations);

                return reloadReport(report.slug, org.id);
              })
              .then(() => {
                this.props.saving(false);
              })
              .catch(() => {
                this.props.saving(false);
              });
          },
        };
      case "EDIT_TILE":
        const editingTile = report.tiles.find(
          (t) => t.slug === this.props.match.params.tileSlug
        );
        if (!editingTile) {
          throw new Error("Unknown tile");
        }
        return {
          actionType: "EDIT_TILE",
          tile: editingTile,
          onSave: async (t, q, e) => {
            this.props.saving(true);
            await this.onTileUpdate(t.id, {
              name: t.name,
              chartType: q.chartType,
              analysisType: q.analysisType,
              content: JSON.stringify({
                ...q,
                dateRange: convertWlyDatePickerValueToString(q.dateRange),
              }),
            })
              .then(() => {
                return GraphQLService(RELOAD_EXPLORATION, {
                  explorationId: e.id,
                }).then((ex) => ex.Exploration as IExploration);
              })
              .then((ex) => {
                setExplorations(
                  explorations.map((explo) => {
                    if (explo.id === ex.id) {
                      return ex;
                    }
                    return explo;
                  })
                );
                setDataStoreValue(t.id!, {
                  ...dataStore[t.id!],
                  query: q,
                  explorationId: t.exploration.id,
                  data: {
                    status: "initial",
                  },
                });

                return reloadReport(report.slug, org.id);
              })
              .then(() => {
                this.props.saving(false);
              })
              .catch(() => {
                this.props.saving(false);
              });
          },
        };
      case "EXPLORE":
        const exploringTile = report.tiles.find(
          (t) => t.slug === this.props.match.params.tileSlug
        );
        if (!exploringTile) {
          throw new Error("Unknown tile");
        }
        return {
          actionType: "EXPLORE",
          tile: exploringTile,
          onAddToReport: async () => {
            this.props.saving(true);
            await reloadReport(report.slug, org.id);
            this.props.saving(false);
          },
        };
    }
  };

  public render() {
    const {
      org,
      user,
      report,
      refreshReport,
      editing,
      history,
      actionType,
      dataStore,
      setDataStoreValue,
      forceCacheRefresh,
      match: { params },
      disableDrills,
      isDisplayedInWorkspace,
      onOpenConsole,
    } = this.props;

    const { selectedView } = this.state;

    if (report.tiles.length === 0) {
      return (
        <div style={{ flex: 1 }}>
          <Feednack>
            <Empty description="Ask a question..." />
          </Feednack>
        </div>
      );
    }

    const tile = report.tiles[0];
    const data = dataStore[tile.id];

    const isEditor = hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id);

    if (!data) {
      return "not found";
    }

    const showExploration = () =>
      history.push(
        routeDescriptor["explore"].renderRoute(
          {
            ...params,
            tileSlug: tile.slug,
            exploreSlug: tile.exploration.slug,
          },
          {
            query: encodeLagoonQueryForURL({
              ...data.query,
              chartType: tile.chartType,
            }),
            loadQuery: true,
          }
        )
      );

    const exportCsv = () =>
      downloadCsv(
        org.id,
        report.id,
        !!ChartDefinition[tile.chartType].downloadCSV
          ? {
              type: "ChartData",
              chartType: tile.chartType,
              options: {
                query: data.query,
                resultSet: data.data,
                additionalResultSet: data.additionalResults,
              },
            }
          : {
              type: "ResultSet",
              data: data.data,
            },
        report.name
      )();

    const menu: MenuProps = {
      items: [
        {
          key: 1,
          onClick: exportCsv,
          label: "Export CSV",
        },
        ...(onOpenConsole && isEditor
          ? [
              {
                key: 2,
                onClick: () => onOpenConsole(tile.id),
                label: "View in console",
              },
            ]
          : []),
      ],
    };

    const { chartOptions, ...query } = data.query;

    return (
      <div
        style={{
          flex: 1,
          height: "calc(100% - 56px)",
        }}
        className="screenshot-container"
      >
        <div className={`report-content-container questions`}>
          <Card height={"100%"}>
            <div style={{ height: "100%" }} ref={this.viz}>
              <Tabs
                style={{ height: "100%" }}
                tabBarStyle={{ padding: "0 12px", marginBottom: 0 }}
                defaultActiveKey={selectedView}
                onChange={(k) => this.setState({ selectedView: k as any })}
                tabBarExtraContent={
                  <Flex gap="small" align="center">
                    <Flex gap="small">
                      <Tooltip
                        title={"Clear cache & refresh"}
                        placement="topRight"
                        trigger={["hover"]}
                      >
                        <Dropdown
                          trigger={["click"]}
                          placement={"bottomRight"}
                          arrow={true}
                          menu={{
                            items: [
                              {
                                key: 1,
                                onClick: () => forceCacheRefresh(false),
                                label: "Refresh",
                              },
                              {
                                key: 2,
                                onClick: () => forceCacheRefresh(true),
                                label: "Clear cache & refresh",
                              },
                            ],
                          }}
                        >
                          <Button size="small" shape="circle">
                            <ReloadOutlined />
                          </Button>
                        </Dropdown>
                      </Tooltip>

                      {this.state.warnings.length > 0 && (
                        <ChartWarnings
                          warnings={this.state.warnings}
                          placement="topRight"
                        />
                      )}
                    </Flex>
                    <HasRoleAccess accessLevel={IUserRoleType.VIEWER}>
                      <Button.Group>
                        <Button
                          size="small"
                          icon={<CompassOutlined />}
                          onClick={() => showExploration()}
                        >
                          Explore
                        </Button>
                        <Dropdown arrow={true} trigger={["click"]} menu={menu}>
                          <Button size="small" icon={<MoreOutlined />} />
                        </Dropdown>
                      </Button.Group>
                    </HasRoleAccess>
                  </Flex>
                }
                items={[
                  {
                    forceRender: true,
                    style: {
                      height: "100%",
                      marginBottom: 0,
                      padding: tile.chartType !== "table" ? 24 : 0,
                    },
                    label: "Visualization",
                    key: "VISUALIZATION",
                    children: (
                      <ChartSizer
                        analysisType={tile.analysisType}
                        currentChartType={tile.chartType}
                        data={data.data}
                        scale={
                          tile.analysisType === "TIME" ? "time" : "ordinal"
                        }
                        lagoonQuery={data.query}
                        availableDimensions={data.availableDimensions}
                        availableMetrics={data.availableMetrics}
                        exploration={data.foundExploration}
                        additionalResults={data.additionalResults}
                        setRender={(meta) => {
                          setDataStoreValue(tile.id, {
                            ...dataStore[tile.id],
                            meta: meta,
                          });
                        }}
                        onWarningsChange={(warnings) => {
                          this.setState({ warnings: warnings });
                        }}
                      />
                    ),
                  },
                  {
                    forceRender: true,
                    style: {
                      height: "100%",
                      marginBottom: 0,
                    },
                    label: "Data",
                    key: "DATA",
                    children: (
                      <ChartSizer
                        analysisType={tile.analysisType}
                        currentChartType={"table"}
                        data={data.data}
                        scale={
                          tile.analysisType === "TIME" ? "time" : "ordinal"
                        }
                        lagoonQuery={query}
                        availableDimensions={data.availableDimensions}
                        availableMetrics={data.availableMetrics}
                        exploration={data.foundExploration}
                        additionalResults={data.additionalResults}
                        reportId={report.id}
                        setRender={(meta) => {
                          if (!dataStore[tile.id].meta) {
                            setDataStoreValue(tile.id, {
                              ...dataStore[tile.id],
                              meta: meta,
                            });
                          }
                        }}
                        onWarningsChange={(warnings) => {
                          this.setState({ warnings: warnings });
                        }}
                        disableDrills={disableDrills}
                      />
                    ),
                  },
                  data.sql &&
                    isDisplayedInWorkspace && {
                      destroyInactiveTabPane: true,
                      style: {
                        height: "100%",
                        marginBottom: 0,
                        padding: tile.chartType !== "table" ? 24 : 0,
                      },
                      label: "SQL",
                      key: "SQL",
                      children: (
                        <>
                          {data.query.comparison ? (
                            <Feednack>
                              SQL is not supported in date comparison mode...
                            </Feednack>
                          ) : (
                            <CodeMirror
                              extensions={[sql()]}
                              height={"100%"}
                              value={data.sql.sql()}
                              editable={false}
                            />
                          )}
                        </>
                      ),
                    },
                ].filter((v) => v)}
              />
            </div>
          </Card>
        </div>
        <ChartEditionDrawer
          visible={!!actionType}
          report={report}
          setVisible={(b) => {
            // we should find a better way to avoid reloading the whole dashboard and just reload the tiles that have change but I have no idea on how to do it now ...
            refreshReport();
            if (editing) {
              return history.push(
                routeDescriptor.report.renderRoute({
                  ...this.props.match.params,
                })
              );
            }
            return history.push(
              routeDescriptor.report.renderRoute({
                ...this.props.match.params,
              })
            );
          }}
          embededWorkbench={this.props.embededWorkbench}
          initialData={this.renderActionType()}
        />
      </div>
    );
  }
}

export default compose<Props, IQuestionContentProps>(
  withRouter,
  WithOrg
)(QuestionContent);
