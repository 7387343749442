import { Typography } from "antd";
import React from "react";

export interface IGroupProps {
  type: "list" | "paragraph" | "comma-separated";
  children: Array<React.ReactNode>;
}

export function Group(props: React.PropsWithChildren<IGroupProps>) {
  const { children, type } = props;

  const getChildren = (): Array<React.ReactNode> => {
    if (!children) {
      return [];
    }
    if (Array.isArray(children)) {
      return children;
    }
    return [children];
  };

  const displayedChildren = getChildren();
  // maybe we can do it in the makdoc component but I don't have time to figure it out
  if (type === "list") {
    return (
      <Typography.Paragraph>
        <ul>
          {displayedChildren.map((c, i) => {
            return <li key={i}>{c}</li>;
          })}
        </ul>
      </Typography.Paragraph>
    );
  } else if (type === "paragraph") {
    return (
      <>
        {displayedChildren.map((p, i) => {
          return <Typography.Paragraph key={i}>{p}</Typography.Paragraph>;
        })}
      </>
    );
  }

  return <>{displayedChildren}</>;
}
