import { InfoCircleOutlined } from "@ant-design/icons";
import type {
  BinaryFilter,
  BinaryOperator,
  Query,
  TimeDimensionGranularity,
  UnaryFilter,
} from "@cubejs-client/core";
import { Button, Modal, Space, Tabs } from "antd";
import _ from "lodash";
import * as React from "react";
import Card from "../../../../components/cards/Card";
import type { ChartType } from "../../../../components/chart/domain";
import { compose } from "../../../../components/compose/WlyCompose";
import type { IComparisonPeriod } from "../../../../components/measures/comparison-selector/ComparisonSelector";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../components/measures/filter-item/FilterItem";
import type { MeasureItemSortValue } from "../../../../components/measures/measure-item/MeasureItem";
import { SplitView } from "../../../../components/resizable/SplitView";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../../interfaces/explorations";
import { IUserFeatureType } from "../../../../interfaces/user";
import { track } from "../../../../services/AnalyticsService";
import type {
  IQueryTraceResult,
  WlyResultSet,
} from "../../../../services/LagoonService";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../services/LagoonService";
import type { ChartOption } from "../../../chart-options/ChartOptions";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { IWlyDatePickerInputValue } from "../../../reports/view/filters/date-filter/WlyDatePicker";
import ExplorationHeader from "../ExplorationHeader";
import { ExplorationPanel } from "../ExplorationPanel";
import { ChartOptionsPanelWrapper } from "../chart-options/ChartOptionsPanelWrapper";
import type {
  FilterOperator,
  GetCurrentQuery,
  IAnalysisType,
  IForecastConfig,
  ILagoonQuery,
  ILagoonQueryExtra,
} from "../domain";
import "./Visualization.scss";
import Breakdown from "./breakdown/Breakdown";
import type { IAsyncMeta } from "./chart/Chart";
import ChartErrorHandler from "./chart/ChartErrorHandler";
import { ChartQueryBuilderDNDOverlay } from "./chart/ChartQueryBuilderDNDOverlay";
import { ChartQueryBuilderFormStatusOverlay } from "./chart/ChartQueryBuilderFormStatusOverlay";
import ChartSizer from "./chart/ChartSizer";
import ChartWarnings from "./chart/ChartWarnings";
import ExplorationDetails from "./exploration-details/ExplorationDetails";
import type {
  QueryBuilderFormStatus,
  QueryBuilderFormValue,
} from "./query-builder/QueryBuilder";
import QueryBuilder from "./query-builder/QueryBuilder";
import { QueryBuilderSubmitButton } from "./query-builder/QueryBuilderSubmitButton";
import { DEFAULT_ROW_LIMIT } from "./query-builder/domain";
import { queryData } from "./queryData";

interface IVisualizationProps {
  availableMetrics: Array<AvailableMetric>;
  availableDimensions: Array<AvailableDimension>;
  availableTime: Array<AvailableDimension>;
  exploration: IExploration;
  getCurrentQuery?: GetCurrentQuery;
  initialQuery?: ILagoonQuery;
  initialQueryLoad?: boolean;
  isEmbeded?: boolean;
  overrideObjectType?: "VIEW" | "EXPLORATION";
}

type VisualizationTabs = "CHART" | "BREAKDOWN";

type Props = IVisualizationProps & InjectedOrgProps;

interface IState {
  queryBuilderFormStatus: QueryBuilderFormStatus;
  results: AsyncData<WlyResultSet<any>>;
  // Used for Sparklines or Grand Total results
  additionalResults: AsyncData<WlyResultSet<any> | undefined>;
  sql: AsyncData<string>;
  trace: AsyncData<IQueryTraceResult>;
  selectedMeasures: string[];
  selectedDimensions: string[];
  selectedTime?: string;
  dateRange: IWlyDatePickerInputValue;
  comparison?: IComparisonPeriod;
  selectedGranularity?: TimeDimensionGranularity;
  filterOperator: FilterOperator;
  filters: (UnaryFilter | BinaryFilter)[];
  selectedAnalysis: IAnalysisType;
  chartType: ChartType;
  limit: number;
  addingToReport: boolean;
  pivotConfig?: string[];
  extra?: ILagoonQueryExtra;
  chartOptions?: ChartOption;
  forecast?: IForecastConfig;
  orderBy: Array<[string, MeasureItemSortValue]>;
  meta: IAsyncMeta;
  sqlQueryVisible: boolean;
  addingToQuestion: boolean;
  isStale: boolean;
  tab: VisualizationTabs;
  infoModalOpen: boolean;
  warnings: string[];
  metricFilterOperator: FilterOperator;
  metricFilters: (UnaryFilter | BinaryFilter)[];
}

class Visualization extends React.Component<Props, IState> {
  queryBuilderRef: React.RefObject<QueryBuilder>;

  constructor(props: Props) {
    super(props);
    this.queryBuilderRef = React.createRef<QueryBuilder>();

    const selectedAnalysis =
      props.initialQuery && props.initialQuery.analysisType
        ? props.initialQuery.analysisType
        : "METRIC";

    this.state = {
      queryBuilderFormStatus: {
        formValidation: {
          isValid: true,
          errors: [],
        },
        form: {
          isExplorationVersionOutdated: false,
          isStale: false,
          isLoading: false,
        },
      },
      infoModalOpen: false,
      tab: "CHART",
      results: {
        status: "initial",
      },
      sql: {
        status: "initial",
      },
      trace: {
        status: "initial",
      },
      additionalResults: {
        status: "initial",
      },
      sqlQueryVisible: false,
      selectedMeasures: [],
      selectedDimensions: [],
      dateRange: { type: "preset", preset: "LAST_7_DAYS" },
      filterOperator: "or",
      selectedGranularity: "day",
      filters: [],
      metricFilterOperator: "and",
      metricFilters: [],
      orderBy: [],
      selectedAnalysis: selectedAnalysis,
      chartType: props.initialQuery?.chartType
        ? props.initialQuery.chartType
        : "kpi",
      limit: 500,
      addingToReport: false,
      addingToQuestion: false,
      pivotConfig: props.initialQuery
        ? props.initialQuery.pivotDimensions
        : undefined,
      extra: props.initialQuery ? props.initialQuery.extra : undefined,
      chartOptions: props.initialQuery
        ? props.initialQuery.chartOptions
        : undefined,
      forecast: props.initialQuery ? props.initialQuery.forecast : undefined,
      meta: { status: "initial" },
      isStale: false,
      warnings: [],
    };
    if (this.props.getCurrentQuery && !props.initialQuery) {
      this.props.getCurrentQuery(
        true,
        this.state.selectedAnalysis,
        this.state.chartType,
        this.state.selectedMeasures,
        this.state.selectedDimensions,
        this.state.filters,
        this.state.filterOperator,
        this.state.dateRange,
        this.state.orderBy,
        this.state.limit,
        this.state.comparison,
        this.state.selectedTime,
        this.state.selectedGranularity,
        this.state.pivotConfig,
        this.state.chartOptions,
        this.state.forecast,
        this.state.extra,
        this.state.metricFilters,
        this.state.metricFilterOperator
      );
    }
  }

  getQuery = () => {
    const lagoonQuery: ILagoonQuery = {
      selectedMeasures: this.state.selectedMeasures,
      selectedDimensions: this.state.selectedDimensions,
      filters: this.state.filters,
      filterOperator: this.state.filterOperator,
      metricFilterOperator: this.state.metricFilterOperator,
      metricFilters: this.state.metricFilters,
      dateRange: this.state.dateRange,
      comparison: this.state.comparison,
      selectedTime: this.state.selectedTime,
      selectedGranularity: this.state.selectedGranularity,
      analysisType: this.state.selectedAnalysis,
      pivotDimensions: this.state.pivotConfig,
      limit: this.state.limit,
      orderBy: this.state.orderBy,
      chartOptions: this.state.chartOptions,
      forecast: this.state.forecast,
      extra: this.state.extra,
    };
    return lagoonQuery;
  };

  onRunQuery = async (options: QueryBuilderFormValue) => {
    this.setState({
      results: { status: "loading" },
      sql: { status: "loading" },
      additionalResults: { status: "loading" },
      selectedMeasures: options.measures,
      selectedDimensions: options.dimensions,
      filters: options.filters,
      filterOperator: options.filterOperator,
      dateRange: options.dateRange,
      comparison: options.comparison,
      selectedTime: options.timeDimension,
      selectedGranularity: options.selectedGranularity,
      chartType: options.chartType,
      selectedAnalysis: options.analysisType,
      limit: options.limit,
      orderBy: options.orderBy,
      chartOptions: this.state.chartOptions,
      pivotConfig: options.pivotConfig,
      forecast: options.forecast,
      extra: options.extra,
      warnings: [],
      metricFilterOperator: options.metricFilterOperator,
      metricFilters: options.metricFilters,
    });

    try {
      const { userFeatures, overrideObjectType } = this.props;
      const shouldUseLagoonNext = userFeatures.includes(
        IUserFeatureType.TMP_USE_LAGOON_NEXT
      );
      const { resultSet, additionalQueryResultSet, getSQL, getTrace } =
        await queryData(
          this.props.org.id,
          this.props.exploration.id,
          overrideObjectType ? overrideObjectType : "EXPLORATION",
          options.analysisType,
          options.chartType,
          options.measures,
          options.dimensions,
          options.filters,
          options.filterOperator,
          options.dateRange,
          options.orderBy,
          options.limit ? options.limit : DEFAULT_ROW_LIMIT,
          LagoonCallOrigin.WHALY_APP,
          options.comparison,
          options.timeDimension,
          options.selectedGranularity,
          options.pivotConfig,
          options.extra,
          options.metricFilters,
          options.metricFilterOperator,
          undefined,
          shouldUseLagoonNext
        );

      this.setState({
        results: {
          status: "success",
          data: resultSet,
        },
        sql: {
          status: "loading",
        },
        additionalResults: {
          status: "success",
          data: additionalQueryResultSet,
        },
        trace: {
          status: "loading",
        },
      });

      const consoleData = await Promise.all([getSQL(), getTrace()]);

      this.setState({
        ...this.state,
        sql: {
          status: "success",
          data:
            (resultSet as any).queryType === "compareDateRangeQuery"
              ? "SQL decomposition is not supported when comparing date range."
              : consoleData[0].sql(),
        },
        trace: {
          status: "success",
          data: consoleData[1],
        },
      });

      (window as any).resultSet = resultSet;

      if (this.props.getCurrentQuery) {
        this.props.getCurrentQuery(
          false,
          this.state.selectedAnalysis,
          this.state.chartType,
          this.state.selectedMeasures,
          this.state.selectedDimensions,
          this.state.filters,
          this.state.filterOperator,
          this.state.dateRange,
          this.state.orderBy,
          this.state.limit,
          this.state.comparison,
          this.state.selectedTime,
          this.state.selectedGranularity,
          this.state.pivotConfig,
          this.state.chartOptions,
          this.state.forecast,
          this.state.extra,
          this.state.metricFilters,
          this.state.metricFilterOperator
        );
      }
      track("Exploration Query Finished", {
        numberOfMeasure: this.state.selectedMeasures.length,
        numberOfDimension: this.state.selectedMeasures.length,
      });
    } catch (err) {
      console.error("lagoon error - ", err);
      this.setState({
        results: { status: "error", error: err as Error },
        sql: { status: "error", error: err as Error },
        additionalResults: { status: "error", error: err as Error },
      });
    }
  };

  autocomplete = async (
    dimension: string,
    operator?: BinaryOperator,
    value?: string
  ): Promise<string[]> => {
    const { userFeatures, overrideObjectType } = this.props;
    const shouldUseLagoonNext = userFeatures.includes(
      IUserFeatureType.TMP_USE_LAGOON_NEXT
    );
    let query: Query = {
      dimensions: [dimension],
      limit: 500,
      filters: [
        {
          member: dimension,
          operator: "set",
        },
      ],
    };
    if (typeof value === "string" && value !== "" && operator) {
      query = {
        dimensions: [dimension],
        limit: 50,
        filters: [
          {
            member: dimension,
            operator: operator,
            values: [value],
          },
        ],
      };
    }
    return lagoonServiceLoad(
      this.props.org.id,
      query,
      overrideObjectType ? overrideObjectType : "EXPLORATION",
      this.props.exploration.id,
      this.props.exploration.id,
      LagoonCallOrigin.WHALY_APP,
      undefined,
      undefined,
      shouldUseLagoonNext
    )
      .then((r) => {
        return r.tablePivot();
      })
      .then((r) => {
        return (r || []).map((d) => d[dimension] as string);
      });
  };

  onChartOptionChange = (v: ChartOption) => {
    if (this.props.getCurrentQuery) {
      this.props.getCurrentQuery(
        false,
        this.state.selectedAnalysis,
        this.state.chartType,
        this.state.selectedMeasures,
        this.state.selectedDimensions,
        this.state.filters,
        this.state.filterOperator,
        this.state.dateRange,
        this.state.orderBy,
        this.state.limit,
        this.state.comparison,
        this.state.selectedTime,
        this.state.selectedGranularity,
        this.state.pivotConfig,
        v,
        this.state.forecast,
        this.state.extra,
        this.state.metricFilters,
        this.state.metricFilterOperator
      );
    }
  };

  public renderRest = () => {
    return (
      <>
        <ChartQueryBuilderDNDOverlay formRef={this.queryBuilderRef} />
        <ChartQueryBuilderFormStatusOverlay
          formStatus={this.state.queryBuilderFormStatus}
          formRef={this.queryBuilderRef}
        />
        <div
          style={{
            height: "100%",
            display: this.state.tab === "CHART" ? "block" : "none",
          }}
        >
          <ChartErrorHandler>
            <ChartSizer
              trace={this.state.trace}
              currentChartType={this.state.chartType}
              data={this.state.results}
              scale={
                this.state.selectedAnalysis === "TIME" ? "time" : "ordinal"
              }
              analysisType={this.state.selectedAnalysis}
              availableMetrics={this.props.availableMetrics}
              availableDimensions={this.props.availableDimensions}
              lagoonQuery={this.getQuery()}
              exploration={this.props.exploration}
              onChartOptionSave={(v) => {
                this.setState({ chartOptions: v }, () => {
                  this.onChartOptionChange(v);
                });
              }}
              additionalResults={this.state.additionalResults}
              setRender={(m) => {
                if (!_.isEqual(m, this.state.meta)) {
                  this.setState({ meta: m });
                }
              }}
              onWarningsChange={(warnings: string[]) =>
                this.setState({ warnings: warnings })
              }
            />
          </ChartErrorHandler>
        </div>
        <div
          style={{
            height: "100%",
            display: this.state.tab === "BREAKDOWN" ? "block" : "none",
          }}
        >
          <Breakdown
            data={this.state.results}
            additionalResults={this.state.additionalResults}
            chartType={this.state.chartType}
            pivotConfig={[]}
            query={this.getQuery()}
            analysisType={this.state.selectedAnalysis}
            availableMetrics={this.props.availableMetrics}
          />
        </div>
      </>
    );
  };

  public render() {
    const { initialQuery, exploration, isEmbeded } = this.props;

    const availableTime = this.props.availableDimensions.filter(
      (am) => am.domain === "TIME"
    );

    return (
      <SplitView
        mainClassName="visualization-content"
        startWidth={290}
        leftClassName="chart-content-wrapper"
        left={
          <ExplorationPanel
            title={
              <Tabs
                destroyInactiveTabPane={false}
                tabBarExtraContent={
                  <>
                    <Space>
                      {this.state.warnings.length > 0 && (
                        <ChartWarnings
                          explicit
                          warnings={this.state.warnings}
                        />
                      )}
                      <Button
                        type="text"
                        shape="circle"
                        icon={<InfoCircleOutlined />}
                        onClick={() => this.setState({ infoModalOpen: true })}
                      />
                    </Space>
                    <Modal
                      open={this.state.infoModalOpen}
                      onCancel={() => this.setState({ infoModalOpen: false })}
                      onOk={() => this.setState({ infoModalOpen: false })}
                      title="Information"
                    >
                      <ExplorationDetails exploration={exploration} />
                    </Modal>
                  </>
                }
                onChange={(k: VisualizationTabs) => this.setState({ tab: k })}
                style={{
                  height: 40,
                }}
                tabBarStyle={{
                  height: 40,
                }}
                items={[
                  {
                    key: "CHART",
                    label: `Chart`,
                  },
                  {
                    key: "BREAKDOWN",
                    label: `Breakdown`,
                  },
                ]}
              />
            }
          >
            <div className="visualization">
              <div style={{ height: "100%", padding: 12 }}>
                <Card height={"100%"}>
                  <div
                    style={{
                      padding: 12,
                      height: "100%",
                    }}
                  >
                    {this.renderRest()}
                  </div>
                </Card>
              </div>
            </div>
            {!isEmbeded && (
              <ExplorationHeader
                exploration={exploration}
                selectedAnalysis={this.state.selectedAnalysis}
                chartType={this.state.chartType}
                getQuery={this.getQuery}
                canSaveChart={
                  !this.state.isStale && this.state.results.status === "success"
                }
                traceModal={{
                  data: this.state.results,
                  trace: this.state.trace,
                  sql:
                    this.state.sql.status === "success"
                      ? this.state.sql.data
                      : null,
                }}
              />
            )}
          </ExplorationPanel>
        }
        alignement="RTL"
        minWidth={210}
        rightClassName="chart-options-wrapper"
        maxWidth={"40%"}
        right={
          <ExplorationPanel
            title="Options"
            bodyStyle={{ background: "white" }}
            footerStyle={{ background: "white" }}
            footer={
              <div>
                <QueryBuilderSubmitButton
                  formStatus={this.state.queryBuilderFormStatus}
                  formRef={this.queryBuilderRef}
                />
              </div>
            }
          >
            <ChartOptionsPanelWrapper
              meta={this.state.meta}
              exploration={this.props.exploration}
              lagoonQuery={this.getQuery()}
              currentChartType={this.state.chartType}
              availableMetrics={this.props.availableMetrics}
              availableDimensions={this.props.availableDimensions}
              queryBuilder={
                <QueryBuilder
                  chartType={this.state.chartType}
                  ref={this.queryBuilderRef}
                  onFormUpdate={(update) => {
                    if (!_.isEqual(update, this.state.queryBuilderFormStatus)) {
                      this.setState({
                        queryBuilderFormStatus: update,
                      });
                    }
                  }}
                  explorationVersion={this.props.exploration.version}
                  availableMetrics={this.props.availableMetrics}
                  availableDimensions={this.props.availableDimensions}
                  availableTime={availableTime}
                  loading={this.state.results.status === "loading"}
                  autocomplete={this.autocomplete}
                  onRunQuery={this.onRunQuery}
                  initialQuery={initialQuery}
                  initialQueryLoad={this.props.initialQueryLoad}
                  isStale={this.state.isStale}
                  setStale={(status) => {
                    this.setState({ isStale: status }, () => {
                      if (this.props.getCurrentQuery) {
                        this.props.getCurrentQuery(
                          status,
                          this.state.selectedAnalysis,
                          this.state.chartType,
                          this.state.selectedMeasures,
                          this.state.selectedDimensions,
                          this.state.filters,
                          this.state.filterOperator,
                          this.state.dateRange,
                          this.state.orderBy,
                          this.state.limit,
                          this.state.comparison,
                          this.state.selectedTime,
                          this.state.selectedGranularity,
                          this.state.pivotConfig,
                          this.state.chartOptions,
                          this.state.forecast,
                          this.state.extra,
                          this.state.metricFilters,
                          this.state.metricFilterOperator
                        );
                      }
                    });
                  }}
                />
              }
              onChange={(f) => {
                this.setState({ chartOptions: f });
                if (this.props.getCurrentQuery) {
                  this.props.getCurrentQuery(
                    false,
                    this.state.selectedAnalysis,
                    this.state.chartType,
                    this.state.selectedMeasures,
                    this.state.selectedDimensions,
                    this.state.filters,
                    this.state.filterOperator,
                    this.state.dateRange,
                    this.state.orderBy,
                    this.state.limit,
                    this.state.comparison,
                    this.state.selectedTime,
                    this.state.selectedGranularity,
                    this.state.pivotConfig,
                    f,
                    this.state.forecast,
                    this.state.extra,
                    this.state.metricFilters,
                    this.state.metricFilterOperator
                  );
                }
              }}
            />
          </ExplorationPanel>
        }
      />
    );
  }
}

export default compose<Props, IVisualizationProps>(WithOrg)(Visualization);
