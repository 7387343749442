import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import type { QueryOptions } from "../../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../../components/hooks/query/useGQLQuery";
import type { ISource } from "../../../../../../interfaces/sources";

type QueryData = {
  allSources: ISource[];
};

type QueryVariables = {
  id: string;
};

const SourcesQuery = gql`
  query getSource($id: ID!) {
    allSources(where: { id: $id }) {
      id
      name
      status
      syncStatus
      lastSyncDate
      nextSyncDate
      catalogFileURI
      stateFileURI
      targetDatabase
      targetSchema
      isEnabled
      shouldCheckSla
      slaPeriod
      lastJobExecution {
        id
        status
      }
      sourceMeta {
        id
        slug
        publicInfo {
          name
          label
          description
          logo
          website
        }
        name
        executor
        auth
        options(where: { hidden: false }) {
          id
          slug
          key
          label
          editable
          type
          encrypt
          hidden
          required
        }
      }
      warehouse {
        id
      }
      values(where: { option: { hidden: false } }) {
        id
        value
        option {
          id
          slug
          key
          label
          editable
          type
          encrypt
          description
          hidden
          required
        }
      }
      slug
      org {
        id
      }
    }
  }
`;

export const useGetSources = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  const [sources, setSources] = useState<ISource[]>([]);
  const { data, pending, loading, error, fetchMore, refetch } = useGQLQuery<
    QueryData,
    QueryVariables
  >(SourcesQuery, options);

  useEffect(() => {
    !pending && !loading && setSources(data?.allSources ?? []);
  }, [data, pending, loading, error]);

  return { sources, pending, loading, error, fetchMore, refetch };
};
