import { theme } from "antd";
import * as React from "react";

export interface ITextFormattingProps {
  id: string;
  type?: "success" | "warning" | "danger" | "secondary";
  size?: "small" | "medium" | "large";
  lineClamp?: number;
}

const { useToken } = theme;

export function TextFormatting(
  props: React.PropsWithChildren<ITextFormattingProps>
) {
  const { type, children, size, lineClamp } = props;
  const { token } = useToken();

  const renderColorToken = () => {
    if (type === "danger") {
      return token.colorError;
    }
    if (type === "secondary") {
      return "rgba(0, 0, 0, 0.45)";
    }
    if (type === "warning") {
      return "#D36B08";
    }
    if (type === "success") {
      return "#389E0E";
    }

    return undefined;
  };

  const renderFontSizeToken = () => {
    if (size === "large") {
      return token.fontSizeLG;
    }
    if (size === "small") {
      return token.fontSizeSM;
    }
    return token.fontSize;
  };

  const color = renderColorToken();
  const fontSize = renderFontSizeToken();

  const renderChildren = () => {
    return React.Children.map(props.children ?? [], (child) => {
      return React.cloneElement(child as any, {
        lineClamp: props.lineClamp,
      });
    });
  };

  return (
    <span
      style={{
        lineClamp: lineClamp,
      }}
      ref={(el) => {
        if (el) {
          if (color) {
            el.style.setProperty("color", color, "important");
          }
          if (fontSize) {
            el.style.setProperty("font-size", `${fontSize}px`, "important");
          }
        }
      }}
    >
      {renderChildren()}
    </span>
  );
}
