import { Button, Flex, Modal } from "antd";
import * as React from "react";

import "./WlyModal.scss";

interface IWlyModalCreationProps {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  okText?: string;
  okContent?: React.ReactNode;
  cancelText?: string | React.ReactNode;
  confirmLoading?: boolean;
  title?: string | React.ReactNode;
  maskClosable?: boolean;
  okButtonDisabled?: boolean;
  okButtonDanger?: boolean;
  className?: string;
  children?: React.ReactNode;
  hideFooter?: boolean;
  closable?: boolean;
}

class WlyModal extends React.Component<IWlyModalCreationProps> {
  public render() {
    const {
      open,
      onClose,
      onOk,
      okText,
      okContent,
      cancelText,
      confirmLoading,
      title,
      maskClosable,
      okButtonDisabled,
      className,
      okButtonDanger,
      hideFooter,
      closable,
    } = this.props;

    return (
      <Modal
        footer={null}
        closable={closable ?? false}
        title={null}
        open={open}
        centered={true}
        width={"50%"}
        className={`whaly-modal whaly-modal-v2 ${className ?? ""}`}
        styles={{
          body: {
            padding: 0,
          },
        }}
        destroyOnClose={true}
        onCancel={onClose}
        maskClosable={maskClosable}
      >
        <div className="header">{title}</div>
        <div className="content">{this.props.children}</div>
        {!hideFooter && (
          <div className="footer">
            <div>
              <Button onClick={onClose} type="text">
                {cancelText ?? "Cancel"}
              </Button>
            </div>
            <Flex gap="small">
              {okContent}
              <Button
                onClick={onOk}
                disabled={okButtonDisabled}
                loading={confirmLoading}
                danger={okButtonDanger}
                type="primary"
              >
                {okText ?? "Continue"}
              </Button>
            </Flex>
          </div>
        )}
      </Modal>
    );
  }
}

export default WlyModal;
